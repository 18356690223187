<template>
  <form class="space-y-8 divide-y divide-blue-gray-300">
    <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6 pb-10">
      <div class="sm:col-span-6">
        <h2 class="text-xl font-medium text-blue-gray-900">
          Assistant Information
        </h2>
        <p class="mt-1 text-sm text-blue-gray-500">
          Assistant phone number does not receive text notifcations.
        </p>
      </div>
      <div class="sm:col-span-2">
        <p>Name</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-if="!loading.details"
              v-model="$v.assistant.$model"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Enter a name"
              :error-message="getDirtyErrorMessage('assistant')"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
      <div class="sm:col-span-2">
        <p>Email address</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-if="!loading.details"
              v-model="$v.assistantEmail.$model"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Enter an email address"
              :error-message="getDirtyErrorMessage('assistantEmail')"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
      <div class="sm:col-span-2">
        <p class="pb-1">
          Phone number
        </p>
        <PuSkeleton
          width="100%"
          height="2.6rem"
        >
          <vue-tel-input
            v-if="!loading.details"
            v-model="$v.assistantPhone.$model"
            class="block w-full sm:text-sm rounded-md md:mt-1 border-gray-300 opacity-50"
            placeholder="Enter a phone number"
            :error-message="getDirtyErrorMessage('assistantPhone')"
            default-country="US"
            disabled-fetching-country
            :preferred-countries="countryCodes"
            disabled
            @input="onPhoneInput"
          />
        </PuSkeleton>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { VueTelInput } from 'vue-tel-input';
import { required, email } from 'vuelidate/lib/validators';
import {
  CTextInput,
} from '@/components/inputs';
import { formMixin } from '@/mixins';
import { countryCodes } from '@/constants';
import { assistantInfo } from './constants';

export default {
  name: 'AssistantInformation',
  components: {
    VueTelInput,
    CTextInput,
  },
  mixins: [formMixin],
  data: () => ({
    assistant: '',
    assistantEmail: '',
    assistantPhone: '',
    countryCodes,
  }),
  validations() {
    return {
      assistant: {
        required,
      },
      assistantEmail: {
        required,
        email,
      },
      assistantPhone: {
        required,
      },
    };
  },
  computed: {
    ...mapState('agent', ['agent', 'loading']),
    fields: () => assistantInfo,
  },
  watch: {
    agent() {
      this.prepareData();
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      try {
        await this.prefillForm();
      } catch ({ message }) {
        this.$toast.error(message);
      }
    },
    onPhoneInput(...args) {
      this.phoneObject = args[1];
    },
    prefillForm() {
      this.fields.forEach(field => {
        const value = this.agent[field];
        if (!value) return;
        this[field] = value;
      });
    },
  },

};
</script>
