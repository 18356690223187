import _ from 'lodash';

export const conditionCallbackByFlag = {
  sellers: client => client.clientType === 'SELLER',
  buyers: client => client.clientType === 'BUYER',
  pending: client => client.propertyStatus === 'PENDING' || client.buyerStatus === 'PENDING',
  sold: client => (client.propertyStatus === 'SOLD' || client.buyerStatus === 'SOLD') && client.balanceDueAmount <= 0,
  active: client => client.propertyStatus === 'ACTIVE' || client.buyerStatus === 'SHOWING_PROPERTIES',
  needsUpdates: client => client.needUpdate,
  offMarket: client => client.propertyStatus === 'OFF_MARKET',
  lost: client => {
    switch (client.clientType) {
      case 'SELLER': return client.lost && !client.agentMarkedAsDeadRequest;
      case 'BUYER':
        return (client.buyerStatus === 'LOST_NO' && !client.agentMarkedAsDeadRequest)
        || (client.buyerStatus !== 'LOST_NO' && client.lost && !client.agentMarkedAsDeadRequest);
      default: return false;
    }
  },
  idealOffer: client => client.offer,
};

export const groupPersons = (persons = []) => {
  if (!persons.length) return {};

  const groupedPersons = _.groupBy(persons, client => {
    if (!client.lastName) return '#';
    const firstLetter = client.lastName[0].toUpperCase();
    return firstLetter;
  });
  return groupedPersons;
};

export const searchPersons = (persons = [], searchQuery, searchCriteria = []) => {
  if (!searchQuery) return persons;

  const queryLowercase = searchQuery.toLowerCase();
  const clientFields = ['firstName', 'lastName', ...searchCriteria];

  const result = persons.filter(client => {
    const matchFound = clientFields.some(field => {
      const isMatched = client[field]?.toLowerCase().includes(queryLowercase);
      return isMatched;
    });

    if (matchFound) return true;
    const fullName = `${client.firstName} ${client.lastName}`;
    return fullName.toLowerCase().includes(queryLowercase);
  });

  return _.sortBy(result, 'lastName');
};

export const sortClientResults = (clients, sortProperty, sortDirection) => {
  let orderedClients;
  if (sortProperty === '' || sortProperty === 'sortDate') {
    orderedClients = clients.sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate))
      .sort((a, b) => Number(b.needUpdate) - Number(a.needUpdate));
    if (sortProperty === 'sortDate') return orderedClients; // ignore direction request on default
  } else {
    orderedClients = clients.sort((a, b) => new Date(a[sortProperty]) - new Date(b[sortProperty]));
  }
  return sortDirection === 'desc' ? orderedClients.reverse() : orderedClients;
};
