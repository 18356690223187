<template>
  <div class="flex flex-col flex-grow bg-blue-light sm:px-5 py-3 overflow-y-auto">
    <spinner
      v-if="loading.dashboard"
      secondary-color="blue-light"
      fullscreen
    />
    <template v-else>
      <dashboard-listings-on-demand />
      <dashboard-details
        class="flex-1 py-5"
        :summary="summary"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Spinner } from '@/components';
import { DashboardDetails, DashboardListingsOnDemand } from '@/components/dashboard';

export default {
  name: 'Dashboard',
  components: {
    DashboardDetails,
    DashboardListingsOnDemand,
    Spinner,
  },
  computed: {
    ...mapState('agent', ['summary', 'loading']),
  },
  created() {
    const { redirect } = this.$route.query;
    if (redirect) {
      this.$router.push(this.$route.query.redirect);
    }
  },
};
</script>
