<template>
  <div class="flex flex-horizontal items-center gap-2">
    <c-dropdown
      v-model="orderBy"
      class="border-gray-300"
      :options="options"
      placeholder="Select Order By"
      @input="getSortSelectionChange"
    />
    <SortAscendingIcon
      v-if="orderByProperty !== defaultOrderBy && searchDirection === 'asc'"
      class="cursor-pointer"
      @click="toggleOrderDirection"
    />
    <SortDescendingIcon
      v-if="orderByProperty !== defaultOrderBy && searchDirection === 'desc'"
      class="cursor-pointer"
      @click="toggleOrderDirection"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { CDropdown } from '@/components/inputs';
import { orderByOptions, defaultOrderByProperty } from '@/constants';
import { SortAscendingIcon, SortDescendingIcon } from '@vue-hero-icons/solid';

export default {
  name: 'SearchOrderControls',
  components: {
    CDropdown,
    SortAscendingIcon,
    SortDescendingIcon,
  },
  data: () => ({
    options: orderByOptions,
    orderBy: defaultOrderByProperty,
    defaultOrderBy: defaultOrderByProperty,
  }),
  computed: {
    ...mapState('filters', ['orderByProperty', 'searchDirection']),
  },
  methods: {
    ...mapActions('filters', ['orderResults', 'setOrderByProperty', 'toggleOrderDirection']),
    getSortSelectionChange() {
      this.setOrderByProperty(this.orderBy);
    },
  },
};
</script>
