<template>
  <!-- eslint-disable max-len -->
  <nav
    class="flex flex-col flex-1 overflow-y-auto w-full sm:w-80 border-t"
    aria-label="Directory"
  >
    <div class="px-6 pb-4 pt-3 bg-gray-100 flex space-x-4">
      <div class="flex-1 min-w-0">
        <label
          for="search"
          class="sr-only"
        >Search</label>
        <div class="relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <search-icon
              class="h-5 w-5 text-gray-400 fill-current"
            />
          </div>
          <input
            id="search"
            type="search"
            name="search"
            class="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            :placeholder="searchPlaceholder"
            autocomplete="off"
            :value="searchQuery"
            @input="setSearchQuery($event.target.value)"
          >
        </div>
      </div>
    </div>
    <div
      v-for="letter in sortedLetters"
      :key="letter"
    >
      <div class="border-t first:border-t-0 border-b border-gray-200 px-6 py-1 text-sm font-medium text-gray-200 divider-gray">
        <h3
          class="text-white"
          v-text="letter"
        />
      </div>
      <ul class="divide-y divide-gray-300">
        <!-- Lead section -->
        <li
          v-for="client in groupedClients[letter]"
          :key="client.email"
          @click="$emit('select-user', client)"
        >
          <div class="px-6 py-5 flex items-center space-x-3 hover:bg-gray-50">
            <!-- Lead avatar -->
            <div class="flex-shrink-0">
              <span
                :class="getClientBulletClass(client)"
                class="inline-flex items-center justify-center h-10 w-10 rounded-full text-white"
              >
                <span
                  class="text-xs font-medium leading-none text-white"
                  v-text="getClientInitials(client)"
                />
              </span>
            </div>
            <!-- Lead info -->
            <div class="flex-1">
              <div
                class="cursor-pointer text-sm focus:outline-none"
              >
                <p class="font-medium text-gray-900">
                  <span v-text="client.firstName" /> <span v-text="client.lastName" />
                  <span
                    v-if="client.clientType"
                    class="ml-2 my-1 mr-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full border-2"
                    :class="client.clientType === 'BUYER' ? 'bg-green-100 text-green-800 border-green-300' : 'bg-blue-100 text-blue-800 border-blue-300'"
                    v-text="client.clientType === 'BUYER' ? 'Buying' : 'Selling'"
                  />
                </p>
                <p
                  class="text-gray-500 truncate"
                  style="max-width:282px;"
                  v-text="client.address || client.location"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SearchIcon } from '@/components/icons';
import { collectionStatusAlertTypes } from '@/constants';

export default {
  name: 'DirectoryList',
  components: {
    SearchIcon,
  },
  props: {
    groupedClients: {
      type: Object,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      required: true,
    },
    preserveSearchQuery: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('filters', ['searchQuery']),
    sortedLetters() {
      const letters = Object.keys(this.groupedClients);
      return letters.sort();
    },
  },
  created() {
    this.clearSearchQuery();
  },
  beforeDestroy() {
    this.clearSearchQuery();
  },
  methods: {
    ...mapActions('filters', ['setSearchQuery']),
    getClientInitials(client) {
      const firstLetter = client.firstName?.[0].toUpperCase() || '';
      const lastLetter = client.lastName?.[0].toUpperCase() || '';
      return firstLetter + lastLetter;
    },
    clearSearchQuery() {
      if (!this.preserveSearchQuery) this.setSearchQuery('');
    },
    getClientBulletClass(client) {
      const collectionsInstruction = collectionStatusAlertTypes.find(x => x.name === client.collectionStatus);
      if (collectionsInstruction) return collectionsInstruction.className;
      if (client.clientType === 'SELLER') return 'bg-blue-500';
      if (client.clientType === 'BUYER') return 'bg-green-500';
      return 'bg-gray-500';
    },
  },
};
</script>
