export const MAINTENANCE_MODE = false;

export const debounceInterval = 400;

export const agentImageType = {
  headshot: 'HEADSHOT',
  brokerageLogo: 'BROKERAGE_LOGO',
};

export const defaultOrderByProperty = 'sortDate';
export const defaultOrderByDirection = 'asc';
