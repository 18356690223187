<template>
  <bordered-section
    title="Referral Fee Calculation"
    class="relative"
  >
    <spinner
      v-if="isLoading"
      class="absolute transform absolute-center"
    />
    <div
      :class="{ 'opacity-0': isLoading }"
      class="sm:flex gap-5 items-center"
    >
      <div class="sm:flex-1 space-y-2">
        <p>Total to Brokerage</p>
        <c-text-input
          :value="values.totalToBrokerage"
          type="currency"
          placeholder="Not Specified"
          readonly
        />
      </div>
      <div class="sm:flex-1 space-y-2">
        <p>Referral Fee %</p>
        <c-text-input
          :value="values.referralFee"
          placeholder="Not Specified"
          readonly
        />
      </div>
      <div class="sm:flex-1 space-y-2">
        <p>Referral Fee Amount</p>
        <c-text-input
          :value="values.referralFeeAmount"
          type="currency"
          placeholder="Not Specified"
          readonly
        />
      </div>
    </div>
  </bordered-section>
</template>

<script>
import { CTextInput } from '@/components/inputs';
import BorderedSection from '@/components/BorderedSection.vue';
import Spinner from '@/components/Spinner.vue';

export default {
  components: {
    CTextInput,
    BorderedSection,
    Spinner,
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    isLoading: Boolean,
  },
};
</script>
