import {
  formatPrice,
} from '@/utils/helperFunctions';

export const getCurrentPriceFromClient = (client) => {
  switch (client.clientType) {
    case 'SELLER': {
      const {
        listPrice, estListPrice, soldPrice, contractPrice,
      } = client;
      const currentSellerPrice = soldPrice || contractPrice || listPrice || estListPrice;
      return typeof currentSellerPrice === 'number' ? formatPrice(currentSellerPrice) : currentSellerPrice;
    }
    case 'BUYER': {
      const { contractPrice, soldPrice, maxBudget } = client;
      const currentBuyerPrice = soldPrice || contractPrice || maxBudget;
      return typeof currentBuyerPrice === 'number' ? formatPrice(currentBuyerPrice) : currentBuyerPrice;
    }
    default: return null;
  }
};

export const getCurrentPriceLabelFromClient = (client) => {
  switch (client.clientType) {
    case 'SELLER': {
      const {
        contractPrice, listPrice, estListPrice, soldPrice,
      } = client;
      if (soldPrice) return 'Sold Price';
      if (contractPrice) return 'Contract Price';
      if (listPrice) return 'List Price';
      if (estListPrice) return 'Estimated List Price';
      return null;
    }
    case 'BUYER': {
      const { contractPrice, soldPrice, maxBudget } = client;
      if (soldPrice) return 'Purchase Price';
      if (contractPrice) return 'Contract Price';
      if (maxBudget) return 'Max Budget';
      return null;
    }
    default: return null;
  }
};
