<template>
  <!-- eslint-disable max-len -->
  <div
    class="lg:relative fixed inset-0 flex flex-col z-40 lg:z-0"
    :class="{'lgd:hidden': !sidebarShown}"
  >
    <div class="lg:hidden absolute inset-0 bg-gray-600 opacity-75" />
    <div
      tabindex="0"
      class="relative flex flex-col flex-1 w-68 bg-white lg:bg-gray-200 focus:outline-none"
    >
      <div class="lg:hidden absolute top-0 right-0 -mr-12 pt-2">
        <button
          type="button"
          class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          @click="$emit('hide-sidebar')"
        >
          <span class="sr-only">Close sidebar</span>
          <close-icon
            class="stroke-current h-6 w-6 text-white"
          />
        </button>
      </div>
      <div class="flex-1 pt-5 overflow-y-auto">
        <router-link
          to="/"
          class="flex-shrink-0 flex items-center px-4"
        >
          <img
            src="@/assets/ia-logo.png"
            class="m-auto w-full"
            style="max-width:200px"
          >
        </router-link>
        <div class="space-y-4 mt-5 my-4">
          <PuSkeleton
            circle
            height="100px"
            width="100px"
            class="flex w-full justify-center"
          >
            <div
              v-if="!loading.user"
              class="w-20 h-20 mx-auto lg:w-24 lg:h-24 relative cursor-pointer"
              @click="$router.push('/settings')"
            >
              <img
                v-if="profileImageUrl"
                :src="profileImageUrl"
                class="w-full h-full rounded-full border-4 border-white shadow-md"
                alt="User profile image"
              >
              <img
                v-else
                src="@/assets/agent-portal-nav-icon.png"
                class="w-full h-full"
                alt="Default profile image"
              >
              <div class="absolute inset-0 flex items-center rounded-full justify-center max-w-60 bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 transition-opacity duration-200">
                <p class="text-xs">
                  {{ profileImageUrl ? 'Change' : 'Add Image' }}
                </p>
              </div>
            </div>
          </PuSkeleton>
          <div
            v-if="collectionStatus && showCollectionAlerts"
          >
            <router-link
              to="/in-collections"
              class="flex items-center justify-center pt-2"
            >
              <CollectionStatusPill
                class="rounded-full"
                :status-name="collectionStatus"
              />
            </router-link>
          </div>
          <PuSkeleton
            class="flex w-full pt-2 justify-center"
            width="60%"
          >
            <div
              v-if="!loading.user"
              class="text-center text-xs lg:text-sm"
            >
              <h3
                v-text="fullName"
              />
            </div>
          </PuSkeleton>
          <router-link
            v-if="isAdmin"
            :to="buttonRoute"
            @click.native="$emit('hide-sidebar')"
          >
            <div class="text-center mt-2 text-sm w-1/2 m-auto px-2 py-1 text-white bg-ia-400 rounded">
              Change User
            </div>
          </router-link>
        </div>
        <nav
          aria-label="Sidebar"
          class="mt-5"
        >
          <div class="px-2 space-y-1">
            <sidebar-item
              v-for="item in sidebarItems"
              :key="item.to"
              :name="item.name"
              :to="item.to"
              :title="item.title"
              @hide-sidebar="$emit('hide-sidebar')"
            >
              <component :is="item.icon" />
            </sidebar-item>
          </div>
          <div class="px-2 space-y-1">
            <hr
              class="border-t border-gray-400 my-5"
              aria-hidden="true"
            >
          </div>
          <div class="flex-1 px-2 space-y-1">
            <button
              class="text-red-600 hover:bg-gray-300 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full focus:outline-none"
              @click="handleLogout"
            >
              <logout-icon
                v-if="!isLoggingOut"
                class="stroke-current mr-3 h-6 w-6"
              />
              <spinner
                v-else
                class="mr-3"
                size="6"
                :primary-color="logoutSpinnerColor"
              />
              Log Out
            </button>
          </div>
        </nav>
      </div>
      <div class="flex flex-col">
        <asa
          v-if="isAsaShown"
          :asa="asa || {}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  HomeIcon,
  MyreferralsIcon,
  SettingsIcon,
  LogoutIcon,
  CloseIcon,
  FAQIcon,
  MarketingMaterialsIcon,
} from '@/components/icons';
import SidebarItem from '@/components/controls/SidebarItem.vue';
import CollectionStatusPill from '@/components/CollectionStatusPill.vue';
import Spinner from '@/components/Spinner.vue';
import Asa from '@/components/Asa.vue';

import {
  sidebarItems,
} from '@/components/constants';

import {
  getHexByColorName,
} from '@/utils';

export default {
  name: 'SideNavigation',
  components: {
    Asa,
    CollectionStatusPill,
    FAQIcon,
    HomeIcon,
    MyreferralsIcon,
    MarketingMaterialsIcon,
    SettingsIcon,
    LogoutIcon,
    CloseIcon,
    SidebarItem,
    Spinner,
  },
  props: {
    sidebarShown: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    sidebarItems,
    isLoggingOut: false,
  }),
  computed: {
    ...mapGetters('agent', ['profileImageUrl', 'collectionStatus', 'showCollectionAlerts']),
    ...mapGetters('auth', ['noActiveUser']),
    ...mapState('auth', ['isAdmin']),
    ...mapState('agent', ['loading', 'agent', 'user', 'asa']),

    fullName() {
      if (this.noActiveUser) return 'No user selected';

      const { firstName, lastName } = this.agent;
      if (!firstName && !lastName) return 'Loading...';
      return `${firstName} ${lastName}`;
    },
    buttonRoute: vm => (vm.isAdmin ? '/user-impersonation' : '/my-account'),
    logoutSpinnerColor: () => getHexByColorName('red-600'),
    isAsaShown: vm => Object.keys(vm.agent).length && !vm.noActiveUser,
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async handleLogout() {
      try {
        this.isLoggingOut = true;
        await this.logout();
        this.$router.push('/login');
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isLoggingOut = false;
      }
    },
  },
};
</script>
