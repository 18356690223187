<!-- eslint-disable max-len -->
<template>
  <form-input :error-message="errorMessage">
    <input
      v-model="currentPlaceName"
      :class="errorClass"
      type="text"
      class="flex-1 input-focusable block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed text-black placeholder-gray-400 bg-white border-gray-300 focus:border-blue-500"
      @input="getResults"
    >

    <div
      v-if="resultsShown"
      class="bg-white rounded-md shadow-md px-2 py-5 z-10"
    >
      <ul v-if="locationResults.length">
        <li
          v-for="result in locationResults"
          :key="result.id"
          class="p-2 cursor-pointer rounded-md hover:bg-blue-500 hover:text-white text-sm"
          @click="setResult(result)"
        >
          {{ result.place_name }}
        </li>
      </ul>
      <p v-else>
        No results
      </p>
    </div>
  </form-input>
</template>

<script>
import debounce from 'lodash/debounce';
import { getLocationResults, getAddressFields } from '@/utils';
import FormInput from './FormInput.vue';

export default {
  name: 'AddressAutocomplete',
  components: {
    FormInput,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Not Specified',
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isRegion: Boolean,
  },
  data() {
    return {
      resultsShown: false,
      locationResults: [],
      currentAddress: this.value,
      currentPlaceName: this.value?.placeName || '',
      getResults: debounce(this.fetchResults, 300),
    };
  },
  computed: {
    formInputLabel: vm => vm.value?.placeName?.trim() || vm.label,
    errorClass() {
      const errorClass = 'border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';
      return this.errorMessage && errorClass;
    },
  },
  methods: {
    async fetchResults() {
      if (!this.currentPlaceName) return this.closeDropdown();
      const mapboxOptions = { isRegion: this.isRegion };
      this.locationResults = await getLocationResults(this.currentPlaceName, mapboxOptions);
      this.resultsShown = true;
    },
    setResult(result) {
      this.currentPlaceName = result.place_name;

      const addressFields = getAddressFields(result);

      this.currentAddress = addressFields;
      this.resultsShown = false;

      this.$emit('place-changed', addressFields);
    },
    closeDropdown() {
      this.locationResults = [];
      this.resultsShown = false;
    },
  },
};
</script>
