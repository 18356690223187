<template>
  <!-- eslint-disable max-len -->
  <div
    :class="[isEmptyPage ? 'h-full' : 'md:h-auto']"
    class="flex flex-col items-center gap-5"
  >
    <div
      v-if="secondaryAgentOptions?.length"
      class="flex flex-col items-center gap-5"
    >
      <div>
        <c-dropdown
          class="mt-3 border-gray-300"
          variant="demo"
          placeholder="Select a team member"
          :value="secondaryAgent?.id"
          :options="secondaryAgentOptions"
          @input="getSummary"
        />
      </div>
      <div
        v-if="secondaryAgent?.fullName"
        class="text-base"
      >
        Viewing stats for agent: {{ secondaryAgent?.fullName }}
      </div>
      <div
        v-else
        class="text-base"
      >
        My stats:
      </div>
    </div>
    <div
      v-for="column in detailColumns"
      :key="column.title"
      class="flex flex-col flex-1 w-full max-w-5xl mx-auto xl:mx-0"
    >
      <div class="flex flex-col flex-1 bg-gray-50 rounded-medium divide-y divide-dashed divide-gray-400">
        <template v-if="column.total > 0">
          <p class="text-center text-3xl text-gray-700 font-bold uppercase p-3">
            {{ column.title }}
          </p>
          <div class="grid grid-cols-1 md:grid-cols-2 p-5">
            <dashboard-details-chart :column="column" />
            <dashboard-details-column
              :column="column"
              class="flex-1"
            />
          </div>
        </template>
        <template v-else>
          <div class="flex flex-1 flex-center text-center text-3xl text-gray-700 font-bold uppercase p-5">
            {{ column.emptyMessage }}
          </div>
        </template>
      </div>
      <div class="w-11/12 h-5 bg-gray-600 rounded-b-medium opacity-50 mx-auto" />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatPrice } from '@/utils';
import { CDropdown } from '@/components/inputs';
import DashboardDetailsColumn from '@/components/dashboard/DashboardDetailsColumn.vue';
import DashboardDetailsChart from '@/components/dashboard/DashboardDetailsChart.vue';
import {
  dashboardDetailColumns,
  dashboardColorScheme,
} from './constants';

export default {
  name: 'DashboardDetails',
  components: {
    CDropdown,
    DashboardDetailsChart,
    DashboardDetailsColumn,
  },
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('agent', ['secondaryAgent']),
    ...mapGetters('agent', ['secondaryAgentOptions']),
    detailColumns() {
      return dashboardDetailColumns.map(column => {
        const { totalPath, fields, ...columnData } = column;
        const total = get(this.summary, totalPath, 0);
        const populatedFields = fields.map(this.populateField);

        const updatedColumn = { total, fields: populatedFields, ...columnData };
        return updatedColumn;
      });
    },
    isEmptyPage: vm => vm.detailColumns.every(c => c.total === 0),
  },
  methods: {
    ...mapActions('agent', [
      'getAgentSummary',
      'getSecondaryAgentSummary',
      'resetSecondaryAgent',
    ]),
    getSummary(agentId) {
      if (agentId) {
        this.getSecondaryAgentSummary(agentId);
      } else {
        this.resetSecondaryAgent();
        this.getAgentSummary();
      }
    },
    populateField(field) {
      const { label, countPath, valuePath } = field;
      const populatedField = { label };
      const price = get(this.summary, valuePath, 0);

      populatedField.count = get(this.summary, countPath) || 0;
      populatedField.price = formatPrice(price);
      populatedField.color = dashboardColorScheme[label];
      return populatedField;
    },
  },
};
</script>
