<template>
  <div class="overflow-y-scroll w-full xs:px-8 sm:px-8 md:px-8 lg:px-20">
    <div class="flex-1 py-10 lg:py-12">
      <h1 class="text-3xl font-extrabold text-blue-gray-900">
        Agent Bio
      </h1>
      <p class="mt-3">
        To change any of the information below please contact your Account Executive
      </p>

      <PersonalInformation class="pb-6 border-b" />
      <!-- Headshot -->
      <ImageUpload
        label="Headshot"
        :image-url="profileImageUrl"
        alt="agent profile image"
        :image-type="agentImageType.headshot"
        @open-file-input="openFileInput"
        @remove-image="removeImage"
      />

      <!-- Brokerage Logo -->
      <ImageUpload
        label="Brokerage Logo"
        :image-url="brokerageLogoUrl"
        alt="Brokerage logo"
        :image-type="agentImageType.brokerageLogo"
        @open-file-input="openFileInput"
        @remove-image="removeImage"
      />

      <template>
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          @change="uploadImage"
        >

        <UpdateEmail />
        <UpdatePhone class="pb-6 border-b" />
        <AddressInformation class="pb-6 border-b" />
        <AssistantInformation class="pb-6 hidden" />

        <ImageCropper
          :image-src="croppedImageUrl"
          :show="cropperShow"
          @crop-image="onCropImage"
          @close-cropper="cropperShow = false"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { agentImageType } from '@/constants';
import {
  PersonalInformation,
  UpdateEmail,
  UpdatePhone,
  AddressInformation,
  AssistantInformation,
  ImageUpload,
  ImageCropper,
} from '@/components';

export default {
  name: 'Settings',
  components: {
    PersonalInformation,
    UpdateEmail,
    UpdatePhone,
    AddressInformation,
    AssistantInformation,
    ImageUpload,
    ImageCropper,
  },
  data() {
    return {
      currentImageType: null,
      agentImageType,
      cropperShow: false,
      croppedImageUrl: null,
    };
  },
  computed: {
    ...mapState('agent', ['agent']),
    ...mapGetters('agent', ['profileImageUrl', 'brokerageLogoUrl']),
  },
  methods: {
    ...mapActions('agent', ['uploadAgentAttachment', 'removeAgentAttachment']),
    openFileInput(imageType) {
      this.currentImageType = imageType;
      this.$refs.fileInput.value = '';
      this.$refs.fileInput.click();
    },
    async uploadImage(event) {
      const file = event.target.files[0];
      this.croppedImageUrl = URL.createObjectURL(file);
      this.cropperShow = true;
    },
    async onCropImage(croppedImageUrl) {
      this.cropperShow = false;

      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const file = new File([blob], 'croppedImage', { type: blob.type });

      const formData = new FormData();
      formData.append('file', file);

      try {
        await this.removeImage(this.currentImageType);
        await this.uploadAgentAttachment({ formData, fileType: this.currentImageType });
      } catch (error) {
        console.error('Failed to upload image: ', error);
      }

      this.$refs.fileInput.value = '';
    },
    async removeImage(imageType) {
      try {
        if (imageType === agentImageType.headshot && this.agent.profileImage) {
          await this.removeAgentAttachment(this.agent.profileImage);
        } else if (imageType === agentImageType.brokerageLogo && this.agent.brokerageLogo) {
          await this.removeAgentAttachment(this.agent.brokerageLogo);
        }
      } catch (error) {
        console.error('Failed to remove image: ', error);
      }
    },
  },
};
</script>
