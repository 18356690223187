import moment from 'moment';
import { dateFormats, dateTimeFormats } from '@/constants';

export const formatCurrency = (amount, placeholder, hideCurrency) => {
  if (amount === null || amount === undefined) return placeholder;
  if (amount === '-') return amount;

  const formatterOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  if (hideCurrency) {
    delete formatterOptions.style;
    delete formatterOptions.currency;
  } else if (!Number.isInteger(amount)) {
    formatterOptions.minimumFractionDigits = 2;
  }

  const formatter = new Intl.NumberFormat('en-US', formatterOptions);
  return formatter.format(amount);
};

export const formatPhone = phoneNumber => {
  if (!phoneNumber) return '';
  const cleaned = phoneNumber.toString().replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
};

export function formatDate(date, placeholder = null, humanReadable) {
  if (!date) return placeholder;
  const format = humanReadable ? dateFormats.humanReadable : dateFormats.iso;
  return moment(date).format(format);
}

export function formatDateTime(dateTime, placeholder = null, humanReadable) {
  if (!dateTime) return placeholder;
  const format = humanReadable
    ? dateTimeFormats.humanReadable
    : dateTimeFormats.iso;
  return moment(dateTime).format(format);
}

export function formatLocalDateTime(
  dateTime,
  placeholder = null,
  humanReadable,
) {
  if (!dateTime) return placeholder;
  const localDateTime = moment.utc(dateTime).local();
  return formatDateTime(localDateTime, placeholder, humanReadable);
}

export const getLocalDateTime = (utcDateTime, formatName) => {
  if (!utcDateTime) return utcDateTime;
  const dateFormat = dateTimeFormats[formatName] || dateTimeFormats.iso;
  return moment.utc(utcDateTime).local().format(dateFormat);
};

export function convertLocalDateToUTC(date, isDateTime) {
  if (!date) return null;
  const formats = isDateTime ? dateTimeFormats : dateFormats;
  return moment(date).utc().format(formats.iso);
}

export const getIsoDate = (date, isDateTime) => {
  const dateFormat = isDateTime ? dateFormats.iso : dateTimeFormats.iso;
  return moment(date).format(dateFormat);
};

export const getBooleanFromString = string => {
  if (typeof string === 'boolean') return string;
  const booleans = [true, false];
  return booleans.find(booleanValue => booleanValue.toString() === string);
};

export const getConditionalValue = (value, placeholder) => {
  return value !== undefined && value !== null && value !== '' && value !== ' '
    ? value
    : placeholder;
};

export const getFormattedCurrencyValue = (currency, placeholder = '$--') => {
  const formattedCurrencyValue = formatCurrency(currency);
  return getConditionalValue(formattedCurrencyValue, placeholder);
};

export const getFormattedDateValue = (date, mode = 'date') => {
  const formattedDateByMode = mode === 'date' ? formatDate(date, null, true) : formatLocalDateTime(date, null, true);
  const formattedDate = date && formattedDateByMode;
  return getConditionalValue(formattedDate, 'No Date Set');
};
