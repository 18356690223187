<template>
  <div class="flex justify-center items-center h-screen">
    <div class="max-w-md sm:max-w-xs px-5 -mt-20">
      <img src="@/assets/ia-logo.png">
      <div class="my-5 text-center font-bold text-xs text-gray-700">
        This application is for authorized partner agents of Ideal Agent only
      </div>
      <t-alert
        v-if="error"
        show
        variant="danger"
        :dismissible="false"
        class="text-xs"
      >
        {{ errorMessage }}
      </t-alert>
      <!--Email check form -->
      <form
        v-if="isLogin"
        @submit.prevent="runLoginMethod"
      >
        <c-text-input
          v-model.trim="$v.username.$model"
          lowercase
          placeholder="Email address"
          :disabled="isEmailVerified"
          :error-message="getError('username')"
        />
        <c-text-input
          v-if="isEmailVerified"
          v-model="$v.password.$model"
          placeholder="Password"
          type="password"
          :error-message="getError('password')"
        />
        <c-button
          class="w-full mt-5"
          type="submit"
          :is-loading="loadingConditionLogin"
        >
          Login
        </c-button>
        <a
          v-if="isEmailVerified"
          href="#"
          class="text-ia-300 mt-5 text-xs font-bold hover:underline text-center block"
          @click="toggleForm(loginForms.passwordReset)"
        >Forgot password?</a>
      </form>
      <!--Password reset-->
      <password-reset
        v-if="isPasswordReset"
        :email="username"
        @back-to-login="toggleForm(loginForms.login)"
      />
      <!--Sign up form-->
      <sign-up
        v-if="isSignUp"
        :email="username"
        @back-to-login="toggleForm(loginForms.login)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CTextInput } from '@/components/inputs';
import { CButton } from '@/components/controls';
import { PasswordReset, SignUp } from '@/components/';
import { loginForms } from '@/views/constants';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { formMixin } from '@/mixins';

export default {
  name: 'Login',
  components: {
    CTextInput,
    CButton,
    PasswordReset,
    SignUp,
  },
  mixins: [formMixin],
  data() {
    return {
      username: '',
      password: '',
      confirmedPassword: '',
      errorMessage: '',
      error: false,
      isLoggingIn: false,
      isCheckingEmail: false,
      isEmailVerified: false,
      currentForm: loginForms.login,
      loginDirty: false,
    };
  },
  computed: {
    loadingConditionLogin: vm => vm.isLoggingIn || vm.isCheckingEmail,
    loginForms: () => loginForms,
    isLogin: vm => vm.currentForm === loginForms.login,
    isSignUp: vm => vm.currentForm === loginForms.signUp,
    isPasswordReset: vm => vm.currentForm === loginForms.passwordReset,
  },
  validations() {
    return {
      username: {
        required,
        email,
      },
      password: {
        required: requiredIf(() => this.isEmailVerified),
      },
    };
  },
  methods: {
    ...mapActions('auth', ['login', 'mailCheckLogin']),
    async handleLogin() {
      this.loginDirty = true;
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;
      try {
        this.error = false;
        this.isLoggingIn = true;
        const { user } = await this.login({
          username: this.username,
          password: this.password,
        });
        // gets you back to previous route if it exists
        // or on a route that depends on who the user is
        let routeToGo = '';
        const { redirect, requiresAdmin } = this.$route.query;
        if (redirect && (!requiresAdmin || user.agentId)) {
          routeToGo = this.$route.query.redirect;
        } else if (user.agentId) {
          routeToGo = '/';
        } else {
          routeToGo = {
            path: '/user-impersonation',
            query: {
              redirect: this.$route.query.redirect,
              requiresAdmin,
            },
          };
        }
        // catch clause surpresses console error
        this.$router.push(routeToGo).catch(() => {});
        this.$toast.success('Login successful!');
      } catch ({ message }) {
        this.error = true;
        this.errorMessage = message;
      } finally {
        this.isLoggingIn = false;
      }
    },
    async checkEmail() {
      this.loginDirty = true;
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.error = false;
        this.isCheckingEmail = true;
        const mailStatus = await this.mailCheckLogin(this.username);
        if (mailStatus === true) {
          this.isEmailVerified = true;
          this.$v.$reset();
          this.loginDirty = false;
        } else {
          this.error = true;
          this.errorMessage = 'This email is not registered with Ideal Agent';
        }
      } catch ({ message }) {
        this.error = true;
        this.errorMessage = message;
      } finally {
        this.isCheckingEmail = false;
      }
    },
    runLoginMethod() {
      if (!this.isEmailVerified) {
        this.checkEmail();
      } else {
        this.handleLogin();
      }
    },
    toggleForm(form) {
      this.currentForm = form;
    },
    getError(field) {
      return this.loginDirty ? this.getDirtyErrorMessage(field) : null;
    },
  },
};
</script>
