<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/no-template-key -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="overflow-auto">
    <table
      v-if="tableData?.length > 0"
      aria-describedby="listings on demand table"
      class="min-w-full border-separate border-spacing-0"
    >
      <thead>
        <tr>
          <th
            v-if="tableData.length > 1"
            :class="thClass"
          >
            &nbsp;
          </th>
          <th :class="thClass">
            Status
          </th>
          <th :class="thClass">
            Purchase Date
          </th>
          <th :class="thClass">
            Quantity Purchased
          </th>
          <th :class="thClass">
            Purchase Price
          </th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(row, i) in tableData"
        >
          <tr :key="`row-${i}`">
            <td
              v-if="tableData.length > 1"
              class="cursor-pointer w-16"
              :class="tdClass"
            >
              <span
                @click="switchValue(row.id)"
              >
                <MinusCircleIcon v-if="row?.isSelected" />
                <PlusCircleIcon v-else />
              </span>
            </td>
            <td :class="tdClass">
              {{ `${getStatus(row)}: ${row?.listingOnDemandReferrals?.length} of ${ row?.purchaseCount} Referred` }}
            </td>
            <td :class="tdClass">
              {{ getFormattedDateValue(row?.dtCreated, 'date') }}
            </td>
            <td :class="tdClass">
              {{ row?.purchaseCount }}
            </td>
            <td :class="tdClass">
              {{ getFormattedCurrencyValue(row?.purchasePrice) }}
            </td>
          </tr>
          <tr
            v-if="row?.isSelected || tableData?.length === 1"
            :key="`detail-${i}`"
          >
            <td
              colspan="7"
              class="bg-slate-100 border-t border-slate-300"
            >
              <div
                class="p-4 max-w-screen-sm"
                :class="row?.listingOnDemandReferrals?.length > 0 && tableData?.length > 1 ? 'sm:pl-24' : 'sm:pl-36'"
              >
                <listings-on-demand-detail-table
                  v-if="row?.listingOnDemandReferrals.length > 0"
                  :table="row?.listingOnDemandReferrals"
                />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div
      v-else
      class="flex items-center justify-center h-24 w-full"
    >
      NO PURCHASES FOUND
    </div>
  </div>
</template>
<script>
import { PlusCircleIcon, MinusCircleIcon } from '@/components/icons';
import ListingsOnDemandDetailTable from '@/components/tables/ListingsOnDemandDetailTable.vue';
import {
  getFormattedCurrencyValue,
  getFormattedDateValue,
} from '@/utils';

export default {
  name: 'ListingsOnDemandTable',
  components: {
    ListingsOnDemandDetailTable,
    MinusCircleIcon,
    PlusCircleIcon,
  },
  props: {
    table: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      thClass: 'table-cell sticky top-0 z-10 border-b border-gray-300 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8',
      tdClass: 'table-cell whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
      tableData: this.table?.data ?? [],
    };
  },
  methods: {
    switchValue(id) {
      this.tableData = this.tableData.map((x) => {
        if (x.id === id) {
          // eslint-disable-next-line no-param-reassign
          x.isSelected = !x.isSelected;
        }
        return x;
      });
    },
    getStatus(item) {
      return item.listingOnDemandReferrals?.length > 0 && item.purchaseCount - item.listingOnDemandReferrals?.length <= 0 ? 'Complete' : 'Active';
    },
    getFormattedCurrencyValue,
    getFormattedDateValue,
  },
};
</script>
