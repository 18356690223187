<template>
  <form
    @submit.prevent="handleSignUp"
  >
    <t-alert
      v-if="error"
      show
      variant="danger"
      :dismissible="false"
      class="text-xs"
    >
      {{ errorMessage }}
    </t-alert>
    <t-alert
      show
      :dismissible="false"
      class="text-xs mt-2"
    >
      Please enter a new password to setup your account
    </t-alert>
    <c-text-input
      v-model="$v.password.$model"
      placeholder="Password"
      type="password"
      :error-message="getDirtyErrorMessage('password')"
    />
    <c-text-input
      v-model="$v.confirmedPassword.$model"
      placeholder="Confirm new password"
      type="password"
      :error-message="getDirtyErrorMessage('confirmedPassword')"
    />
    <c-button
      class="w-full mt-5"
      type="submit"
      :is-loading="isCreatingAccount"
    >
      Save Password
    </c-button>
  </form>
</template>

<script>
import { CButton } from '@/components/controls';
import { CTextInput } from '@/components/inputs';

import { mapActions } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';
import { formMixin } from '@/mixins';

export default {
  name: 'SignUp',
  components: {
    CButton,
    CTextInput,
  },
  mixins: [formMixin],
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCreatingAccount: false,
      error: false,
      errorMessage: '',
      password: '',
      confirmedPassword: '',
    };
  },
  validations() {
    return {
      password: {
        required,
      },
      confirmedPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    };
  },
  methods: {
    ...mapActions('auth', ['createAccount']),
    async handleSignUp() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;
      this.error = false;
      try {
        this.isCreatingAccount = true;
        const payload = {
          email: this.email,
          password: this.password,
          confirmedPassword: this.confirmedPassword,
        };
        await this.createAccount(payload);
        this.$toast.success('New account created! You can now log in.');
        this.$emit('back-to-login');
      } catch ({ message }) {
        this.error = true;
        this.errorMessage = message;
      } finally {
        this.isCreatingAccount = false;
      }
    },
  },
};
</script>
