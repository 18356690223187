<template>
  <submittable-form
    :is-submitted="isSubmitted"
    class="pb-20"
    @submit="handleSubmit"
  >
    <div class="font-bold pb-4">
      Provide information below when the offer gets executed
    </div>

    <c-dropdown
      v-model="offerContractOption"
      :options="offerContractOptions"
      class="border-gray-300 mb-5"
      variant="demo"
      @input="clearFields"
    />

    <template v-if="offerContractOption === 'Offer Executed'">
      <div class="mt-8 space-y-3">
        <p>Contract Date:</p>
        <c-date-picker
          v-model="$v.contractDate.$model"
          :error-message="getDirtyErrorMessage('contractDate')"
          date-format="Y-m-d"
          close-on-select
        />
      </div>
      <div class="mt-5 space-y-3">
        <p>Contract Price:</p>
        <c-text-input
          v-model.number="$v.contractPrice.$model"
          :error-message="getDirtyErrorMessage('contractPrice')"
          type="currency"
        />
      </div>
      <div class="mt-5 space-y-3">
        <p>Scheduled Closing Date:</p>
        <c-date-picker
          v-model="$v.scheduledClosingDate.$model"
          :error-message="getDirtyErrorMessage('scheduledClosingDate')"
          date-format="Y-m-d"
          close-on-select
        />
      </div>
      <div class="mt-5 space-y-3">
        <p>Due Diligence End Date:</p>
        <c-date-picker
          v-model="$v.dueDiligenceEndDate.$model"
          :error-message="getDirtyErrorMessage('dueDiligenceEndDate')"
          date-format="Y-m-d"
          close-on-select
        />
      </div>
      <div class="mt-5 space-y-3">
        <p>Comments:</p>
        <c-textarea-input
          v-model="comments"
          placeholder="Enter comments"
        />
      </div>
      <div class="mt-5 space-y-3">
        <p>Upload Contract:</p>
        <c-file-input
          :error-message="getDirtyErrorMessage('contract')"
          class="mt-3"
          @change="setFile($event, 'contract')"
        />
      </div>
    </template>

    <template v-if="offerContractOption === 'Inspection Done'">
      <div class="mt-8 space-y-3">
        <p>Inspection Date:</p>
        <c-date-picker
          v-model="$v.inspectionDate.$model"
          :error-message="getDirtyErrorMessage('inspectionDate')"
          date-format="Y-m-d"
          close-on-select
        />
      </div>
      <div class="mt-5 space-y-3">
        <p>Comments:</p>
        <c-textarea-input
          v-model="comments"
          placeholder="Enter comments"
        />
      </div>
    </template>

    <template v-if="offerContractOption === 'Due Diligence Status'">
      <c-dropdown
        v-model="diligencePassed"
        :options="diligencePassedOptions"
        class="border-gray-300 mb-5"
        variant="demo"
        @input="clearDiligenceFields"
      />

      <template v-if="diligencePassed === true">
        <div class="mt-5 space-y-3">
          <p>Comments:</p>
          <c-textarea-input
            v-model="comments"
            placeholder="Enter comments"
          />
        </div>

        <div class="mt-5 space-y-3">
          <p>Upload Inspection Report:</p>
          <c-file-input
            :error-message="getDirtyErrorMessage('inspectionReport')"
            class="mt-3"
            @change="setFile($event, 'inspectionReport')"
          />
        </div>
      </template>

      <template v-if="diligencePassed === false">
        <div class="mt-5 space-y-3">
          <p>Would you like to look at all the previous offers received for the property?</p>
          <c-dropdown
            v-model="lookForPreviousOffers"
            :options="booleanOptions"
            :error-message="getDirtyErrorMessage('lookForPreviousOffers')"
            class="border-gray-300 mb-5"
            variant="demo"
          />
        </div>

        <div class="mt-5 space-y-3">
          <p>Comments:</p>
          <c-textarea-input
            v-model="$v.comments.$model"
            :error-message="getDirtyErrorMessage('comments')"
            placeholder="Enter comments"
          />
        </div>
      </template>
    </template>

    <template v-if="offerContractOption === 'Submitted Contract to Title'">
      <div class="mt-5 space-y-3">
        <p>Comments:</p>
        <c-textarea-input
          v-model="comments"
          placeholder="Enter comments"
        />
      </div>
    </template>

    <div v-show="isSaveButtonShown">
      <attachment-upload
        class="mt-5"
        :attachment-type="attachmentType"
        :error-messages="attachmentErrors"
        @changeFile="setFile($event, 'attachment')"
        @changeAttachmentType="attachmentType = $event"
      />

      <c-button
        :is-loading="isSubmitting"
        type="submit"
      >
        Save
      </c-button>
    </div>
  </submittable-form>
</template>

<script>
import { mapActions } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import {
  CDropdown,
  CDatePicker,
  CTextInput,
  CTextareaInput,
  CFileInput,
  AttachmentUpload,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import SubmittableForm from '@/components/forms/SubmittableForm.vue';
import { submitIocf, sendPropertyAttachment } from '@/api/actions';
import { booleanOptions } from '@/constants/dropdown';
import { getFileFromObject } from '@/utils';
import { formMixin } from '@/mixins';
import {
  iocfFields,
  offerContractOptions,
  diligencePassedOptions,
} from './constants';

export default {
  name: 'IocfForm',
  components: {
    SubmittableForm,
    CDropdown,
    CDatePicker,
    CTextInput,
    CTextareaInput,
    CFileInput,
    CButton,
    AttachmentUpload,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    offerContractOption: null,
    contractDate: null,
    contractPrice: null,
    scheduledClosingDate: null,
    dueDiligenceEndDate: null,
    inspectionDate: null,
    diligencePassed: null,
    lookForPreviousOffers: null,
    comments: '',
    contract: null,
    inspectionReport: null,
    attachment: null,
    attachmentType: null,
    isSubmitting: false,
    isSubmitted: false,
    offerContractOptions,
    diligencePassedOptions,
    booleanOptions,
    fields: iocfFields,
  }),
  validations() {
    return {
      contractDate: {
        required: requiredIf(() => this.offerContractOption === 'Offer Executed'),
      },
      contractPrice: {
        required: requiredIf(() => this.offerContractOption === 'Offer Executed'),
      },
      scheduledClosingDate: {
        required: requiredIf(() => this.offerContractOption === 'Offer Executed'),
      },
      dueDiligenceEndDate: {
        required: requiredIf(() => this.offerContractOption === 'Offer Executed'),
      },
      contract: {
        requiredIf: requiredIf(() => this.offerContractOption === 'Offer Executed'),
      },
      inspectionDate: {
        required: requiredIf(() => this.offerContractOption === 'Inspection Done'),
      },
      comments: {
        required: requiredIf(() => this.diligencePassed === false),
      },
      inspectionReport: {
        requiredIf: requiredIf(() => this.diligencePassed === true),
      },
      lookForPreviousOffers: {
        required: requiredIf(() => this.diligencePassed === false),
      },
      attachmentType: {
        required: requiredIf(() => this.attachment),
      },
    };
  },
  computed: {
    attachmentErrors: vm => ({ attachmentType: vm.getDirtyErrorMessage('attachmentType') }),
    fieldConditions() {
      return {
        contractDate: this.offerContractOption === 'Offer Executed',
        contractPrice: this.offerContractOption === 'Offer Executed',
        scheduledClosingDate: this.offerContractOption === 'Offer Executed',
        dueDiligenceEndDate: this.offerContractOption === 'Offer Executed',
        inspectionDate: this.offerContractOption === 'Inspection Done',
        diligencePassed: this.offerContractOption === 'Due Diligence Status',
        lookForPreviousOffers: this.diligencePassed === false,
        attachmentType: this.attachment,
      };
    },
    isSaveButtonShown() {
      const isDueDiligence = this.offerContractOption === 'Due Diligence Status';
      return isDueDiligence ? this.diligencePassed !== null : this.offerContractOption;
    },
  },
  methods: {
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        ...this.generatedPayload,
        agentId: +this.client.assignedAgent,
        listingId: this.client.propertyReferralId,
      };

      const formData = new FormData();
      const binaryPayload = getFileFromObject(payload);
      formData.append('idealOfferContractRequest', binaryPayload);

      if (this.contract) formData.append('attachment', this.contract);
      if (this.inspectionReport) formData.append('attachment', this.inspectionReport);

      try {
        this.isSubmitting = true;
        await submitIocf(formData);
        if (this.attachment) {
          const formData = new FormData();
          formData.append('file', this.attachment);
          sendPropertyAttachment(formData, this.attachmentType, this.client.id);
        }
        this.$emit('submit');
        this.isSubmitted = true;

        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    setFile(file, fieldName) {
      this[fieldName] = file;
    },
    clearFields() {
      this.contractDate = null;
      this.contractPrice = null;
      this.scheduledClosingDate = null;
      this.dueDiligenceEndDate = null;
      this.inspectionDate = null;
      this.lookForPreviousOffers = null;
      this.comments = '';

      this.contract = null;
      this.inspectionReport = null;
      this.attachment = null;
      this.attachmentType = null;
      this.$v.$reset();
    },
    clearDiligenceFields() {
      this.inspectionReport = null;
      this.lookForPreviousOffers = null;
      this.comments = '';
      this.$v.$reset();
    },
  },
};
</script>
