export const booleanOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const reasonsForListingAppointmentCancelled = [
  'Rescheduled',
  'Not Rescheduled',
];

export const propertyGoActiveOptions = [
  'Yes',
  'No',
  'Listing Agreement Cancelled',
];

export const orderByOptions = [
  {
    value: 'nextFollowUp',
    label: 'Next Follow Up Date',
  },
  {
    value: 'exchangeDate',
    label: 'Referral Date',
  },
];
