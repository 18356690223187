import router from '@/router';
import {
  removeUserToken,
  removeAdminToken,
} from './localStorageControls';

const errorMessages = {
  required: 'This field is required',
  numeric: 'The value should be a number',
  minLength: length => `Minimum number of characters is ${length}`,
  maxLength: length => `Maximum number of characters is ${length}`,
  email: 'Please enter a valid email',
  sameAsPassword: 'Passwords must match',
  codeFormatting: 'Code must contain 4 to 10 digits',
  greaterThanZero: 'The value must be greater than zero',
  commissionPercent: 'Must be a valid number between 0 and 10',
  between: (min, max) => `The number must be between ${min} and ${max}`,
};

const axiosErrorMessages = {
  unknown: 'Unknown server error. Please try again.',
  wrongPassword: 'Wrong password. Please try again',
  largeFile: 'File too large.',
};

export function getErrorMessage(name, rule) {
  switch (name) {
    case 'required':
    case 'requiredIf':
      return errorMessages.required;
    case 'numeric': return errorMessages.numeric;
    case 'minLength': return errorMessages.minLength(rule.min);
    case 'maxLength': return errorMessages.maxLength(rule.max);
    case 'email': return errorMessages.email;
    case 'sameAsPassword': return errorMessages.sameAsPassword;
    case 'codeFormatting': return errorMessages.codeFormatting;
    case 'greaterThanZero': return errorMessages.greaterThanZero;
    case 'commissionPercent': return errorMessages.commissionPercent;
    case 'between': return errorMessages.between(rule.min, rule.max);
    case 'canBeAccepted': return 'Another offer has already been accepted';
    default: return '';
  }
}

export function formatErrors(response) {
  const timestamp = `Error Number ${Date.now()}`;

  if (!response) return `${axiosErrorMessages.unknown} ${timestamp}`;

  // Get error by status code
  const { status, data } = response;
  if (status === 401) {
    const isUserLogin = data.path === '/user/login';
    if (isUserLogin) return axiosErrorMessages.wrongPassword;

    // Session expired
    removeUserToken();
    removeAdminToken();
    router.push({ path: '/login', query: { redirect: router.currentRoute.name } });
  }
  if (status === 413) return axiosErrorMessages.largeFile;

  // Get error by error message
  const { displayedMessage, message, error } = data;
  const errorToReturn = (() => {
    if (displayedMessage) return displayedMessage;
    if (message) return `${message} ${timestamp}`;
    if (error) return `${error} ${timestamp}`;
    return `${axiosErrorMessages.unknown} ${timestamp}`;
  })();
  return errorToReturn;
}
