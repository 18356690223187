import { api, paths } from '@/api';

export const getFile = async (fileId, storageName) => {
  const { data } = await api.get(paths.FILE_ATTACHMENT, {
    responseType: 'blob',
    params: {
      id: fileId,
      storageName,
    },
  });
  return data;
};
