import { accessToken, mapboxSearchTypes } from '@/constants/mapbox';
import { countryCodes } from '@/constants/countries';

export const getGeocoderAPI = (searchInputText, options) => {
  const { region, precise } = mapboxSearchTypes;
  const placeTypes = options.isRegion ? region : precise;

  const query = encodeURIComponent(searchInputText);
  const countryCodesFormatted = encodeURIComponent(countryCodes);
  const searchTypesFormatted = encodeURIComponent(placeTypes);
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${accessToken}&country=${countryCodesFormatted}&types=${searchTypesFormatted}`;
};
