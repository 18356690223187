import { render, staticRenderFns } from "./ScrollToTop.vue?vue&type=template&id=25678536&scoped=true&"
import script from "./ScrollToTop.vue?vue&type=script&lang=ts&"
export * from "./ScrollToTop.vue?vue&type=script&lang=ts&"
import style0 from "./ScrollToTop.vue?vue&type=style&index=0&id=25678536&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25678536",
  null
  
)

export default component.exports