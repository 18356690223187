<template>
  <div class="alcf">
    <t-alert
      v-if="isSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <form @submit.prevent="handleSubmit">
        <div class="pb-5">
          <p>Did the property go active?</p>
          <c-dropdown
            v-model="option"
            class="mt-3 border-gray-300"
            variant="demo"
            :options="propertyGoActiveOptions"
            :error-message="getDirtyErrorMessage('option')"
            @input="clearFormFields"
          />
        </div>
        <template v-if="optionSelected">
          <template v-if="!isCancelled">
            <div class="space-y-3 mb-5">
              <p>{{ activeListingDateTitle }}</p>
              <c-date-picker
                v-model="$v.activeListingDate.$model"
                class="flex-auto"
                close-on-select
                :placeholder="activeListingDateTitle"
                date-format="Y-m-d"
                :error-message="getDirtyErrorMessage('activeListingDate')"
              />
            </div>

            <div class="pb-5">
              <p>List Price</p>
              <c-text-input
                v-model.number="$v.listPrice.$model"
                type="currency"
                placeholder="List Price"
                :error-message="getDirtyErrorMessage('listPrice')"
              />
            </div>
          </template>
          <div class="pb-5">
            <p>Comments</p>
            <c-textarea-input
              v-model="$v.activeListingConfirmationComments.$model"
              :error-message="getDirtyErrorMessage('activeListingConfirmationComments')"
            />
          </div>

          <div class="mb-5">
            <c-button
              :is-loading="isSubmitting"
              type="submit"
            >
              Save
            </c-button>
          </div>
        </template>
      </form>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required,
  requiredIf,
  maxLength,
} from 'vuelidate/lib/validators';
import {
  CTextareaInput,
  CTextInput,
  CDatePicker,
  CDropdown,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import { formMixin } from '@/mixins';
import { propertyGoActiveOptions } from '@/constants/dropdown';
import { alcfFields } from './constants';

export default {
  name: 'AlcfForm',
  components: {
    CTextareaInput,
    CTextInput,
    CDatePicker,
    CDropdown,
    CButton,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    option: null,
    activeListingDate: '',
    listPrice: null,
    activeListingConfirmationComments: '',
    propertyGoActiveOptions,
    isSubmitted: false,
    isSubmitting: false,
    fields: alcfFields,
  }),
  validations() {
    return {
      option: {
        required,
      },
      activeListingDate: {
        required: requiredIf(() => !this.isCancelled),
      },
      listPrice: {
        required: requiredIf(() => !this.isCancelled),
      },
      activeListingConfirmationComments: {
        required,
        maxLength: maxLength(2000),
      },
    };
  },
  computed: {
    optionSelected: vm => propertyGoActiveOptions.includes(vm.option),
    isCancelled: vm => vm.option === 'Listing Agreement Cancelled',
    activeListingDateTitle: vm => (
      vm.option === 'Yes'
        ? 'Active Listing Date:'
        : 'Target Active Listing Date:'
    ),
  },
  methods: {
    ...mapActions('client', ['saveAlcf']),
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        agentId: +this.client.assignedAgent,
        listingId: this.client.propertyReferralId,
        ...this.generatedPayload,
      };

      try {
        this.isSubmitting = true;
        await this.saveAlcf(payload);
        this.$emit('submit');
        this.isSubmitted = true;
        this.clearFields();

        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    clearFields() {
      this.option = null;
      this.clearFormFields();
    },
    clearFormFields() {
      this.activeListingDate = '';
      this.listPrice = null;
      this.activeListingConfirmationComments = '';
      this.$nextTick(() => this.$v.$reset());
    },
  },
};
</script>
