<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-col">
    <div class="dashboard-column flex-1 space-y-3 bg-gray-300 rounded-xxl p-5">
      <div
        v-for="field in column.fields"
        :key="field.label"
        class="flex justify-between items-center font-bold space-x-3"
      >
        <div class="flex items-center space-x-5">
          <div
            class="w-8 h-8 flex-shrink-0 flex justify-center items-center text-white rounded-full"
            :class="field.color"
            v-text="field.count"
          />
          <p class="text-gray-800">
            {{ field.label }}
          </p>
        </div>
        <p class="text-gray-800">
          {{ field.price }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardDetailsColumn',
  props: {
    column: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.dashboard-column {
  --shadow-color: rgba(0, 0, 0, .2);
  box-shadow: 0 2px 5px var(--shadow-color), inset 0 0 3px var(--shadow-color);
}
</style>
