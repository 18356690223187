<template>
  <!-- eslint-disable max-len -->
  <t-button
    v-bind="$attrs"
    class="h-10 flex justify-center items-center"
    @click="onClickWrapper"
  >
    <div v-if="isLoading">
      <spinner size="4" />
    </div>
    <slot v-else />
  </t-button>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'CButton',
  components: {
    Spinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickWrapper() {
      if (!this.isLoading) {
        this.$emit('click');
      }
    },
  },
};
</script>
