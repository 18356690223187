<template>
  <div>
    <router-link
      :to="to"
      class="group flex items-center px-2 py-2 text-sm font-medium rounded-md"
      :class="itemClass"
      @click.native="$emit('hide-sidebar')"
    >
      <div
        class="flex justify-center items-center w-6 h-6 stroke-current mr-3"
        :class="itemClass"
      >
        <slot />
      </div>
      <p>
        {{ title }}
      </p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelected: vm => vm.name === vm.$route.name,
    itemClass: vm => ({
      'bg-blue-500 text-white hover:text-white': vm.isSelected,
      'text-gray-600 hover:bg-gray-300': !vm.isSelected,
    }),
  },
};
</script>
