export const idealOfferStatusLabels = {
  SUBMITTED: 'Submitted',
  DNQ: 'DNQ',
  VIDEO_APPOINTMENT_SET: 'Video Appointment Set',
  VIDEO_APPOINTMENT_COMPLETED: 'Video Appointment Completed',
  VIDEO_APPOINTMENT_CANCELED: 'Video Appointment Cancelled',
  OFFER_EXECUTED_AND_UPLOADED: 'Offer Executed and Uploaded',
  INSPECTION_DONE: 'Inspection Done',
  DUE_DILIGENCE_PASSED: 'Due Diligence Passed',
  DUE_DILIGENCE_NOT_PASSED: 'Due Diligence Not Passed',
  SUBMITTED_CONTRACT_TO_TITLE: 'Submitted Contract to Title',
};
