// IOCF form

export const iocfFields = [
  'offerContractOption',
  'contractDate',
  'contractPrice',
  'scheduledClosingDate',
  'dueDiligenceEndDate',
  'comments',
  'inspectionDate',
  'diligencePassed',
  'lookForPreviousOffers',
];

export const offerContractOptions = [
  'Offer Executed',
  'Inspection Done',
  'Due Diligence Status',
  'Submitted Contract to Title',
];

export const diligencePassedOptions = [
  {
    label: 'Diligence Passed',
    value: true,
  },
  {
    label: 'Diligence Not Passed',
    value: false,
  },
];

// IOVAF form
export const iovafFields = [
  'videoAppointmentCompleted',
  'leftMarketingPiece',
  'leftMarketingPieceComments',
  'setListingAppointment',
  'setListingAppointmentComments',
  'listingAgreementSigned',
  'listingAgreementSignedDate',
  'listingAgreementSignedComments',
  'didNotSignReason',
  'nextFollowUp',
  'lostOfferOpportunityReason',
  'agentMarkedAsDeadRequestComments',
  'videoAppointmentCancelledReason',
  'videoAppointmentCancelledReasonComments',
  'offerVideoApptDateTime',
];

export const didNotSignReasonOptions = [
  'Waiting for the cash offers',
  'Trying For Sale By Owner',
  'Seller has listing agreement',
  'Interviewing Other Agents',
];

export const lostOfferOpportunityReasonOptions = [
  'Not Selling',
  'Trying for Sale by Owner',
  'Went with another agent',
  'Other',
];

export const videoAppointmentCompletedOptions = [
  'Yes',
  'No - Lost Opportunity',
  'Video Appointment Cancelled',
];

// IOICF form

export const ioicfFields = [
  'setVideoAppointment',
  'offerVideoApptDateTime',
  'comments',
  'noVideoAppointmentReason',
  'nextFollowUp',
  'agentMarkedAsDeadRequest',
];

export const setVideoAppointmentOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const noVideoAppointmentReasonOptions = [
  {
    label: 'Not Decision Maker',
    value: 'NOT_DECISION_MAKER',
  },
  {
    label: 'Currently Listed',
    value: 'CURRENTLY_LISTED',
  },
  {
    label: 'For Sale By Owner',
    value: 'FOR_SALE_BY_OWNER',
  },
  {
    label: 'Not Selling',
    value: 'NOT_SELLING',
  },
  {
    label: 'Went with Another Agent',
    value: 'WENT_WITH_ANOTHER_AGENT',
  },
  {
    label: 'Property Sold',
    value: 'PROPERTY_SOLD',
  },
  {
    label: 'Pricing Expectations are unrealistic',
    value: 'PRICING_EXPECTATIONS_ARE_UNREALISTIC',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

// IOF form

export const iofFields = [
  'offerPresentedToClient',
  'acceptedRejected',
  'clientRejectedOfferReason',
];

export const vendorNotOfferedReasonOptions = [
  'Out of Buy Box',
  'Offer Not Competitive',
  'Market Saturation',
];

export const acceptedRejectedOptions = [
  {
    label: 'Accepted',
    value: true,
  },
  {
    label: 'Rejected',
    value: false,
  },
];

export const clientRejectedOfferReasonOptions = [
  'Going to Market',
  'Waiting to List',
  'Listing with Someone Else',
  'Accepted a Cash Offer from a Competitor',
  'Other',
];

export const attachmentTypeOptions = [
  {
    label: 'Offer Property Video',
    value: 'OFFER_PROPERTY_VIDEO',
  },
  {
    label: 'Offer Property Exterior Photos (Frnt,Back)',
    value: 'OFFER_PROPERTY_EXT_PHOTOS',
  },
  {
    label: 'Offer Property Interior Photos ((LR, Kit, App (Oven, DW, RF), DR, MBed, MBath, Guest Rooms, Guest Bath)',
    value: 'OFFER_PROPERTY_INT_PHOTOS',
  },
  {
    label: 'Offer Property Other Photos',
    value: 'OFFER_PROPERTY_OTHER_PHOTOS',
  },
  {
    label: 'Listing Agreement',
    value: 'LISTING_AGREEMENT',
  },
  {
    label: 'Purchase Contract',
    value: 'PURCHASE_CONTRACT',
  },
  {
    label: 'Inspection Report',
    value: 'INSPECTION_REPORT',
  },
  {
    label: 'ALTA',
    value: 'ALTA_SETTLEMENT_STATEMENT',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Letter of Intent',
    value: 'LETTER_OF_INTENT',
  },
  {
    label: 'Offer Contract',
    value: 'OFFER_CONTRACT',
  },
];
