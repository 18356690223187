/* eslint-disable import/prefer-default-export */
export const operationTypes = {
  IOVAF: 'Ideal Offer Video Appointment Form',
  IOSPF: 'Ideal offer Sale Purchase Form',
  IOICF: 'Ideal Offer Initial Contact Form',
  IOCF: 'Ideal Offer Contract Form',
  IOF: 'Ideal Offer Form',
  ICF: 'Initial Call Notes',
  LAF: 'Listing Appointment Notes',
  PCF: 'Purchase Contract Notes',
  SPF: 'Sold Notes',
  OTHER: 'Other',
  'Needs Assistance': 'Needs Assistance',
  'Assist with a client': 'Assist with a client',
};

export const noteTypeOptions = {
  GENERAL: 'General',
  NOTE_TO_AGENT: 'Note to Agent',
  FAKE_BAD_INQUIRY: 'Fake/Bad Inquiry',
  PROPERTY_CONDITION: 'Fake/Bad Inquiry',
  INTRO_CALL_COMMENTS: 'Intro Call Comments',
  INITIAL_CALL_COMMENTS_FOR_AGENT: 'Initial Call Comments for Agent',
  LISTING_APPOINTMENT_COMMENTS: 'Listing Appointment Comments',
  LIST_TO_CLOSE_COMMENTS: 'List to Close Comments',
  URGENT: 'URGENT',
  SERVICES: 'Services',
  IDEAL_OFFER_INTRO_CALL_COMMENTS: 'Ideal Offer Intro Call Comments',
  IDEAL_OFFER_COMMENTS: 'Ideal Offer Comments',
};

export const fieldsWithPrice = ['List Price', 'Sold Price', 'Seller Concession', 'Buyer Contract Price'];
export const percentageFields = ['Total Commission Pct'];
