<template>
  <!-- eslint-disable max-len -->
  <div>
    <t-alert
      v-if="isSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>
    <template v-else>
      <div class="text-base font-bold pb-4">
        Provide details to request assistance from your Account Executive
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="pb-5 mr-5">
          <c-textarea-input
            v-model="$v.comments.$model"
            placeholder="Enter any comments regarding assistance"
            :error-message="getDirtyErrorMessage('comments')"
          />
        </div>
        <div class="pb-5">
          <c-button
            type="submit"
            :is-loading="isSubmitting"
          >
            Save
          </c-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import {
  CTextareaInput,
} from '@/components/inputs';
import {
  CButton,
} from '@/components/controls';
import { formMixin } from '@/mixins';
import { assistFormFields } from './constants';

export default {
  name: 'AssistForm',
  components: {
    CTextareaInput,
    CButton,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    comments: '',
    isSubmitting: false,
    isSubmitted: false,
  }),
  validations: {
    comments: {
      required,
      maxLength: maxLength(255),
    },
  },
  computed: {
    fields: () => assistFormFields,
  },
  methods: {
    ...mapActions('client', ['updateAssist']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      const clientIdNameByClientType = {
        SELLER: 'propertyReferralId',
        BUYER: 'buyerProfileReferralId',
      };
      const clientIdName = clientIdNameByClientType[this.client.clientType];

      const payload = {
        agentId: +this.client.assignedAgent,
        [clientIdName]: this.client[clientIdName],
        ...this.generatedPayload,
      };
      try {
        this.isSubmitting = true;
        await this.updateAssist(payload);
        this.isSubmitted = true;
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
