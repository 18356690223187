<template>
  <!-- eslint-disable max-len -->
  <form-input :error-message="errorMessage">
    <div class="flex items-center">
      <div
        v-if="isCurrency"
        class="w-10 bg-gray-500 text-lg text-white border-2 border-gray-500 shadow-sm self-stretch flex items-center justify-center rounded-l"
        v-text="'$'"
      />
      <div
        v-if="isPercentage"
        class="w-10 bg-gray-500 text-lg text-white border-2 border-gray-500 shadow-sm self-stretch flex items-center justify-center rounded-l"
        v-text="'%'"
      />
      <input
        v-bind="$attrs"
        :type="type"
        :value="formattedValue"
        :readonly="readonly"
        :class="dynamicClasses"
        class="w-full flex-1 px-3 py-2 input-focusable"
        @input="handleInput"
        @change="handleChange"
      >
    </div>
  </form-input>
</template>

<script>
import { formatInputPrice } from '@/utils';
import FormInput from './FormInput.vue';

export default {
  name: 'CTextInput',
  components: {
    FormInput,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    lowercase: Boolean,
    readonly: Boolean,
  },
  computed: {
    isInvalid: vm => Boolean(vm.errorMessage),
    isCurrency: vm => vm.type === 'currency',
    isPercentage: vm => vm.type === 'percentage',
    formattedValue: vm => {
      if (vm.isCurrency) {
        return formatInputPrice(vm.value);
      }

      if (vm.lowercase) {
        return vm.value.toLowerCase();
      }

      return vm.value;
    },
    dynamicClasses() {
      const readonlyStyle = this.readonly ? 'pointer-events-none' : '';
      const border = this.isCurrency ? 'border-l-0 rounded-r' : 'rounded';
      const error = { 'border-red-500': this.isInvalid };
      return [border, error, readonlyStyle];
    },
  },
  methods: {
    handleInput(event) {
      const targetValue = event.target.value;
      let value = this.isCurrency ? targetValue.replaceAll(',', '') : targetValue;
      if (this.lowercase) {
        value = targetValue.toLowerCase();
      }
      this.$emit('input', value);
    },
    handleChange(event) {
      this.$emit('change', event);
    },
  },
};
</script>
