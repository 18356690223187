export const checkMailMessages = {
  showPasswordEnter: 'Email exists and IS registered in the portal',
  showSignUp: 'Email exists but is NOT registered in the portal',
  noMailRegistered: 'Email doesn\'t exist in the table at all',
};

export const loginForms = {
  login: 'login',
  signUp: 'signUp',
  passwordReset: 'passwordReset',
};
