import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth.module';
import client from './modules/client.module';
import agent from './modules/agent.module';
import filters from './modules/filters.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    agent,
    client,
    filters,
  },
});
