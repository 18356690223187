<template>
  <!-- eslint-disable max-len -->
  <div class="w-full flex shadow-md rounded-md mb-6 cursor-pointer">
    <div
      class="flex items-center justify-center w-8 text-white text-sm font-medium rounded-l-md"
      :class="labelColor"
    >
      <span
        class="transform -rotate-90"
        v-text="labelText"
      />
    </div>
    <div class="flex flex-1 text-sm border border-l-0 border-gray-200 bg-white hover:bg-gray-100 rounded-r-md truncate">
      <div class="flex-1 overflow-hidden p-4 sm:p-6">
        <div class="flex justify-between items-start space-x-2">
          <div class="max-w-50 sm:max-w-75 whitespace-normal sm:truncate">
            <p class="font-sans text-gray-800 sm:truncate sm:text-md md:text-lg lg:text-xlg font-bold">
              <template v-if="client.address">
                <span>{{ client.address }}, </span>
                <span v-if="client.unitNumber">
                  Unit {{ client.unitNumber }},
                </span>
                <span class="truncate capitalize block sm:inline">
                  {{ client.city }}, {{ client.state }} {{ client.zipCode }}
                </span>
              </template>
              <span
                v-else-if="client.location"
                v-text="client.location"
              />
            </p>
            <div
              class="text-left sm:inline sm:mr-4 text-gray-800 font-bold text-sm sm:truncate"
              v-text="fullName"
            />
            <div v-if="client.assignedSecondaryAgent">
              Assigned Team Member: <span class="font-bold">{{ secondaryAgentName(client.assignedSecondaryAgent) }}</span>
            </div>
            <div class="text-left text-sm sm:truncate">
              <div
                v-for="(methods, name) in contactMethods"
                :key="name"
                class="sm:inline sm:mr-4"
                v-text="methods"
              />
            </div>
          </div>
          <div
            v-if="price"
            class="text-right"
          >
            <p
              v-if="priceLabel"
              class="text-xs pt-1"
              v-text="priceLabel"
            />
            <div :class="isSold && 'flex md:flex-row flex-col-reverse items-end space-x-2'">
              <c-link
                v-if="isSold && iPayText"
                link="https://idealagent.ingageipay.com/Account/SignIn.aspx"
                class-link="mt-1 px-6 py-2 md:m-0 md:px-3 md:py-1 bg-blue-900 text-md sm:text-xs text-white font-medium uppercase rounded-full hover:bg-blue-800 hover:underline"
                :text="iPayText"
                target="_blank"
              />
              <p
                class="font-sans sm:text-md md:ml-2 md:text-lg lg:text-xlg font-bold text-gray-700"
                v-text="price"
              />
            </div>
          </div>
        </div>
        <div
          v-if="client.needUpdateReason"
          class="text-red-600 mt-2 text-left sm:text-center text-sm whitespace-normal"
          v-text="client.needUpdateReason"
        />
        <div class="sm:flex flex-wrap justify-between mt-2">
          <div class="flex flex-wrap">
            <p
              v-for="tag in displayedTags"
              :key="tag.name"
              class="my-1 mr-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full border-2"
              :class="`bg-${ tag.color }-100 text-${ tag.color }-800 border-${ tag.color }-300`"
              v-text="tag.name"
            />
          </div>
          <div class="flex items-center text-sm mt-2 sm:mt-0">
            <calendar-icon
              color="gray-500"
              class="w-5 h-5 mr-1 flex-none"
            />
            <p v-if="formattedLastDate">
              {{ client.lastEvent?.label }} {{ formattedLastDate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <CollectionStatusPill
      v-if="client.collectionStatus && showCollectionAlerts"
      class="flex items-center justify-center w-8 text-sm font-medium rounded-r-md"
      :status-name="client.collectionStatus"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CalendarIcon } from '@/components/icons';
import { CLink } from '@/components/controls';
import CollectionStatusPill from '@/components/CollectionStatusPill.vue';
import {
  formatDate,
  formatPhoneNumber,
  getBadges,
  isIncludedSoldTag,
  getCurrentPriceFromClient,
  getCurrentPriceLabelFromClient,
  getTags,
  getIPayText,
} from '@/utils';

export default {
  name: 'ReferralCard',
  components: {
    CalendarIcon,
    CollectionStatusPill,
    CLink,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('agent', ['secondaryAgentName', 'collectionInstruction', 'showCollectionAlerts']),
    labelColor() {
      const { clientType } = this.client;
      const isSellerOrBoth = clientType === 'SELLER' || clientType === 'BOTH';
      const isBuyer = clientType === 'BUYER';
      const colorClasses = { 'bg-blue-500': isSellerOrBoth, 'bg-green-500': isBuyer };
      return colorClasses;
    },
    labelText() {
      const { clientType } = this.client;
      const isBoth = clientType === 'BOTH';
      return isBoth ? 'SELLER' : clientType;
    },
    fullName: vm => `${vm.client.firstName} ${vm.client.lastName}`,
    contactMethods() {
      return {
        phones: this.client.phones?.map(p => formatPhoneNumber(p.phoneNumber)).join(', '),
        emails: this.client.emails?.map(e => e.emailAddress).join(', '),
      };
    },
    displayedTags: vm => {
      const {
        needUpdate, nextFollowUp, offer, offerStatus,
      } = vm.client;
      const tags = getTags(vm.client, vm.formSubmitted);
      if (vm.formSubmitted || !needUpdate) return tags;

      const badges = getBadges({ nextFollowUp, offer, offerStatus });
      return [...tags, ...badges];
    },
    iPayText: vm => getIPayText(vm.client),
    isSold: vm => isIncludedSoldTag(vm.displayedTags),
    formattedLastDate() {
      const { exchangeDate } = this.client;
      return exchangeDate && formatDate(exchangeDate, 'slashes', true);
    },
    price() {
      return getCurrentPriceFromClient(this.client);
    },
    priceLabel() {
      return getCurrentPriceLabelFromClient(this.client);
    },
  },
};
</script>
