export const dateFormats = {
  iso: 'YYYY-MM-DD',
  humanReadable: 'MMMM D, YYYY',
  slashes: 'MM/DD/YYYY',
};

export const dateTimeFormats = {
  iso: 'YYYY-MM-DDTHH:mm:ss',
  humanReadable: 'MMMM D, YYYY HH:mm A',
};
