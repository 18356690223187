export const tabs = [
  {
    name: 'updateForm',
    label: 'Update',
  },
  {
    name: 'assistForm',
    label: 'Assist',
  },
  {
    name: 'history',
    label: 'History',
  },
  {
    name: 'attachments',
    label: 'Attachments',
  },
];

export const tabTypes = {
  updateForm: 'updateForm',
  assistForm: 'assistForm',
  history: 'history',
  attachments: 'attachments',
};

export const formTypes = {
  PCF: 'PCF',
  LAF: 'LAF',
  ICF: 'ICF',
  SPF: 'SPF',
  BUF: 'BUF',
  BSF: 'BSF',
  IBCF: 'IBCF',
  IOCF: 'IOCF',
  IOVAF: 'IOVAF',
  IOICF: 'IOICF',
  IOF: 'IOF',
  IOSPF: 'IOSPF',
  ALCF: 'ALCF',
};

export const formPlaceholderByStatus = {
  OFF_MARKET: 'Property is in off-market status. Use the assist button to provide a client status update.',
  TEMPORARY_OFF_MARKET: 'Use the assist button to provide a client status update.',
};

export const formRedirections = {
  SPF: 'PcfForm',
};
