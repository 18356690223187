import { booleanOptions } from '@/constants/dropdown';

const interestedProgramOptions = [
  'Cash Offer Only',
  'Sell and Leaseback',
];

const leasebackTimeNeededOptions = [
  'Single Family Home',
  'Condominium',
  'Townhome',
  'Villa',
  'Multi-Family',
  'Manufactured Home',
  'Land',
  'Commercial',
  'Other',
];

const fireplaceTypeOptions = [
  'Wood Burning',
  'Gas Starter',
  'Mock',
];

const waterSupplyOptions = [
  'Public - City',
  'Well',
];

const sewerOptions = [
  'Public - City',
  'Septic Tank',
];

const solarPanelsOptions = [
  'Yes - Owned',
  'Yes - Leased',
  'No',
];

const poolOptions = [
  'Inground',
  'Above ground',
  'None',
];

const poolTypeOptions = [
  'Concrete',
  'Liner',
];

const ternaryOptions = [
  'Yes',
  'No',
  'Unsure',
];

const lotSizeOptions = [
  'Less than 1/2 acre',
  '1/2 - 1 acre',
  '1 acre plus',
];

const homeConditionOptions = [
  'Very good(needs no work inside or outside)',
  'Fair (could use some things inside or outside)',
  'Poor(needs work inside or outside)',
];

const waterHeaterTypeOptions = [
  'Tank',
  'Tankless',
];

const currentOccupancyOptions = [
  'Owner',
  'Tenant',
  'Vacant',
];

export const questionnaireFormFields = [
  {
    component: 'CDropdown',
    name: 'interestedProgram',
    label: 'What program is the client interested in?',
    props: {
      options: interestedProgramOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'leasebackTimeNeeded',
    label: 'How long does the client need a leaseback?',
    props: {
      options: leasebackTimeNeededOptions,
    },
  },
  {
    component: 'CTextInput',
    name: 'bedroomsNum',
    label: 'No. of Bedrooms',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CTextInput',
    name: 'fullBathNum',
    label: 'No. of Full Bathrooms',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CTextInput',
    name: 'halfBathNum',
    label: 'No. of Half Bathrooms',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CTextInput',
    name: 'floorsNum',
    label: 'No. of Floors (Including Basement)',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CDropdown',
    name: 'basement',
    label: 'Does the home have a basement?',
    props: {
      options: booleanOptions,
    },
  },
  {
    component: 'CTextInput',
    name: 'yearBuilt',
    label: 'Year Built',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CTextInput',
    name: 'sqFootage',
    label: 'Square Footage',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CTextInput',
    name: 'garageSpaces',
    label: 'How many garage spaces?',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CTextInput',
    name: 'carportSpaces',
    label: 'How many Carport spaces?',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CDropdown',
    name: 'fireplace',
    label: 'Is there a fireplace?',
    props: {
      options: booleanOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'fireplaceType',
    label: 'Is the fireplace wood burning, gas starter or mock?',
    props: {
      options: fireplaceTypeOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'waterSupply',
    label: 'Water Supply',
    props: {
      options: waterSupplyOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'sewer',
    label: 'Sewer',
    props: {
      options: sewerOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'solarPanels',
    label: 'Solar Panels?',
    props: {
      options: solarPanelsOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'pool',
    label: 'Pool',
    props: {
      options: poolOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'poolType',
    label: 'Pool Type',
    props: {
      options: poolTypeOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'hoa',
    label: 'Is there an HOA?',
    props: {
      options: booleanOptions,
    },
  },
  {
    component: 'CTextInput',
    name: 'monthlyHoaFees',
    label: 'Monthly HOA fees',
    props: {
      type: 'currency',
    },
  },
  {
    component: 'CTextInput',
    name: 'quarterlyHoaFees',
    label: 'Quarterly HOA Fees',
    props: {
      type: 'currency',
    },
  },
  {
    component: 'CTextInput',
    name: 'annualHoaFees',
    label: 'Annual HOA fees',
    props: {
      type: 'currency',
    },
  },
  {
    component: 'CDropdown',
    name: 'hoaAllowsRentals',
    label: 'Does the HOA allow rentals in the neighborhood?',
    props: {
      options: ternaryOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'lotSize',
    label: 'Lot Size',
    props: {
      options: lotSizeOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'homeInFloodZone',
    label: 'Is your home in a Flood Zone?',
    props: {
      options: ternaryOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'homeCondition',
    label: 'Home Condition',
    props: {
      options: homeConditionOptions,
    },
  },
  {
    component: 'CTextInput',
    name: 'ageOfRoof',
    label: 'Age of Roof',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CTextInput',
    name: 'ageOfHvac',
    label: 'Age of HVAC',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CDropdown',
    name: 'waterHeaterType',
    label: 'Water Heater Type',
    props: {
      options: waterHeaterTypeOptions,
    },
  },
  {
    component: 'CTextInput',
    name: 'waterHeaterAge',
    label: 'Water Heater Age',
    props: {
      type: 'number',
      min: 0,
    },
  },
  {
    component: 'CDropdown',
    name: 'currentOccupancy',
    label: 'Current Occupancy',
    props: {
      options: currentOccupancyOptions,
    },
  },
  {
    component: 'CDropdown',
    name: 'leaseInPlace',
    label: 'Is there a lease in place?',
    props: {
      options: booleanOptions,
    },
  },
  {
    component: 'CDatePicker',
    name: 'leaseExpirationDate',
    label: 'Expiration Date of Lease',
    props: {
      closeOnSelect: true,
      dateFormat: 'Y-m-d',
    },
  },
];
