<template>
  <!-- eslint-disable max-len -->
  <div class="lg:hidden">
    <div class="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
      <router-link to="/">
        <img
          src="@/assets/ia-logo.png"
          class="h-8 w-auto"
        >
      </router-link>
      <div>
        <button
          type="button"
          class="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
          @click="$emit('show-sidebar')"
        >
          <span class="sr-only">Open sidebar</span>
          <hamburger-icon
            class="h-6 w-6 stroke-current"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { HamburgerIcon } from '@/components/icons';

export default {
  name: 'MobileHeader',
  components: {
    HamburgerIcon,
  },
};
</script>
