<template>
  <div class="pb-5 space-y-3">
    <p>Upload attachment:</p>
    <div class="flex space-x-5">
      <c-dropdown
        :value="attachmentType"
        :options="attachmentTypeOptions"
        :error-message="errorMessages.attachmentType"
        placeholder="Select Attachment Type"
        class="border-gray-300 w-72"
        variant="demo"
        @input="$emit('changeAttachmentType', $event)"
      />
      <c-file-input
        :error-message="errorMessages.attachments"
        class="pt-2"
        multiple
        @change="$emit('changeFile', $event)"
      />
    </div>
    <div class="text-gray-700 text-xs">
      <p>Allowed file types for video: MP4, MOV</p>
      <p>Allowed file types for photos: JPEG, PNG</p>
    </div>
  </div>
</template>

<script>
import { CFileInput, CDropdown } from '@/components/inputs';
import { attachmentTypeOptions } from '@/components/forms/offer-forms/constants';

export default {
  name: 'AttachmentUpload',
  components: {
    CFileInput,
    CDropdown,
  },
  props: {
    attachmentType: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    attachmentTypeOptions,
  }),
};
</script>
