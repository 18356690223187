<template>
  <div
    :class="`border-l-4 border-${alert.color}-400 bg-${alert.color}-50 p-2 ${alert.wrapperClass}`"
  >
    <div
      class="flex"
      :class="{'justify-center': isCentered}"
    >
      <div class="flex-shrink-0">
        <ExclamationIcon
          v-if="showIcon && alert.icon === 'ExclamationTriangleIcon'"
          :class="`h-5 w-5 text-${alert.color}-400`"
        />
        <XCircleIcon
          v-if="showIcon && alert.icon === 'XCircleIcon'"
          :class="`h-5 w-5 text-${alert.color}-400`"
        />
        <CheckCircleIcon
          v-if="showIcon && alert.icon === 'CheckCircleIcon'"
          :class="`h-5 w-5 text-${alert.color}-400`"
        />
        <InformationCircleIcon
          v-if="showIcon && alert.icon === 'InformationCircleIcon'"
          :class="`h-5 w-5 text-${alert.color}-400`"
        />
      </div>
      <div class="ml-3">
        <p :class="`text-sm text-${alert.color}-700${isBold ? ' font-bold' : ''}`">
          {{ alert.text }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ExclamationIcon,
  XCircleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from '@vue-hero-icons/solid';

export default {
  name: 'SiteAlert',
  components: {
    ExclamationIcon,
    XCircleIcon,
    CheckCircleIcon,
    InformationCircleIcon,
  },
  props: {
    alert: {
      type: Object,
      required: true,
    },
    isBold: Boolean,
    showIcon: Boolean,
    isCentered: Boolean,
  },
};
</script>
