<template>
  <form-input :error-message="errorMessage">
    <div class="flex space-x-5 mr-2">
      <label
        v-for="option in options"
        :key="option.label"
        class="flex items-center"
      >
        <input
          :checked="option === value"
          type="radio"
          class="w-4 h-4 flex-shrink-0 text-indigo-600 border-gray-500 focus:ring-indigo-500"
          @change="$emit('input', option.value)"
        >
        <span
          class="ml-2 block text-sm font-medium text-gray-700"
          v-text="option.label"
        />
      </label>
    </div>
  </form-input>
</template>

<script>
import FormInput from './FormInput.vue';

export default {
  components: {
    FormInput,
  },
  props: {
    value: {
      type: [String, Boolean],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
};
</script>
