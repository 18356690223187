<template>
  <svg
    v-bind="$attrs"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path
      d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
      :fill="fillColor"
    />
    <path
      d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import { getHexByColorName } from '@/utils';

export default {
  name: 'MailIcon',
  props: {
    color: {
      type: String,
      default: 'gray-400',
    },
  },
  computed: {
    fillColor: vm => getHexByColorName(vm.color),
  },
};
</script>
