<template>
  <div>
    <c-button
      ref="button"
      type="button"
      :is-loading="isLoading"
      :disabled="isLoading"
      @click="handleGenerateStatement"
    >
      Generate Statement
    </c-button>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { downloadFileByBlob } from '@/utils';
import { CButton } from '@/components/controls';

export default {
  name: 'GenerateStatement',
  components: {
    CButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isSeller: {
      type: Boolean,
      default: true,
    },
    fileName: {
      type: String,
      required: false,
      default: 'statement.pdf',
    },
    queryString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('client', ['fetchStatement']),
    async handleGenerateStatement() {
      try {
        this.isLoading = true;
        const fileBlob = await this.fetchStatement({ id: this.id, isSeller: this.isSeller, queryString: this.queryString });
        downloadFileByBlob(fileBlob, this.fileName);
        this.$emit('confirm');
        this.$toast.success('Statement Downloaded');
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
