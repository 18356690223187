<template>
  <!-- eslint-disable max-len -->

  <!-- Overlay -->
  <div class="bg-black p-5 md:p-8 absolute inset-0 z-50 bg-opacity-70">
    <!--Window -->
    <div class="bg-white rounded-md shadow-md relative">
      <main class="p-5">
        <slot />
      </main>
      <footer class="p-5 rounded-b-md w-full bg-gray-200">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
};
</script>

<style>

</style>
