<template>
  <div>
    <div
      v-for="(link, i) in flyerLinks"
      :key="`flyer-links-${i}`"
      class="flex items-center"
    >
      <DownloadIcon class="mr-1" />
      <a
        class="text-blue-600 hover:text-blue-800 underline xs:text-xs xs:mr-7"
        target="_blank"
        :href="link.linkUrl"
        download
      >
        {{ link.linkText }}
      </a>
    </div>
  </div>
</template>

<script>
import DownloadIcon from '@/components/icons/DownloadIcon';

export default {
  components: {
    DownloadIcon,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'flyerLinks'],
};
</script>

<style scoped>
.margin-right {
      margin-right: 0.30rem;
    }
.margin-download {
    margin-right: 0.35rem;
}
</style>
