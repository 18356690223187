<template>
  <!-- eslint-disable max-len -->
  <svg
    v-bind="$attrs"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path
      d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import { getHexByColorName } from '@/utils';

export default {
  name: 'PhoneIcon',
  props: {
    color: {
      type: String,
      default: 'gray-400',
    },
  },
  computed: {
    fillColor: vm => getHexByColorName(vm.color),
  },
};
</script>
