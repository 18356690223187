<template>
  <label class="flex-auto">
    <form-input
      class="flex items-center ml-2"
      :error-message="errorMessage"
    >
      <t-checkbox
        :model="value"
        @input="handleInput"
      />
      <span
        class="ml-2"
        v-text="label"
      />
    </form-input>
  </label>
</template>

<script>
import FormInput from './FormInput';

export default {
  name: 'CCheckbox',
  components: {
    FormInput,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
