<template>
  <fieldset class="border border-gray-400 rounded-md p-3">
    <legend
      v-if="title"
      class="p-1"
      v-text="title"
    />
    <slot>
      {{ placeholder }}
    </slot>
  </fieldset>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Empty section',
    },
  },
};
</script>
