<template>
  <a
    :href="link"
    :class="classLink"
    :target="target"
    @click="preventEventPropagation"
    v-text="text"
  />
</template>

<script>
export default {
  name: 'CLink',
  props: {
    link: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    classLink: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  methods: {
    preventEventPropagation(e) {
      e.stopPropagation();
    },
  },
};
</script>
