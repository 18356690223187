<template>
  <div
    v-if="hasPackage"
    class="flex flex-col items-center gap-5"
  >
    <div
      class="flex flex-col flex-1 w-full max-w-5xl mx-auto xl:mx-0"
    >
      <div class="flex flex-col flex-1 bg-gray-50 rounded-medium divide-y divide-dashed divide-gray-400">
        <p class="text-center text-3xl text-gray-700 font-bold uppercase p-3">
          Listings On Demand
        </p>
        <div class="p-5">
          <listings-on-demand-table
            v-if="listingsOnDemand?.data?.length"
            :table="listingsOnDemand"
          />
        </div>
      </div>
      <div class="w-11/12 h-5 bg-gray-600 rounded-b-medium opacity-50 mx-auto" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ListingsOnDemandTable } from '@/components/tables';

export default {
  name: 'DashboardListingsOnDemand',
  components: {
    ListingsOnDemandTable,
  },
  data() {
    return {
      isLoading: false,
      hasPackage: false,
    };
  },
  computed: {
    ...mapState('agent', ['listingsOnDemand']),
    ...mapGetters('agent', ['currentListingsOnDemand']),
  },
  async mounted() {
    try {
      this.isLoading = true;
      await this.fetchProfileListingsOnDemand();
      if (this.listingsOnDemand?.data?.length) this.hasPackage = true;
    } catch ({ message }) {
      this.$toast.error(message);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('agent', ['fetchProfileListingsOnDemand']),
  },
};
</script>
