<template>
  <div class="grid xs:px-2 xs:py-2 xs:gap-3 xl:grid-cols-1 2xl:grid-cols-2 md:gap-6 xl:gap-6 md:p-20 xl:p-20 bg-blue-100 w-full overflow-auto">
    <Card
      v-for="(card, index) in cards"
      :key="index"
      v-bind="card"
    />
  </div>
</template>

<script>
import Card from '@/components/MarketingMaterialsCard.vue';
import cards from '@/components/constants/marketing.js';

export default {
  components: {
    Card,
  },
  data() {
    return {
      cards,
    };
  },
};
</script>
