export const personalInfo = [
  'firstName',
  'lastName',
  'brokerage',
];

export const assistantInfo = [
  'assistant',
  'assistantEmail',
  'assistantPhone',
];

export const addressInfo = [
  'mailingCountry',
  'mailingState',
  'mailingCity',
  'mailingStreet',
  'mailingStreet2',
  'mailingZip',
];
