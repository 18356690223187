// ICF constants

export const appointmentOptions = [
  'Yes',
  'Not Set Yet',
];

export const notSetReasonOptions = [
  'Not Ready (repairs, paint, etc)',
  'Not Decision Maker',
  'Currently Listed',
  'For Sale By Owner',
  'Not Selling',
  'Went with Another Agent',
  'Property Sold',
  'iBuyer Platform',
  'Pricing Expectations are unrealistic',
  'Other',
];

export const icfFields = [
  'isContactMadeWithReferral',
  'setListingAppointment',
  'listingApptDateTime',
  'nextFollowUp',
  'introCallComments',
  'icfLafNoReason',
  'agentMarkedAsDeadRequest',
];

export const iospfFields = [
  'soldDate',
  'comments',
  'scheduledClosingDate',
  'closingOccurrenceOnTheScheduledDate',
];

export const alcfFields = [
  'option',
  'activeListingDate',
  'activeListingConfirmationComments',
  'listPrice',
];

export const listingAppointmentStates = {
  set: 'Yes',
  notSet: 'Not Set Yet',
};

// LAF constants

export const agreementOptions = [
  'Yes',
  'Soon',
  'Undecided',
  'Listing Appointment Cancelled',
  'No - Lost Listing',
];

export const reasonsForNo = [
  'Not Selling',
  'Went with another agent',
  'Poor Property Condition',
  'Overpriced and Not Open to Reduce',
  'Trying For Sale By Owner',
  'Other',
];

export const reasonsForSoon = [
  'House preparation or repairs',
  'Seller has listing agreement',
  'Waiting until later in the year',
];

export const reasonsForUndecided = [
  'Interviewing other agents',
  'Trying For Sale By Owner',
  'Currently Listed - Waiting on Exp or Release',
];

export const lafFields = [
  'listingAgreementSigned',
  'listingAgreementSignedDate',
  'activeListingDate',
  'listPrice',
  'nextFollowUp',
  'noFurtherFollowUpRequired',
  'didNotSignReason',
  'listingApptDate',
  'listingAppointmentComments',
];

export const secureListingStates = {
  yes: 'Yes',
  soon: 'Soon',
  undecided: 'Undecided',
  cancelled: 'Listing Appointment Cancelled',
  no: 'No - Lost Listing',
};

// PCF constants

export const directBuyerOptions = [
  {
    value: true,
    text: 'Yes',
  },
  {
    value: false,
    text: 'No',
  },
];

export const defaultListingCommissionPercent = 2;

export const pcfFields = [
  'contractDate',
  'closingDate',
  'sellerConcession',
  'contractPrice',
  'directBuyer',
  'comments',
  'commissionReduction',
  'otherFee',
  'sellerConcessionComments',
  'buyerCommissionPercent',
  'listingCommissionPercent',
  'transactionFee',
];

// SPF constants

export const closingDateOptions = [
  'Yes',
  'No',
];

export const propertyCommissionPercentageTypes = ['LAND', 'COMMERCIAL'];
