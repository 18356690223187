<template>
  <!-- eslint-disable max-len -->
  <div>
    <c-button
      ref="button"
      :disabled="disabled"
      type="button"
      @click="setShowCFT(true)"
    >
      Contract Fell Through
    </c-button>
    <div
      v-show="showCFT"
      ref="toggle"
      class="bg-black text-white text-xs rounded p-4 right-0 bottom-full max-w-xs"
    >
      <p>
        Are you sure you would like to clear the contract information for
        {{ address }} and place the {{ profileType }} back in Active status?
      </p>
      <div class="w-full flex justify-evenly mt-5">
        <c-button
          class="w-1/2"
          type="button"
          :is-loading="isLoading"
          @click="handleContractFellThrough"
        >
          Yes
        </c-button>
        <c-button
          class="w-1/2 ml-2"
          type="button"
          @click="setShowCFT(false)"
        >
          No
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { mapActions, mapState } from 'vuex';
import { CButton } from '@/components/controls';
import { CFTOptionsByClientType } from './constants';

export default {
  name: 'ContractFellThrough',
  components: {
    CButton,
  },
  props: {
    profileType: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  data() {
    return {
      isLoading: false,
      popperInstance: null,
      showCFT: false,
    };
  },
  computed: {
    ...mapState('agent', ['user', 'agent']),
    ...mapState('client', ['client']),
  },
  mounted() {
    const tooltip = this.$refs.toggle;
    const toggle = this.$refs.button.$el;

    this.popperInstance = createPopper(toggle, tooltip, {
      placement: 'top',
      offset: [0, 0],
    });
  },
  beforeDestroy() {
    this.popperInstance.destroy();
  },
  methods: {
    ...mapActions('client', ['contractFellThrough']),
    async handleContractFellThrough() {
      const clientOptions = CFTOptionsByClientType[this.client.clientType];
      const { pathName, idName, clientIdName } = clientOptions || {};

      const params = {
        agentId: this.agent.id,
        [idName]: this.client[clientIdName],
      };
      const payload = { params, pathName };
      try {
        this.isLoading = true;
        await this.contractFellThrough(payload);
        this.$emit('confirm');
        this.$toast.success('Forms cleared!');
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isLoading = false;
        this.setShowCFT(false);
      }
    },
    setShowCFT(value) {
      this.showCFT = value;
    },
  },
};
</script>
