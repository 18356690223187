export const tagMapping = {
  buyer: {
    name: 'Buyer',
    color: 'teal',
  },
  seller: {
    name: 'Seller',
    color: 'blue',
  },
  lost: {
    name: 'Lost',
    color: 'gray',
  },
  needUpdate: {
    name: 'Needs Update',
    color: 'rose',
  },
  offer: {
    name: 'Offer',
    color: 'violet',
  },
};

export const lostStatus = {
  name: 'Lost',
  color: 'gray',
};

export const statusTypes = [
  {
    propertyStatuses: ['active'],
    name: 'Active',
    color: 'green',
  },
  {
    propertyStatuses: ['unknown', 'temp_off_market'],
    name: 'Off Market',
    color: 'gray',
  },
  {
    propertyStatuses: ['pending'],
    name: 'Pending',
    color: 'yellow',
  },
  {
    propertyStatuses: ['sold'],
    name: 'Sold',
    color: 'red',
  },
  {
    propertyStatuses: ['expired'],
    name: 'Expired',
    color: 'gray',
  },
  {
    propertyStatuses: ['withdrawn'],
    name: 'Withdrawn',
    color: 'pink',
  },
  {
    propertyStatuses: ['null', 'off_market'],
    name: 'Off Market',
    color: 'gray',
  },
];

export const buyerStatusTypes = [
  {
    statuses: ['connect'],
    name: 'Connect',
    color: 'green',
  },
  {
    statuses: ['pending'],
    name: 'Pending',
    color: 'gray',
  },
  {
    statuses: ['showing_properties'],
    name: 'Showing Properties',
    color: 'orange',
  },
  {
    statuses: ['not_ready'],
    name: 'Not Ready',
    color: 'indigo',
  },
  {
    statuses: ['sold'],
    name: 'Sold',
    color: 'red',
  },
  {
    statuses: ['lost_no'],
    ...lostStatus,
  },
];
