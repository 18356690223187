<template>
  <div
    class="bg-white border rounded-lg shadow px-6 sm:px-8 py-8 sm:py-10 flex flex-col justify-between"
  >
    <div>
      <div
        v-if="isImage(title)"
        class="title-image-container"
      >
        <img
          :src="title"
          alt=""
          class="xs:w-68 sm:w-22rem 2xl:w-25rem"
        >
      </div>
      <h1
        v-else
        class="text-2xs sm:text-4xl text-center pb-2"
      >
        {{ title }}
      </h1>
      <div
        class="flex pt-4 sm:pt-7 xs:text-xs md:text-sm"
        :class="contactProps.title ? 'justify-between flex-col sm:flex-row' : 'flex-row'"
      >
        <ContactDetails
          v-if="contactProps.title"
          class="mb-4 pr-2 overflow-auto"
          v-bind="contactProps"
        />
        <OrdersDetails
          v-if="ordersTitle"
          class="flex flex-col mb-4 w-full overflow-auto"
          :class="{'sm:w-1/2': contactProps.title }"
          :orders="orders"
          :orders-title="ordersTitle"
        />
      </div>
    </div>
    <FlyerLinks
      class="flex flex-col items-end"
      v-bind="flyerProps"
    />
  </div>
</template>

<script>
import ContactDetails from '@/components/ContactDetails.vue';
import OrdersDetails from '@/components/OrderDetails.vue';
import FlyerLinks from '@/components/FlyerLinks.vue';

export default {
  components: {
    ContactDetails,
    OrdersDetails,
    FlyerLinks,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['title', 'contactTitle', 'phoneNumber', 'email', 'ordersTitle', 'orders', 'phoneImg', 'emailImg', 'type', 'flyerLinks'],
  computed: {
    contactProps() {
      return {
        title: this.contactTitle,
        phoneNumber: this.phoneNumber,
        email: this.email,
        phoneImg: this.phoneImg,
        emailImg: this.emailImg,
      };
    },
    flyerProps() {
      return {
        type: this.type,
        flyerLinks: this.flyerLinks,
      };
    },
  },
  methods: {
    isImage(str) {
      return /\.(jpe?g|png|gif)$/i.test(str);
    },
  },
};
</script>

<style scoped>
    .title-image-container {
      display: flex;
      justify-content: center;
    }
    .shadow {
      box-shadow: 3px 4px 4px 0px #2d3748;
    }
    .border {
      border-radius: 4rem;
    }
    @media screen and (min-height: 1200px) {
      .bg-white {
        height: 35rem;
      }
    }
</style>
