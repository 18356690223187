export const filterControlsMapping = [{
  property: 'sellers',
  label: 'Sellers',
}, {
  property: 'buyers',
  label: 'Buyers',
}, {
  property: 'pending',
  label: 'Pending',
}, {
  property: 'needsUpdates',
  label: 'Needs Updates',
}, {
  property: 'sold',
  label: 'Sold',
}, {
  property: 'offMarket',
  label: 'Off Market',
}, {
  property: 'lost',
  label: 'Lost',
}, {
  property: 'idealOffer',
  label: 'Offer',
}];

export const flagControlsMapping = [{
  property: 'idealOffer',
  label: 'Offer',
}, {
  property: 'needsUpdates',
  label: 'Needs Updates',
}];

export const typeControlsMapping = [{
  property: 'sellers',
  label: 'Sellers',
}, {
  property: 'buyers',
  label: 'Buyers',
}];

export const propertyStatusControlsMapping = [{
  property: 'offMarket',
  label: 'Off Market',
}, {
  property: 'active',
  label: 'Active',
}, {
  property: 'pending',
  label: 'Pending',
}];

export const hideControlsMapping = [{
  property: 'lost',
  label: 'Hide Lost',
}, {
  property: 'sold',
  label: 'Hide Sold',
}];
