<template>
  <form class="space-y-8 divide-y divide-blue-gray-300">
    <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
      <div class="sm:col-span-6">
        <h2 class="text-xl font-medium text-blue-gray-900">
          Contact Information
        </h2>
        <p class="mt-1 text-sm text-blue-gray-500">
          This information will be displayed publicly so be careful what you share.
        </p>
      </div>

      <div class="sm:col-span-6">
        <p>Email address</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-if="!loading.details"
              :value="email"
              class="mt-1 block w-full sm:text-sm rounded-md"
              placeholder="Enter an email address"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { CTextInput } from '@/components/inputs';

export default {
  name: 'UpdateEmail',
  components: {
    CTextInput,
  },
  data: () => ({
    email: '',
  }),
  computed: {
    ...mapState('agent', ['agent', 'loading']),
  },
  watch: {
    agent() {
      this.prepareData();
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      this.email = this.agent.emails?.[0]?.emailAddress || '';
    },
  },
};
</script>
