<template>
  <!-- eslint-disable max-len -->
  <div>
    <t-alert
      v-if="previousBsfSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      {{ fileReceivedText }}
    </t-alert>

    <t-alert
      v-else-if="bsfSubmitted"
      :dismissible="false"
      variant="success"
      show
      class="mb-5"
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <spinner v-if="isFetchingFields" />
      <template v-else>
        <div
          v-if="!bufSubmitted"
          class="pb-5"
        >
          <p>Did the closing occur on the scheduled closing date?</p>
          <c-dropdown
            v-model="didPropertyClose"
            class="mt-3 border-gray-300"
            :options="propertyClosingOptions"
          />
        </div>

        <form
          v-show="showFileUpload"
          class="pb-8"
          @submit.prevent="handleSubmit"
        >
          <div class="pb-5">
            <p>Final Sold Date</p>
            <c-date-picker
              v-model="$v.finalClosingDate.$model"
              close-on-select
              class="mt-3"
              :max-date="new Date()"
              :error-message="getDirtyErrorMessage('finalClosingDate')"
            />
          </div>

          <div class="pb-5">
            <p>Upload ALTA (Final Settlement Statement)</p>
            <c-text-input
              type="file"
              class="mt-3"
              :value="altaFilePath"
              :error-message="getDirtyErrorMessage('altaFile')"
              @change="setFile"
            />
          </div>

          <c-button
            :is-loading="isSubmitting"
            type="submit"
          >
            Save
          </c-button>
        </form>

        <buf-form
          v-if="showBufForm"
          :client="client"
          @submit="handleSubmitBuf"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { formMixin } from '@/mixins';
import { CDropdown, CDatePicker, CTextInput } from '@/components/inputs';
import { CButton } from '@/components/controls';
import Spinner from '@/components/Spinner.vue';
import BufForm from './BufForm';
import { propertyClosingOptions } from './constants.js';
import { fileReceivedMessage } from '../constants';

export default {
  name: 'BsfForm',
  components: {
    CDropdown,
    CDatePicker,
    CTextInput,
    CButton,
    BufForm,
    Spinner,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    didPropertyClose: null,
    finalClosingDate: null,
    altaFile: null,
    altaFilePath: '',
    bsfSubmitted: false,
    bufSubmitted: false,
    previousBsfSubmitted: false,
    isSubmitting: false,
    isFetchingFields: false,
    propertyClosingOptions,
    fileReceivedText: fileReceivedMessage,
  }),
  validations: () => ({
    finalClosingDate: { required },
    altaFile: { required },
  }),
  computed: {
    showFileUpload: vm => vm.didPropertyClose === propertyClosingOptions[0],
    showBufForm: vm => vm.didPropertyClose === propertyClosingOptions[1],
  },
  mounted() {
    this.fetchFields();
  },
  methods: {
    ...mapActions('client', ['saveBsf', 'fetchBsf']),
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.isSubmitting = true;
        const formData = new FormData();
        formData.append('file', this.altaFile, this.altaFile.name);
        const payload = {
          agentId: +this.client.assignedAgent,
          clientId: this.client.buyerProfileReferralId,
          file: formData,
          finalClosingDate: this.finalClosingDate,
        };
        await this.saveBsf(payload);
        this.$emit('submit');
        this.bsfSubmitted = true;
        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchFields() {
      try {
        this.isFetchingFields = true;
        const response = await this.fetchBsf(this.client.id);
        this.previousBsfSubmitted = response.bsfSubmitted;
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isFetchingFields = false;
      }
    },
    handleSubmitBuf() {
      this.bufSubmitted = true;
    },
    setFile(event) {
      this.altaFilePath = event.target.value;
      this.$v.altaFile.$model = event.target.files[0];
    },
  },
};
</script>
