export const dashboardColorScheme = {
  Referrals: 'bg-indigo-700',
  Pending: 'bg-yellow-500',
  Sold: 'bg-green-500',
  Lost: 'bg-red-700',
  Active: 'bg-blue-700',
  'Expired / Withdrawn / Off Market / Temp Off Market': 'bg-gray-500',
  'Referred Not Active': 'bg-blue-500',
  'Referred Not Listed': 'bg-blue-500',
  'Referred Not Video Appt Completed': 'bg-blue-500',
  'Video Appt Completed': 'bg-blue-700',
  'Video Appt Cancelled': 'bg-gray-500',
};

export const dashboardChartIgnoredFields = ['Referrals'];
export const dashboardDetailColumns = [
  {
    title: 'Sellers',
    totalPath: 'seller.total',
    emptyMessage: 'New Seller Referrals Coming Soon',
    fields: [
      {
        label: 'Referrals',
        countPath: 'seller.referralsCount',
        valuePath: 'seller.referrals',
      },
      {
        label: 'Pending',
        countPath: 'seller.pendingCount',
        valuePath: 'seller.pending',
      },
      {
        label: 'Sold',
        countPath: 'seller.soldCount',
        valuePath: 'seller.sold',
      },
      {
        label: 'Lost',
        countPath: 'seller.lostCount',
        valuePath: 'seller.lost',
      },
      {
        label: 'Active',
        countPath: 'seller.activeCount',
        valuePath: 'seller.active',
      },
      {
        label: 'Referred Not Listed',
        countPath: 'seller.referredNotListedCount',
        valuePath: 'seller.referredNotListed',
      },
      {
        label: 'Expired / Withdrawn / Off Market / Temp Off Market',
        countPath: 'seller.expiredCount',
        valuePath: 'seller.expired',
      },
    ],
  },
  {
    title: 'Buyers',
    totalPath: 'buyer.total',
    emptyMessage: 'New Buyer Referrals Coming Soon',
    fields: [
      {
        label: 'Referrals',
        countPath: 'buyer.referralsCount',
        valuePath: 'buyer.referrals',
      },
      {
        label: 'Pending',
        countPath: 'buyer.pendingCount',
        valuePath: 'buyer.pending',
      },
      {
        label: 'Sold',
        countPath: 'buyer.soldCount',
        valuePath: 'buyer.sold',
      },
      {
        label: 'Lost',
        countPath: 'buyer.lostCount',
        valuePath: 'buyer.lost',
      },
      {
        label: 'Active',
        countPath: 'buyer.activeCount',
        valuePath: 'buyer.active',
      },
      {
        label: 'Referred Not Active',
        countPath: 'buyer.referredNotActiveCount',
        valuePath: 'buyer.referredNotActive',
      },
    ],
  },
  // {
  //   title: 'Ideal Offers',
  //   totalPath: 'idealOffer.total',
  //   emptyMessage: 'New Ideal Offer Referrals Coming Soon',
  //   fields: [
  //     {
  //       label: 'Referrals',
  //       countPath: 'idealOffer.referralsCount',
  //       valuePath: 'idealOffer.referrals',
  //     },
  //     {
  //       label: 'Referred Not Video Appt Completed',
  //       countPath: 'idealOffer.referralsNotVideoApptCompletedCount',
  //       valuePath: 'idealOffer.referralsNotVideoApptCompleted',
  //     },
  //     {
  //       label: 'Video Appt Completed',
  //       countPath: 'idealOffer.videoApptCompletedCount',
  //       valuePath: 'idealOffer.videoApptCompleted',
  //     },
  //     {
  //       label: 'Video Appt Cancelled',
  //       countPath: 'idealOffer.videoApptCancelledCount',
  //       valuePath: 'idealOffer.videoApptCancelled',
  //     },
  //     {
  //       label: 'Pending',
  //       countPath: 'idealOffer.pendingCount',
  //       valuePath: 'idealOffer.pending',
  //     },
  //     {
  //       label: 'Sold',
  //       countPath: 'idealOffer.soldCount',
  //       valuePath: 'idealOffer.sold',
  //     },
  //   ],
  // },
];
