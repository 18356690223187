import { api, paths } from '@/api';

export const submitIocf = async (payload) => {
  await api.post(paths.IOCF_FORM, payload);
};

export const submitIovaf = async (payload, fileAttachmentType) => {
  await api.post(paths.IOVAF_FORM, payload, {
    params: { fileAttachmentType },
    contentType: 'multipart/form-data',
  });
};

export const submitIospf = async (payload, fileAttachmentType) => {
  const axiosOptions = { fileAttachmentType };
  await api.post(paths.IOSPF_FORM, payload, {
    params: axiosOptions,
    contentType: 'multipart/form-data',
  });
};

export const submitIoicf = async payload => {
  await api.post(paths.IOICF_FORM, payload);
};

export const submitIof = async payload => {
  await api.post(paths.IOF_FORM, payload);
};

export const sendPropertyAttachment = async (attachment, fileAttachmentType, propertyId) => {
  const params = { fileAttachmentType };
  await api.post(paths.PROPERTY_ATTACHMENTS(propertyId), attachment, {
    params,
    contentType: 'multipart/form-data',
  });
};
