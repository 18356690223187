<template>
  <form-input :error-message="errorMessage">
    <t-textarea
      v-bind="$attrs"
      :value="value"
      rows="7"
      class="mt-3 resize-none"
      :class="errorClass"
      @input="handleInput"
    />
  </form-input>
</template>

<script>
import FormInput from './FormInput.vue';

export default {
  name: 'CTextareaInput',
  components: {
    FormInput,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    isInvalid: vm => Boolean(vm.errorMessage),
    errorClass: vm => ({ 'border-red-500': vm.isInvalid }),
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
