<template>
  <div>
    <t-alert
      v-if="isSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>
    <template v-else>
      <div class="text-base font-bold pb-4">
        Provide the information below after your listing appointment
      </div>

      <form @submit.prevent="handleSubmit">
        <!-- Listing Agreement dropdown -->
        <div class="pb-5">
          <p>Did you secure the listing?</p>
          <c-dropdown
            v-model="$v.listingAgreementSigned.$model"
            class="mt-3 border-gray-300"
            variant="demo"
            :options="agreementOptions"
            @input="clearFields"
          />
        </div>
        <!-- Listing Agreement Signed Date  & Active Listing Date & List Price -->
        <div
          v-show="isAgreementSigned"
          class="pb-5"
        >
          <p>Listing Agreement Signed Date:</p>
          <c-date-picker
            v-model="$v.listingAgreementSignedDate.$model"
            close-on-select
            date-format="Y-m-d"
            :error-message="getDirtyErrorMessage('listingAgreementSignedDate')"
            class="mt-3 pb-5"
          />
          <p>Active Listing Date:</p>
          <c-date-picker
            v-model="$v.activeListingDate.$model"
            class="flex-auto mt-3 pb-5"
            close-on-select
            date-format="Y-m-d"
            :error-message="getDirtyErrorMessage('activeListingDate')"
          />
          <p>List Price:</p>
          <c-text-input
            v-model.number="$v.listPrice.$model"
            class="block w-full rounded-md"
            placeholder="List Price"
            :error-message="getDirtyErrorMessage('listPrice')"
            type="currency"
          />
        </div>
        <div
          v-show="!isAgreementSigned"
          class="pb-5"
        >
          <div
            v-show="didNotSignReasonShown"
            class="pb-5"
          >
            <p>Reason for not securing listing?</p>
            <c-dropdown
              v-model="$v.didNotSignReason.$model"
              class="mt-3 border-gray-300"
              variant="demo"
              :options="noReasonOptions"
              :error-message="getDirtyErrorMessage('didNotSignReason')"
            />
          </div>
          <!-- Next follow up date datepicker and checkbox -->
          <div v-show="nextFollowUpShown">
            <p>Next Follow Up Date:</p>
            <div class="flex items-center mt-3 pb-5">
              <c-date-picker
                v-model="$v.nextFollowUp.$model"
                class="flex-auto"
                close-on-select
                date-format="Y-m-d"
                :min-date="new Date()"
                :error-message="getDirtyErrorMessage('nextFollowUp')"
              />
            </div>
          </div>
          <no-further-followup
            v-show="noFurtherFollowUpRequiredShown"
            v-model="noFurtherFollowUpRequired"
            label="No further follow up required"
          />
          <!-- New listing appointment date -->
          <div v-show="listingAppointmentDateShown">
            <p>New Listing Appointment Date:</p>
            <c-date-picker
              v-model="$v.listingApptDate.$model"
              class="flex-auto mt-3 pb-5"
              mode="dateTime"
              placeholder="New Listing Appointment Date:"
              :error-message="getDirtyErrorMessage('listingApptDate')"
            />
          </div>
        </div>
        <!-- Details about the listing appointment - textarea-->
        <div
          v-show="listingAppointmentDetailsShown"
          class="pb-5"
        >
          <p>{{ commentsLabel }}:</p>
          <c-textarea-input
            v-model="$v.listingAppointmentComments.$model"
            :error-message="getDirtyErrorMessage('listingAppointmentComments')"
          />
        </div>
        <div class="mb-5">
          <c-button
            :is-loading="isSubmitting"
            type="submit"
          >
            Save
          </c-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import {
  CDatePicker,
  CDropdown,
  CTextareaInput,
  CTextInput,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import NoFurtherFollowup from '@/components/NoFurtherFollowup.vue';
import { formMixin } from '@/mixins';
import {
  greaterThanZero,
  reasonsForListingAppointmentCancelled,
} from '@/constants';
import {
  agreementOptions,
  reasonsForSoon,
  reasonsForUndecided,
  reasonsForNo,
  lafFields,
  secureListingStates,
} from './constants';

export default {
  name: 'LafForm',
  components: {
    CDatePicker,
    CDropdown,
    CTextInput,
    CTextareaInput,
    CButton,
    NoFurtherFollowup,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    listingAgreementSigned: null,
    listingAgreementSignedDate: null,
    listPrice: 0,
    activeListingDate: null,
    nextFollowUp: null,
    noFurtherFollowUpRequired: false,
    didNotSignReason: null,
    listingAppointmentComments: '',
    listingApptDate: null,
    isSubmitted: false,
    isSubmitting: false,
  }),
  validations() {
    return {
      listingAgreementSigned: {
        required,
      },
      listingAgreementSignedDate: {
        required: requiredIf(() => this.isAgreementSigned),
      },
      activeListingDate: {
        required: requiredIf(() => this.isAgreementSigned),
      },
      listPrice: {
        required: requiredIf(() => this.isAgreementSigned),
        greaterThanZero: !this.isAgreementSigned || greaterThanZero,
      },
      nextFollowUp: {
        required: requiredIf(() => this.nextFollowUpShown),
      },
      didNotSignReason: {
        required: requiredIf(() => !this.isAgreementSigned),
      },
      listingApptDate: {
        required: requiredIf(() => this.listingAppointmentDateShown),
      },
      listingAppointmentComments: {
        required,
        maxLength: maxLength(2000),
      },
    };
  },
  computed: {
    isAgreementSigned: vm => vm.listingAgreementSigned === 'Yes',
    secureListingStates: () => secureListingStates,
    agreementOptions: () => agreementOptions,
    noReasonOptions: vm => {
      if (vm.listingAgreementSigned === vm.secureListingStates.soon) {
        return reasonsForSoon;
      }
      if (vm.listingAgreementSigned === vm.secureListingStates.undecided) {
        return reasonsForUndecided;
      }
      if (vm.listingAgreementSigned === vm.secureListingStates.no) {
        return reasonsForNo;
      }
      if (vm.listingAgreementSigned === vm.secureListingStates.cancelled) {
        return reasonsForListingAppointmentCancelled;
      }
      return null;
    },
    didNotSignReasonShown: vm => {
      const states = [
        vm.secureListingStates.soon,
        vm.secureListingStates.undecided,
        vm.secureListingStates.no,
        vm.secureListingStates.cancelled,
      ];
      return states.includes(vm.listingAgreementSigned);
    },
    nextFollowUpShown: vm => {
      const states = [
        vm.secureListingStates.soon,
        vm.secureListingStates.undecided,
      ];
      return (states.includes(vm.listingAgreementSigned) || vm.didNotSignReason === 'Not Rescheduled');
    },
    noFurtherFollowUpRequiredShown: vm => (
      vm.listingAgreementSigned === vm.secureListingStates.no
    ),
    listingAppointmentDateShown: vm => (
      vm.didNotSignReason === 'Rescheduled'
    ),
    listingAppointmentDetailsShown: vm => Boolean(vm.listingAgreementSigned),
    commentsLabel: vm => {
      if (!vm.listingAppointmentDetailsShown) return '';
      const isNoSecureListing = vm.listingAgreementSigned === vm.secureListingStates.no;
      return isNoSecureListing ? 'Comments' : 'Please provide details about the listing appointment';
    },
    fieldConditions: vm => ({
      listingAgreementSignedDate: vm.isAgreementSigned,
      activeListingDate: vm.isAgreementSigned,
      listPrice: vm.isAgreementSigned,
      nextFollowUp: !vm.nextFollowUpDisabled && vm.nextFollowUpShown,
      didNotSignReason: vm.didNotSignReasonShown,
      listingApptDate: vm.listingAppointmentDateShown,
    }),
    fields: () => lafFields,
  },
  methods: {
    ...mapActions('client', ['saveLaf']),
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;
      const payload = {
        agentId: +this.client.assignedAgent,
        listingId: this.client.propertyReferralId,
        ...this.generatedPayload,
      };

      if (this.listingAgreementSigned === secureListingStates.cancelled) {
        payload.icfLafNoReason = payload.didNotSignReason;
        delete payload.didNotSignReason;
      }

      try {
        this.isSubmitting = true;
        await this.saveLaf(payload);
        this.$emit('submit');
        this.isSubmitted = true;

        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    clearFields() {
      this.didNotSignReason = null;
      this.listingAgreementSignedDate = null;
      this.activeListingDate = null;
      this.listPrice = '0';
      this.nextFollowUp = null;
      this.noFurtherFollowUpRequired = false;
      this.listingAppointmentComments = '';
      this.listingApptDate = null;
      this.$v.$reset();
    },
  },
};
</script>
