<!-- eslint-disable max-len -->
<template>
  <div>
    <nav class="mt-2 sm:mt-0 border-gray-400 border-b flex justify-between sm:justify-start cursor-pointer space-x-8">
      <div
        v-for="(tab,i) in tabs"
        :key="i"
        :class="getTabClass(tab.name)"
        @click="setCurrentTab(tab.name)"
        v-text="tab.label"
      />
    </nav>
    <div class="mt-8">
      <component
        :is="tabToDisplay"
        :client="client"
        :client-name="fullName"
        :client-zoho-id="client.zohoId"
        @confirm-cft="confirmCFT"
        @submit="handleSubmit"
      />
      <t-alert
        v-if="formPlaceholder"
        :dismissible="false"
        :variant="demeanor"
        show
      >
        {{ formPlaceholder }}
      </t-alert>
    </div>
  </div>
</template>

<script>
import { Attachments, History } from '@/components';
import {
  SpfForm,
  PcfForm,
  LafForm,
  AlcfForm,
  IcfForm,
  BufForm,
  IbcfForm,
  BsfForm,
  IocfForm,
  IovafForm,
  IoicfForm,
  IofFormGrid,
  AssistForm,
  IospfForm,
} from '@/components/forms';

import {
  formPlaceholderByStatus,
  formRedirections,
  tabTypes,
  formTypes,
  tabs,
} from './constants';

export default {
  name: 'LeadDetailsTabs',
  components: {
    SpfForm,
    PcfForm,
    LafForm,
    AlcfForm,
    IcfForm,
    BufForm,
    IbcfForm,
    BsfForm,
    IocfForm,
    IovafForm,
    IospfForm,
    IofFormGrid,
    AssistForm,
    History,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentTab: tabTypes.updateForm,
    triggeredCFT: null,
    tabs,
    demeanor: 'success',
  }),
  computed: {
    formToDisplay() {
      if (this.triggeredCFT) return formRedirections[this.triggeredCFT];

      switch (this.client.formToDisplay) {
        case formTypes.SPF: return SpfForm;
        case formTypes.PCF: return PcfForm;
        case formTypes.LAF: return LafForm;
        case formTypes.ICF: return IcfForm;
        case formTypes.BUF: return BufForm;
        case formTypes.IBCF: return IbcfForm;
        case formTypes.BSF: return BsfForm;
        case formTypes.IOCF: return IocfForm;
        case formTypes.IOVAF: return IovafForm;
        case formTypes.IOSPF: return IospfForm;
        case formTypes.IOICF: return IoicfForm;
        case formTypes.ALCF: return AlcfForm;
        case formTypes.IOF: return IofFormGrid;
        default: return null;
      }
    },
    tabToDisplay() {
      switch (this.currentTab) {
        case tabTypes.updateForm: return this.formToDisplay;
        case tabTypes.assistForm: return AssistForm;
        case tabTypes.history: return History;
        case tabTypes.attachments: return Attachments;
        default: return null;
      }
    },
    formPlaceholder() {
      if (this.formToDisplay || this.currentTab !== tabTypes.updateForm) return null;
      return formPlaceholderByStatus[this.client.formToDisplay] || this.client.needUpdateReason || 'No client update needed at this time';
    },
  },
  mounted() {
    this.demeanor = this.client.isPaymentDue || this.client.needUpdate ? 'warning' : 'success';
  },
  methods: {
    getTabClass(tabName) {
      return {
        'border-gray-500 text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm': this.currentTab === tabName,
        'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm': this.currentTab !== tabName,
      };
    },
    setCurrentTab(value) {
      this.currentTab = value;
    },
    confirmCFT(formName) {
      this.triggeredCFT = formName;
    },
    handleSubmit() {
      this.triggeredCFT = null;
      this.$emit('submit');
    },
  },
};
</script>
