<template>
  <div class="relative py-5">
    <apexchart
      width="300"
      height="300"
      type="pie"
      class="relative flex justify-center z-1"
      :series="series"
      :options="options"
    />
    <div
      v-if="settings.outerStrokeColor"
      class="chart-border absolute top-1/2 left-1/2 bg-blue-light rounded-full z-0"
    />
  </div>
</template>

<script>
import { getHexByColorName } from '@/utils';

export default {
  name: 'PieChart',
  props: {
    series: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      default: () => ([]),
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    shouldDisplayStroke() {
      const filledSections = this.series.filter(value => value > 0);
      return filledSections.length > 1;
    },
    strokeColor() {
      const strokeColor = this.settings?.innerStrokeColor;
      return strokeColor ? getHexByColorName(strokeColor) : 'white';
    },
    options() {
      return {
        labels: this.labels,
        colors: this.colors,
        legend: {
          show: false,
        },
        stroke: {
          show: this.shouldDisplayStroke,
          colors: [this.strokeColor],
        },
        plotOptions: {
          pie: {
            dataLabels: {
              minAngleToShowLabel: 20,
            },
          },
        },
        dataLabels: {
          style: {
            fontSize: '14px',
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.chart-border {
  /* Unfortunately Apexcharts doesn't allow us to set an outer border
  of the pie chart. That's why we have to manually create one. */
  width: 290px;
  height: 290px;
  /* Apexcharts positions pie charts with offset of 1px for some reason */
  transform: translate(calc(-50% + 1px), -50%);
}
</style>
