<template>
  <div>
    <c-checkbox
      v-bind="$attrs"
      @input="handleInput"
    />
    <t-modal
      v-model="modalShown"
      hide-close-button
      :click-to-close="false"
      class="flex items-center justify center"
    >
      <p>
        {{ modalText }}
      </p>
      <template v-slot:footer>
        <t-button
          type="button"
          @click="hideModal"
        >
          Ok
        </t-button>
      </template>
    </t-modal>
  </div>
</template>

<script>
import {
  CCheckbox,
} from '@/components/inputs';
import {
  modalText,
} from '@/components/constants';

export default {
  name: 'NoFurtherFollowup',
  components: {
    CCheckbox,
  },
  data() {
    return {
      modalShown: false,
      modalText,
    };
  },
  methods: {
    handleInput(value) {
      if (value) { // if checkobox was checked
        this.modalShown = true;
      }
      this.$emit('input', value);
    },
    hideModal() {
      this.modalShown = false;
    },
  },
};
</script>
