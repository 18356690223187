<template>
  <!-- eslint-disable max-len -->
  <span
    v-show="isVisible"
    class="p-3 rounded-md text-white bg-blue-300 absolute bottom-right cursor-pointer "
    @click="scrollToTop"
  >
    <scroll-top-icon class="text-blue-700 w-12 cursor-pointer opacity-70 stroke-current" />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import { ScrollTopIcon } from '../icons';

export default Vue.extend({
  name: 'ScrollToTop',
  components: {
    ScrollTopIcon,
  },
  props: {
    containerId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isVisible: false,
    view: null,
  }),
  mounted() {
    this.view = document.getElementById(this.containerId);
    this.view.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.view.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToTop() {
      this.view.scrollTop = 0;
    },
    handleScroll() {
      this.isVisible = this.view.scrollTop > 150;
    },
  },
});
</script>

<style scoped>
.bottom-right {
  bottom: 20px;
  right: 20px;
}
</style>
