import { getErrorMessage } from '@/utils';

export default {
  computed: {
    generatedPayload() {
      if (!this.fields) throw new Error('No fields were specified for the form');

      const conditions = this.fieldConditions || {};
      return this.fields.reduce((acc, field) => {
        const isConditional = field in conditions;
        if (!isConditional || conditions[field]) {
          acc[field] = this[field];
        }
        return acc;
      }, {});
    },
    errors() {
      const vuelidate = this.$v;
      const vuelidateParams = vuelidate.$params;
      const errors = {};
      Object.keys(vuelidateParams).forEach(fieldName => {
        const field = vuelidate[fieldName];
        const fieldParams = field.$params;

        Object.keys(fieldParams).forEach(fieldParam => {
          if (field[fieldParam]) return;
          errors[fieldName] = getErrorMessage(fieldParam, fieldParams[fieldParam]);
        });
      });
      return errors;
    },
  },
  methods: {
    getDirtyErrorMessage(fieldName) {
      return this.$v[fieldName].$dirty ? this.errors[fieldName] : null;
    },
  },
};
