import { render, staticRenderFns } from "./LafForm.vue?vue&type=template&id=5c570e04&"
import script from "./LafForm.vue?vue&type=script&lang=js&"
export * from "./LafForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports