export default [
  // {
  //   title: 'https://static.idealagent.com/web/v3/ideal-rate.png',
  //   contactTitle: 'Contact',
  //   phoneNumber: '(800) 520-2340',
  //   email: 'ideal@barrettfinancial.com',
  //   ordersTitle: 'Orders',
  //   orders:
  //     "Visit: <br/> <a href='https://platform.getbonzo.com/lp/idealagent ' target='_blank'class='text-blue-600 underline'>https://platform.getbonzo.com/lp/idealagent</a>",
  //   phoneImg: '/images/phone.png',
  //   emailImg: '/images/email.png',
  //   flyerLinks: [
  //     {
  //       linkUrl: 'https://static.idealagent.com/web/docs/ideal-rate.pdf',
  //       linkText: 'Download Flyer',
  //     },
  //   ],
  // },
  // {
  //   title: 'https://static.idealagent.com/web/v3/ideal-title.png',
  //   contactTitle: 'Contact',
  //   phoneNumber: '(888) 435-8485',
  //   email: 'customercare@idealtitle.com',
  //   ordersTitle: 'Orders',
  //   orders:
  //     "Email a copy of the contract and the client’s contact info to: <br/> orders@idealtitle.com <br/><br/> For an instant fee quote, visit: <br/> <a href='http://www.idealtitle.com' target='_blank'class='text-blue-600 underline'>www.idealtitle.com</a>",
  //   phoneImg: '/images/phone.png',
  //   emailImg: '/images/email.png',
  //   flyerLinks: [
  //     {
  //       linkUrl: 'https://static.idealagent.com/web/docs/ideal-title.pdf',
  //       linkText: 'Download Flyer',
  //     },
  //   ],
  // },
  {
    title: 'https://static.idealagent.com/web/v3/ideal-home-warranty.png',
    type: 'homeWarranty',
    // contactTitle: 'Contact',
    // phoneNumber: '(413) 841-6453',
    // email: 'tom.mcdonough@globalhomeusa.com',
    ordersTitle: 'Orders',
    orders:
      "Visit: <br/> <a href='https://idealagent.typeform.com/IdealWarranty' target='_blank'class='text-blue-600 underline'>https://idealagent.typeform.com/IdealWarranty</a>",
    phoneImg: '/images/phone.png',
    emailImg: '/images/email.png',
    flyerLinks: [
      // {
      //   linkUrl: 'https://static.idealagent.com/web/docs/ideal-home-warranty-agent.pdf',
      //   linkText: 'Download Agent Flyer',
      // },
      // {
      //   linkUrl: 'https://static.idealagent.com/web/docs/ideal-home-warranty-seller.pdf',
      //   linkText: 'Download Seller Flyer',
      // },
      // {
      //   linkUrl: 'https://static.idealagent.com/web/docs/ideal-home-warranty-buyer.pdf',
      //   linkText: 'Download Buyer Flyer',
      // },
    ],
  },
];
