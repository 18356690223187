<template>
  <div>
    <spinner v-if="isLoadingAttachment" />
    <div v-else>
      <div v-if="!attachments.length">
        No Attachments
      </div>
      <template v-else>
        <!--Desktop view -->
        <table class="w-full hidden md:table table-fixed bg-gray shadow-md rounded overflow-hidden">
          <tr class="bg-ia-400 text-white font-bold">
            <td class="w-1/4 text-left py-3 px-2">
              Attachment Name
            </td>
            <td class="w-1/4 text-left py-3 px-2">
              Attachment Type
            </td>
          </tr>
          <tr
            v-for="(update, x) in formattedTableData"
            :key="x"
            class="even:bg-gray-100 odd:bg-gray-200 text-sm"
          >
            <td class="px-2 py-10 cursor-pointer">
              <c-file-input
                :file="update"
                readonly
                downloadable
              />
            </td>
            <td class="px-2">
              {{ update.type }}
            </td>
          </tr>
        </table>

        <!--Mobile view -->
        <div class="md:hidden shadow-lg rounded overflow-hidden flex flex-col bg-gray-100">
          <div
            v-for="(update, x) in formattedTableData"
            :key="x"
            class="space-y-3 even:bg-gray-200 text-sm p-3"
          >
            <p>
              <b>Attachment Name: </b> {{ update.name }}
            </p>
            <p>
              <b>Attachment Type: </b> {{ update.type }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CFileInput } from '@/components/inputs';
import Spinner from '@/components/Spinner.vue';
import { attachmentTypeOptions } from '@/components/forms/offer-forms/constants';

export default {
  name: 'Attachments',
  components: {
    CFileInput,
    Spinner,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoadingAttachment: false,
    attachments: [],
  }),
  computed: {
    formattedTableData() {
      const formattedTableData = this.attachments.map(entry => {
        const formattedEntry = { ...entry };
        formattedEntry.type = attachmentTypeOptions.find(t => t.value === entry.type)?.label;
        return formattedEntry;
      });

      return formattedTableData;
    },
  },
  async created() {
    this.getAttachmentList();
  },
  methods: {
    ...mapActions('client', ['getAttachments']),
    async getAttachmentList() {
      const { id, clientType } = this.client;
      try {
        this.isLoadingAttachment = true;
        const payload = { profileId: id, clientType };
        this.attachments = await this.getAttachments(payload);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isLoadingAttachment = false;
      }
    },
  },
};
</script>
