export const sidebarItems = [
  {
    name: 'Dashboard',
    title: 'Dashboard',
    to: '/',
    icon: 'HomeIcon',
  },
  {
    name: 'My Referrals',
    title: 'My Referrals',
    to: '/my-referrals',
    icon: 'MyreferralsIcon',
  },
  {
    name: 'Marketing Materials',
    title: 'Marketing Materials',
    to: '/marketing-materials',
    icon: 'MarketingMaterialsIcon',
  },
  {
    name: 'Settings',
    title: 'Settings',
    to: '/settings',
    icon: 'SettingsIcon',
  },
  {
    name: 'FAQ',
    title: 'FAQ',
    to: '/faq',
    icon: 'FAQIcon',
  },
];
