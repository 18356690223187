export const downloadFileByBlob = (blob, name) => {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  document.body.appendChild(link);

  link.style = 'display: none';
  link.href = url;
  link.download = name;
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};
