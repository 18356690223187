<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-col text-center justify-center items-center h-screen">
    <h1 class="font-semibold text-3xl text-indigo-600 mb-3">
      We Are Currently Undergoing System Maintenance
    </h1>
    <p class="text-2xl text-gray-500">
      We apologize for the inconvenience!
    </p>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
};
</script>
