<template>
  <div class="relative h-full">
    <spinner
      v-if="isLoading"
      class="absolute"
      fullscreen
    />
    <t-alert
      v-if="error"
      show
      variant="danger"
      :dismissible="false"
      class="text-xs"
    >
      {{ errorMessage }}
    </t-alert>
    <div class="flex justify-center h-full max-w-2xl mx-auto p-8 sm:p-10">
      <div
        v-if="!formSubmitted"
        class="overflow-auto h-full"
      >
        <component
          :is="formToDisplay"
          :client="client"
          :client-name="fullName"
          :client-zoho-id="client.zohoId"
          @confirm-cft="confirmCFT"
          @submit="handleSubmit"
        />
        <t-alert
          v-if="allowPlaceholderDisplay && formPlaceholder"
          :dismissible="false"
          :variant="demeanor"
          show
        >
          {{ formPlaceholder }}
        </t-alert>
      </div>
      <div
        v-else
        class="flex flex-col gap-12 h-80 justify-center items-center"
      >
        <h2>Thank you for your update.</h2>
        <router-link
          to="/"
          class="py-4 underline text-blue-500"
        >
          Ideal Agent Dashboard
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'; // mapGetters
import {
  SpfForm,
  PcfForm,
  LafForm,
  AlcfForm,
  IcfForm,
  BufForm,
  IbcfForm,
  BsfForm,
  IocfForm,
  IovafForm,
  IoicfForm,
  IofFormGrid,
  AssistForm,
  IospfForm,
} from '@/components/forms';
import { Spinner } from '@/components';
import { errors } from '@/constants';
import { formPlaceholderByStatus, formTypes } from '@/components/lead-details/constants';

export default {
  name: 'Update',
  components: {
    Spinner,
    SpfForm,
    PcfForm,
    LafForm,
    AlcfForm,
    IcfForm,
    BufForm,
    IbcfForm,
    BsfForm,
    IocfForm,
    IovafForm,
    IoicfForm,
    IofFormGrid,
    AssistForm,
    IospfForm,
  },
  data() {
    return {
      isLoggingIn: false,
      isLoadingClient: false,
      tags: [],
      formSubmitted: false,
      errorMessage: '',
      error: false,
      triggeredCFT: null,
      allowPlaceholderDisplay: false,
    };
  },
  computed: {
    ...mapState('agent', ['agent']),
    ...mapState('agent', ['user', 'loading']),
    ...mapState('client', ['client']),
    ...mapGetters('agent', ['secondaryAgentOptions', 'showCollectionAlerts']),
    isLoading: vm => vm.isLoggingIn || vm.isLoadingClient,
    fullName() {
      return `${this.client.firstName} ${this.client.firstName}`;
    },
    formToDisplay() {
      switch (this.client.formToDisplay) {
        case formTypes.SPF: return SpfForm;
        case formTypes.PCF: return PcfForm;
        case formTypes.LAF: return LafForm;
        case formTypes.ICF: return IcfForm;
        case formTypes.BUF: return BufForm;
        case formTypes.IBCF: return IbcfForm;
        case formTypes.BSF: return BsfForm;
        case formTypes.IOCF: return IocfForm;
        case formTypes.IOVAF: return IovafForm;
        case formTypes.IOSPF: return IospfForm;
        case formTypes.IOICF: return IoicfForm;
        case formTypes.ALCF: return AlcfForm;
        case formTypes.IOF: return IofFormGrid;
        default: return null;
      }
    },
    formPlaceholder() {
      if (this.formToDisplay || this.isLoading) return null;
      return formPlaceholderByStatus[this.client.formToDisplay] || this.client.needUpdateReason || 'No client update needed at this time';
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$route.params.id'() {
      this.getClientDetails();
    },
  },
  async created() {
    await this.handleLogin();
    await this.fetchUserData();
    await this.getClientDetails();
    setTimeout(() => {
      this.allowPlaceholder();
    }, 3000);
  },
  mounted() {
    this.demeanor = this.client.isPaymentDue || this.client.needUpdate ? 'warning' : 'success';
  },
  methods: {
    ...mapActions('auth', ['loginOtp']),
    ...mapActions('client', ['getClient']),
    ...mapActions('agent', [
      'getUserDetails',
      'getAgentDetails',
      'getAgentAsa',
      'getAgentSummary',
      'getSecondaryAgents',
      'setLoading',
      'setOtpUser',
    ]),
    allowPlaceholder() {
      this.allowPlaceholderDisplay = true;
    },
    confirmCFT(formName) {
      this.triggeredCFT = formName;
    },
    handleSubmit() {
      this.triggeredCFT = null;
      this.formSubmitted = true;
    },
    async fetchUserData() {
      if (this.noActiveUser) return;
      try {
        this.setLoading({ type: 'global', status: true });
        await this.getUserDetails();
        await this.getAgentDetails();
        await Promise.all([
          this.getAgentAsa(),
          this.getAgentSummary(),
          this.getSecondaryAgents(),
        ]);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.setLoading({ type: 'global', status: false });
      }
    },
    async getClientDetails() {
      try {
        const { params } = this.$route;
        const profileType = params.name === 'b' ? 'Buyer' : 'Property';
        const options = { referralId: params.id, agentId: this.agent?.id, name: profileType };
        this.isLoadingClient = true;
        await this.getClient(options);
        if (this.client.noFurtherFollowUpRequired) {
          throw new Error(errors.notAvailableForUpdates);
        }
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isLoadingClient = false;
      }
    },
    async handleLogin() {
      try {
        this.error = false;
        this.isLoggingIn = true;
        const { user } = await this.loginOtp({
          code: this.$route.params.code,
        });
        this.setOtpUser(user);
      } catch ({ message }) {
        this.error = true;
        this.errorMessage = message;
      } finally {
        this.isLoggingIn = false;
      }
    },
  },
};
</script>
