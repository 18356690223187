<template>
  <div class="icf">
    <t-alert
      v-if="isSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <div class="text-base font-bold pb-4">
        Provide the information below after your initial phone call
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="pb-5">
          <p>Did you make contact via phone, text, or email with our referral?</p>
          <c-dropdown
            v-model="isContactMadeWithReferral"
            class="mt-3 border-gray-300"
            variant="demo"
            :options="booleanOptions"
            :error-message="getDirtyErrorMessage('isContactMadeWithReferral')"
            @input="clearFields"
          />
        </div>

        <template v-if="isContactMadePositive">
          <div class="pb-5">
            <p>Did you set listing appointment?</p>
            <c-dropdown
              v-model="$v.setListingAppointment.$model"
              class="mt-3 border-gray-300"
              variant="demo"
              :options="appointmentOptions"
              :error-message="getDirtyErrorMessage('setListingAppointment')"
              @input="clearAppointmentFields"
            />
          </div>

          <div
            v-show="listingApptDateShown"
            class="pb-5"
          >
            <p>{{ listingApptDateTitle }}</p>
            <c-date-picker
              v-model="$v.listingApptDateTime.$model"
              close-on-select
              mode="dateTime"
              class="mt-3"
              :error-message="getDirtyErrorMessage('listingApptDateTime')"
            />
          </div>

          <div
            v-show="icfLafNoReasonShown"
            class="pb-5"
          >
            <p>Reason for not setting:</p>
            <c-dropdown
              v-model="$v.icfLafNoReason.$model"
              class="mt-3 border-gray-300"
              variant="demo"
              :options="notSetReasonOptions"
              :error-message="getDirtyErrorMessage('icfLafNoReason')"
            />
          </div>

          <div
            v-show="nextFollowUpShown"
            class="mb-5"
          >
            <p class="mb-3">
              Next Follow Up Date:
            </p>
            <c-date-picker
              v-model="$v.nextFollowUp.$model"
              class="flex-auto"
              close-on-select
              date-format="Y-m-d"
              :min-date="new Date()"
              :error-message="getDirtyErrorMessage('nextFollowUp')"
            />
          </div>

          <div class="mb-5">
            <c-checkbox
              v-if="markAsDeadShown"
              v-model="agentMarkedAsDeadRequest"
              label="Mark as Dead"
              class="mb-5"
            />
          </div>
        </template>

        <template v-else-if="isContactMadeNegative">
          <div class="space-y-3 mb-5">
            <p>Next Follow Up Date:</p>
            <c-date-picker
              v-model="$v.nextFollowUp.$model"
              class="flex-auto"
              close-on-select
              date-format="Y-m-d"
              :min-date="new Date()"
              :error-message="getDirtyErrorMessage('nextFollowUp')"
            />
          </div>
        </template>

        <div
          v-if="commentsShown"
          class="mb-5"
        >
          <p>Comments:</p>
          <c-textarea-input
            v-model="$v.introCallComments.$model"
            :error-message="getDirtyErrorMessage('introCallComments')"
          />
        </div>

        <div class="mb-5">
          <c-button
            :is-loading="isSubmitting"
            type="submit"
          >
            Save
          </c-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import {
  CTextareaInput,
  CDatePicker,
  CDropdown,
  CCheckbox,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import { formMixin } from '@/mixins';
import { booleanOptions } from '@/constants/dropdown';
import {
  appointmentOptions,
  icfFields,
  listingAppointmentStates,
  notSetReasonOptions,
} from './constants';

export default {
  name: 'IcfForm',
  components: {
    CTextareaInput,
    CDatePicker,
    CDropdown,
    CCheckbox,
    CButton,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isContactMadeWithReferral: null,
    setListingAppointment: null,
    icfLafNoReason: null,
    listingApptDateTime: null,
    nextFollowUp: null,
    introCallComments: '',
    agentMarkedAsDeadRequest: false,
    isSubmitted: false,
    isSubmitting: false,
    booleanOptions,
    appointmentOptions,
    notSetReasonOptions,
    listingAppointmentStates,
    fields: icfFields,
  }),
  validations() {
    return {
      isContactMadeWithReferral: {
        required,
      },
      setListingAppointment: {
        required: requiredIf(() => this.isContactMadePositive),
      },
      listingApptDateTime: {
        required: requiredIf(() => this.isContactMadePositive && this.listingApptDateShown),
      },
      reasonForNotSetting: {
        required: requiredIf(() => this.isContactMadePositive && this.reasonForNotSettingShown),
      },
      nextFollowUp: {
        required: requiredIf(() => this.isNextFollowUpRequired),
      },
      icfLafNoReason: {
        required: requiredIf(() => this.isContactMadePositive && this.icfLafNoReasonShown),
      },
      introCallComments: {
        required,
        maxLength: maxLength(2000),
      },
    };
  },
  computed: {
    isContactMadePositive: vm => vm.isContactMadeWithReferral,
    isContactMadeNegative: vm => vm.isContactMadeWithReferral === false,
    listingApptDateShown: vm => vm.setListingAppointment === vm.listingAppointmentStates.set,
    icfLafNoReasonShown: vm => vm.setListingAppointment === vm.listingAppointmentStates.notSet,
    markAsDeadShown: vm => vm.setListingAppointment === vm.listingAppointmentStates.notSet,
    commentsShown() {
      const isListingAppointment = this.isContactMadePositive && this.setListingAppointment;
      return isListingAppointment || this.isContactMadeNegative;
    },
    nextFollowUpShown: vm => vm.setListingAppointment === vm.listingAppointmentStates.notSet,
    isNextFollowUpRequired() {
      if (this.isContactMadeNegative) return true;
      return this.isContactMadePositive && this.nextFollowUpShown && !this.agentMarkedAsDeadRequest;
    },
    listingApptDateTitle: vm => (
      vm.setListingAppointment === vm.listingAppointmentStates.set
        ? 'Listing Appointment Date/Time:'
        : 'Virtual Listing Appointment Date/Time:'
    ),
    fieldConditions: vm => ({
      setListingAppointment: vm.isContactMadePositive,
      nextFollowUp: vm.nextFollowUpShown || vm.isContactMadeNegative,
      listingApptDateTime: vm.isContactMadePositive && vm.listingApptDateShown,
      icfLafNoReason: vm.isContactMadePositive && vm.icfLafNoReasonShown,
      reasonForNotSetting: vm.isContactMadePositive && vm.reasonForNotSettingShown,
      agentMarkedAsDeadRequest: vm.markAsDeadShown,
    }),
  },
  methods: {
    ...mapActions('client', ['saveIcf']),
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        agentId: +this.client.assignedAgent,
        listingId: this.client.propertyReferralId,
        ...this.generatedPayload,
      };

      try {
        this.isSubmitting = true;
        await this.saveIcf(payload);
        this.$emit('submit');
        this.isSubmitted = true;

        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    clearFields() {
      this.setListingAppointment = null;
      this.clearAppointmentFields();
    },
    clearAppointmentFields() {
      this.icfLafNoReason = null;
      this.listingApptDateTime = null;
      this.nextFollowUp = null;
      this.introCallComments = '';
      this.$nextTick(() => this.$v.$reset());
    },
  },
};
</script>
