// BSF constants

export const propertyClosingOptions = [
  'Yes',
  'No/New Contract Terms',
];

// BUF constants

export const buyerProgressOptions = [
  'Showing Properties',
  'Offer Accepted',
  'Not Ready',
];

export const buyerProgressStates = {
  showingProperties: 'Showing Properties',
  notReady: 'Not Ready',
  offerAccepted: 'Offer Accepted',
  lost: 'Lost Client',
};

export const bufFields = [
  'nextFollowUp',
  'notes',
  'closingDate',
  'contractDate',
  'contractPrice',
  'buyerCommissionPercent',
  'listingCommissionPercent',
  'state',
  'city',
  'zip',
  'address',
  'operation',
];

export const operationByProgress = {
  'View Listings Online': 'VIEWING',
  'Showing Properties': 'SHOWING',
  'Not Ready': 'NOT_READY',
  'Offer Accepted': 'OFFER',
  'Lost Client': 'LOST',
};

export const addressFields = [
  'city',
  'state',
  'zip',
];

export const prefillFields = [
  ...addressFields,
  'contractDate',
  'closingDate',
  'contractPrice',
  'buyerCommissionPercent',
  'listingCommissionPercent',
];

export const conditionalTypeByField = {
  zip: 'postal_code',
  country: 'country',
  state: 'administrative_area_level_1',
  city: 'locality',
};

// IBCF constants

export const madeContactOptions = [
  'Yes',
  'Did Not Reach',
];

export const contactMapping = {
  // eslint-disable-next-line
  'Yes': 'YES',
  'Did Not Reach': 'DID_NOT_REACH',
};

export const ibcfFields = [
  'contacted',
  'noFurtherFollowUpRequired',
  'notes',
  'nextFollowUpDate',
];
