import Vue from 'vue';
import VueToast from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast, {
  pauseOnHover: true,
  dismissible: false,
  duration: 5000,
});
