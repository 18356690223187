<template>
  <!-- eslint-disable max-len -->
  <div>
    <t-alert
      v-if="previousIospfSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      {{ fileReceivedText }}
    </t-alert>

    <t-alert
      v-else-if="iospfSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <spinner v-if="isFetchingFields" />
      <div v-else>
        <div class="text-base font-bold pb-4">
          Provide the information below after the closing
        </div>
        <!-- Closing date dropdown -->
        <div class="pb-5">
          <p>Did the closing occur on the scheduled date?</p>
          <c-dropdown
            v-model="closingOccurrenceOnTheScheduledDate"
            class="mt-3 border-gray-300"
            variant="demo"
            :options="closingDateOptions"
            @input="clearFields"
          />
        </div>
      </div>

      <form
        v-if="showFileUpload || showForm"
        @submit.prevent="handleSubmitIospf"
      >
        <template v-if="showFileUpload">
          <!-- Attachment upload -->
          <attachment-upload
            :attachment-type="attachmentType"
            :error-messages="attachmentErrors"
            @changeFile="setFile($event, 'attachments')"
            @changeAttachmentType="attachmentType = $event"
          />
          <!-- Final Sold datepicker -->
          <div class="pb-5">
            <p>Final Sold Date:</p>
            <c-date-picker
              v-model="$v.soldDate.$model"
              close-on-select
              date-format="Y-m-d"
              placeholder="Final sold date:"
              class="mt-3"
              :max-date="new Date()"
              :error-message="getDirtyErrorMessage('soldDate')"
            />
          </div>
        </template>

        <template v-if="showForm">
          <div class="mt-5 space-y-3">
            <p>Scheduled Closing Date:</p>
            <c-date-picker
              v-model="$v.scheduledClosingDate.$model"
              :error-message="getDirtyErrorMessage('scheduledClosingDate')"
              date-format="Y-m-d"
              close-on-select
            />
          </div>
          <div class="mt-5 pb-5 space-y-3">
            <p>Comments:</p>
            <c-textarea-input
              v-model="comments"
              :error-message="getDirtyErrorMessage('comments')"
              placeholder="Enter comments"
            />
          </div>
        </template>
        <div class="flex mb-5">
          <c-button
            type="submit"
            class="mr-5"
            :is-loading="isSubmitting"
          >
            Save
          </c-button>
          <contract-fell-through
            v-if="shouldDisplayCFT"
            :address="client.address"
            profile-type="property"
            disabled
          />
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import {
  CDropdown,
  CDatePicker,
  CTextareaInput,
  AttachmentUpload,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import ContractFellThrough from '@/components/ContractFellThrough.vue';
import { submitIospf } from '@/api/actions';
import { getFileFromObject } from '@/utils';
import Spinner from '@/components/Spinner.vue';
import { formMixin } from '@/mixins';
import { closingDateOptions, iospfFields } from './constants';
import { fileReceivedMessage } from '../constants';

export default {
  name: 'IospfForm',
  components: {
    CDatePicker,
    CDropdown,
    ContractFellThrough,
    CButton,
    CTextareaInput,
    AttachmentUpload,
    Spinner,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    soldDate: null,
    comments: '',
    scheduledClosingDate: null,
    closingOccurrenceOnTheScheduledDate: null,
    isFetchingFields: false,
    iospfSubmitted: false,
    previousIospfSubmitted: false,
    isSubmitting: false,
    attachmentType: null,
    attachments: null,
    closingDateOptions,
    fields: iospfFields,
    fileReceivedText: fileReceivedMessage,
  }),
  validations() {
    return {
      soldDate: {
        required: requiredIf(() => this.showFileUpload),
      },
      attachmentType: {
        required: requiredIf(() => this.attachments),
      },
      scheduledClosingDate: {
        required: requiredIf(() => this.showForm),
      },
      comments: {
        required: requiredIf(() => this.showForm),
      },
    };
  },
  computed: {
    showFileUpload: (vm) => vm.closingOccurrenceOnTheScheduledDate === 'Yes',
    showForm: (vm) => vm.closingOccurrenceOnTheScheduledDate === 'No',
    shouldDisplayCFT: (vm) => vm.client.propertyStatus === 'PENDING',
    attachmentErrors: vm => ({ attachmentType: vm.getDirtyErrorMessage('attachmentType') }),
    fieldConditions() {
      return {
        comments: this.showForm,
        scheduledClosingDate: this.showForm,
        attachmentType: this.attachments,
        soldDate: this.showFileUpload,
      };
    },
  },
  mounted() {
    this.fetchFields();
  },
  methods: {
    ...mapActions('client', ['fetchIospf']),
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmitIospf() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;
      try {
        this.isSubmitting = true;

        const payload = {
          ...this.generatedPayload,
          agentId: +this.client.assignedAgent,
          listingId: this.client.propertyReferralId,
        };

        const formData = new FormData();
        const binaryPayload = getFileFromObject(payload);
        formData.append('salePurchaseRequest', binaryPayload);

        if (this.attachments) {
          [...this.attachments].forEach(file => formData.append('attachments', file, file.name));
        }

        await submitIospf(formData, this.attachmentType);
        this.$emit('submit');
        this.iospfSubmitted = true;
        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchFields() {
      try {
        this.isFetchingFields = true;
        const response = await this.fetchIospf(this.client.id);
        this.previousIospfSubmitted = response.iospfSubmitted;
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isFetchingFields = false;
      }
    },
    setFile(files, fieldName) {
      this[fieldName] = files;
    },
    clearFields() {
      this.comments = '';
      this.scheduledClosingDate = null;
      this.attachmentType = null;
      this.soldDate = null;
      this.attachments = null;
      this.iospfSubmitted = false;
      this.$v.$reset();
    },
  },
};
</script>
