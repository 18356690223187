/* eslint-disable import/prefer-default-export */
import { formatDate } from '@/utils/formatters';
import { colors, idealOfferStatusLabels } from '@/constants';
import {
  tagMapping, statusTypes, buyerStatusTypes, lostStatus,
} from '@/views/constants';

export function formatPhoneNumber(phoneRaw) {
  if (!phoneRaw) return '';

  const firstPart = phoneRaw.slice(0, 3);
  const secondPart = phoneRaw.slice(3, 6);
  const thirdPart = phoneRaw.slice(6, 10);
  return `(${firstPart}) ${secondPart}-${thirdPart}`;
}
export function formatPrice(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(amount);
}

export function formatInputPrice(price) {
  if (price === null) return null;
  const priceWithoutFormatting = price.toString().replace(/^$,/g, '');
  return formatPrice(priceWithoutFormatting).slice(1);
}

export function getHexByColorName(colorName) {
  const destructuredColor = colorName.split('-');
  if (destructuredColor.length === 1) {
    return colors[colorName]; // black, white, transparent
  }
  const colorFamily = colors[destructuredColor[0]]; // choosing a particular color
  return colorFamily[destructuredColor[1]]; // choosing a shade
}

export function getStatusTag(lead) {
  let status = '';
  if (lead.clientType === 'SELLER') {
    status = lead.propertyStatus?.toLowerCase() || '';
    return statusTypes.find(type => type.propertyStatuses.includes(status));
  }
  status = lead.buyerStatus?.toLowerCase() || '';
  // override status if lost flag is true
  if (status !== 'lost_no' && lead.lost) return lostStatus;

  return buyerStatusTypes.find(type => type.statuses.includes(status));
}

export function getTags(lead, formSubmitted) {
  const tags = [];
  const { needUpdate: needUpdateTag, offer: offerTag } = tagMapping;
  const statusTag = getStatusTag(lead);
  if (statusTag) tags.push(statusTag);
  if (lead.needUpdate && !formSubmitted) tags.push(needUpdateTag);
  if (lead.offer) tags.push(offerTag);
  return tags;
}

export const capitalize = (word) => word && (word.charAt(0).toUpperCase() + word.slice(1));

export const getBadges = ({ nextFollowUp, idealOffer, offerStatus }) => {
  const nextFollowUpBadge = nextFollowUp && {
    color: 'green',
    name: `Next follow up on ${formatDate(nextFollowUp, 'slashes')}`,
  };

  const idealOfferBadge = idealOffer && {
    color: 'violet',
    name: 'Offer',
  };

  const idealOfferLabel = idealOfferStatusLabels[offerStatus];
  const idealOfferStatusBadge = idealOfferLabel && {
    color: 'yellow',
    name: idealOfferLabel,
  };
  return [nextFollowUpBadge, idealOfferBadge, idealOfferStatusBadge].filter(Boolean);
};

export const getIPayText = profile => {
  return (profile.isPaymentDue && profile?.balanceDueAmount > 0
    ? `AMOUNT DUE: ${formatPrice(profile.balanceDueAmount)} - Pay Here` : '');
};
