<template>
  <div>
    <h2 class="pb-2 sm:text-lg text-blue-900">
      {{ title }}
    </h2>
    <div class="flex flex-col">
      <div class="flex items-center py-3 pr-3 2xl:text-sm">
        <img
          class="h-6 w-6 mr-2"
          :src="phoneImg"
          alt="Phone"
        >
        <a
          :href="'tel:' + phoneNumber"
          class="text-blue-600 underline"
        >{{ phoneNumber }}</a>
      </div>
      <div class="flex items-center py-3 pr-3 2xl:text-md">
        <img
          class="h-6 w-6 mr-2"
          :src="emailImg"
          alt="Email"
        >
        <a
          :href="'mailto:' + email"
          class="text-blue-600 underline"
        >{{ email }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['title', 'phoneNumber', 'email', 'phoneImg', 'emailImg'],
};
</script>
