<template>
  <!-- eslint-disable max-len -->
  <div class="w-full flex-grow flex flex-col bg-gray-250 overflow-y-auto p-4 sm:p-6">
    <h1 class="text-3xl font-extrabold text-blue-gray-900 mb-5">
      Help
    </h1>
    <div class="flex items-center mb-4">
      <lightbulb-icon class="w-4 h-4" />
      <p class="font-semibold text-gray-700 ml-2">
        List of available tours
      </p>
    </div>

    <h2
      v-if="!seenTours.length"
      class="flex flex-1 justify-center items-center font-semibold text-xl text-gray-700"
    >
      No tours to display
    </h2>
    <div
      v-for="tour in seenTours"
      :key="tour.id"
      class="relative flex flex-col sm:flex-row justify-between bg-blue-50 hover:bg-blue-100 rounded-md mb-4 p-4 pl-6 cursor-pointer"
      @click="$emit('show-tutorial', tour)"
    >
      <div class="absolute top-0 left-0 w-2 h-full rounded-l bg-blue-400" />
      <h2
        class="font-semibold"
        v-text="tour.name"
      />
      <p class="text-sm text-gray-700">
        Viewed on: {{ formatDate(tour.dtView, 'humanReadable') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { LightbulbIcon } from '@/components/icons';
import { formatDate } from '@/utils';

export default {
  name: 'Help',
  components: {
    LightbulbIcon,
  },
  computed: {
    ...mapGetters('agent', ['seenTours']),
    ...mapState('agent', ['loading']),
  },
  methods: {
    formatDate,
  },
};
</script>
