/* eslint-disable no-restricted-syntax */
<template>
  <div>
    <div v-if="!tableData.length">
      No previous updates
    </div>
    <template v-else>
      <!--Desktop view -->
      <table class="w-full hidden md:table table-fixed bg-gray shadow-md rounded overflow-hidden">
        <tr class="bg-ia-400 text-white font-bold">
          <td class="w-1/4 text-left py-3 px-2">
            Type
          </td>
          <td class="w-1/4 text-left py-3 px-2">
            Date Created
          </td>
          <td class="w-1/2 text-left py-3 px-2">
            Note Type
          </td>
          <td class="w-1/2 text-left py-3 px-2">
            Update Details
          </td>
        </tr>
        <tr
          v-for="(update, x) in formattedTableData"
          :key="x"
          class="even:bg-gray-100 odd:bg-gray-200 text-sm"
        >
          <td class="px-2 py-10">
            {{ update.updateType }}
          </td>
          <td class="px-2">
            {{ update.dateCreated }}
          </td>
          <td class="px-2">
            {{ update.noteType }}
          </td>
          <td class="space-y-2 px-2 py-3">
            <div
              v-for="(entry, y) in update.details"
              :key="`${update.timestamp}-${y}`"
            >
              <template v-if="entry.title && entry.value">
                <b>{{ entry.title }}</b>:
                <span>{{ entry.value }}</span>
              </template>
              <p
                v-else
                class="font-bold italic"
                v-text="entry.title"
              />
            </div>
          </td>
        </tr>
      </table>

      <!--Mobile view -->
      <div class="md:hidden shadow-lg rounded overflow-hidden flex flex-col bg-gray-100">
        <div
          v-for="(update, x) in formattedTableData"
          :key="x"
          class="space-y-3 even:bg-gray-200 text-sm p-3"
        >
          <p class="font-bold">
            {{ update.updateType }}
          </p>
          <p>
            <b>Date created: </b> {{ update.dateCreated }}
          </p>
          <p class="font-bold">
            Update details:
          </p>
          <div
            v-for="(entry, y) in update.details"
            :key="`${update.timestamp}-${y}`"
            class="border-l-2 border-blue-500 mb-2 pl-2"
          >
            <template v-if="entry.title && entry.value">
              <b>{{ entry.title }}</b>:
              <p>{{ entry.value }}</p>
            </template>
            <p
              v-else
              class="font-bold italic"
              v-text="entry.title"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { capitalize, formatPrice, formatDateTime } from '@/utils';
import {
  operationTypes,
  fieldsWithPrice,
  percentageFields,
  noteTypeOptions,
} from './constants';

export default {
  name: 'UpdateTable',
  filters: {
    capitalize,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedTableData() {
      const formattedTableData = this.tableData.map(entry => {
        const formattedEntry = { ...entry };
        formattedEntry.updateType = operationTypes[entry.operationType] || 'Not Specified';
        formattedEntry.dateCreated = formatDateTime(entry.updateTime, 'humanReadable');
        formattedEntry.details = this.formatDetails(entry.details);
        formattedEntry.noteType = noteTypeOptions[entry.noteType] || 'Not Specified';
        return formattedEntry;
      });

      return formattedTableData.sort((x, y) => new Date(x.updateTime) - new Date(y.updateTime));
    },
  },
  methods: {
    formatDetails(details) {
      const detailEntries = details.split('\n').filter(Boolean);
      const equalSeparator = ' = ';

      return detailEntries.map(detail => {
        if (!detail.includes(equalSeparator)) return { title: detail };

        const [title, value] = detail.split(equalSeparator);
        const formattedDetail = { title: title.replaceAll('_', ' '), value };

        if (fieldsWithPrice.includes(title)) {
          formattedDetail.value = formatPrice(value);
        }
        if (percentageFields.includes(title)) {
          formattedDetail.value = value ? `${detail.value}%` : 'Not Specified';
        }

        return formattedDetail;
      });
    },
  },
};
</script>
