<template>
  <!-- eslint-disable max-len -->
  <div class="w-full flex-grow flex flex-col bg-gray-250 overflow-y-auto p-4 sm:p-6">
    <h1 class="text-3xl font-extrabold text-blue-gray-900 mb-5">
      Frequently Asked Questions
    </h1>
    <vue-faq-accordion
      :items="faqAccordionItems"
      active-color="#000000"
      border-color="#9E9E9E"
      font-color="#5c5c5c"
    />
  </div>
</template>

<script>
import { faqAccordionItems } from '@/constants';

export default {
  name: 'FAQ',
  data() {
    return {
      faqAccordionItems,
    };
  },
};
</script>
