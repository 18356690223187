<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-col flex-1 overflow-hidden">
    <fieldset
      v-if="showFilterHeader"
      class="xl:hidden"
    >
      <legend class="sr-only">
        Return to My Referrals
      </legend>
      <div class="border-1 border-gray-200 text-center py-4">
        <button
          class="px-4 py-2 border-1 border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-ia-400 focus:outline-none"
          @click="$emit('switch-filters', false)"
        >
          Return to My Referrals
        </button>
      </div>
    </fieldset>
    <fieldset v-if="!hideSearchFilters">
      <legend class="sr-only">
        Search Filters
      </legend>
      <div class="relative h-1 bg-gray-300" />
      <div class="grid grid-flow-col grid-cols-2 bg-gray-50 grid-rows-1">
        <div
          v-for="control in typeControls"
          :key="control.property"
          class="flex items-center border-1 border-gray-300 p-4"
        >
          <input
            :id="control.property"
            type="checkbox"
            class="w-4 h-4 text-ia-600 border-gray-500 cursor-pointer"
            :checked="flags[control.property]"
            @change="setGroupFlag({ group: typeControls, flag: control.property, value: $event.target.checked })"
          >
          <label
            :for="control.property"
            class="flex flex-col text-sm font-medium ml-3 cursor-pointer"
            v-text="control.label"
          />
        </div>
      </div>
      <div class="relative h-1 bg-gray-300" />
      <div class="grid grid-flow-col grid-cols-2 bg-gray-50 grid-rows-2">
        <div
          v-for="control in propertyStatusControls"
          :key="control.property"
          class="flex items-center border-1 border-gray-300 p-4"
        >
          <input
            :id="control.property"
            type="checkbox"
            class="w-4 h-4 text-ia-600 border-gray-500 cursor-pointer"
            :checked="flags[control.property]"
            @change="setGroupFlag({ group: propertyStatusControls, flag: control.property, value: $event.target.checked })"
          >
          <label
            :for="control.property"
            class="flex flex-col text-sm font-medium ml-3 cursor-pointer"
            v-text="control.label"
          />
        </div>
        <div class="flex items-center border-1 border-gray-300 p-4" />
      </div>
      <div class="relative h-1 bg-gray-300" />
      <div class="grid grid-flow-col grid-cols-2 bg-gray-50 grid-rows-1">
        <div
          v-for="control in flagControls"
          :key="control.property"
          class="flex items-center border-1 border-gray-300 p-4"
        >
          <input
            :id="control.property"
            type="checkbox"
            class="w-4 h-4 text-ia-600 border-gray-500 cursor-pointer"
            :checked="flags[control.property]"
            @change="setFlag({ flag: control.property, value: $event.target.checked })"
          >
          <label
            :for="control.property"
            class="flex flex-col text-sm font-medium ml-3 cursor-pointer"
            v-text="control.label"
          />
          <div
            v-if="shouldDisplayNeedUpdateCounter(control.property)"
            class="w-5 h-5 flex justify-center items-center rounded-full text-white text-xs bg-red-500 ml-2"
            v-text="needUpdateCount"
          />
        </div>
      </div>
      <div class="relative h-1 bg-gray-300" />
      <div
        v-show="activeFlags.length"
        class="border-1 border-gray-200 bg-gray-50 text-center py-4"
      >
        <button
          type="button"
          class="px-4 py-2 border-1 text-gray-700 bg-white hover:bg-gray-200 border-gray-300 shadow-sm text-sm font-medium rounded-md"
          @click="clearFilters"
        >
          Reset Filters
        </button>
      </div>
    </fieldset>

    <directory-list
      :grouped-clients="directoryUsers"
      search-placeholder="Search for clients"
      preserve-search-query
      @select-user="goToDetailPage"
    />
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { getReferralPageUrl } from '@/utils';
import {
  flagControlsMapping, typeControlsMapping, propertyStatusControlsMapping, filterControlsMapping,
} from './constants';
import DirectoryList from './DirectoryList';

export default {
  components: {
    DirectoryList,
  },
  props: {
    showFilterHeader: {
      type: Boolean,
      default: false,
    },
    hideSearchFilters: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('filters', ['flags']),
    ...mapGetters('filters', ['directoryUsers', 'activeFlags', 'needUpdateCount']),
    filterControls: () => filterControlsMapping,
    flagControls: () => flagControlsMapping,
    typeControls: () => typeControlsMapping,
    propertyStatusControls: () => propertyStatusControlsMapping,
  },
  methods: {
    ...mapActions('filters', ['setFlag', 'setGroupFlag', 'clearFilters']),
    goToDetailPage(client) {
      const referralUrl = getReferralPageUrl(client);
      this.$router.push(referralUrl).catch(() => {}); // prevent redundant navigation error
    },
    shouldDisplayNeedUpdateCounter(property) {
      return property === 'needsUpdates' && this.needUpdateCount > 0;
    },
  },
};
</script>
