<template>
  <div
    v-if="show"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <div class="bg-white p-6 rounded-lg shadow-lg w-80">
      <vue-cropper
        ref="cropper"
        :src="imageSrc"
        :aspect-ratio="1 / 1"
        :drag-mode="'none'"
        :option="option"
        class="w-full h-full"
      />
      <div class="flex justify-between mt-3">
        <button
          class="text-gray-500 font-bold py-2 px-4 rounded"
          @click="close"
        >
          Cancel
        </button>
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          @click="crop"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  components: {
    VueCropper,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['imageSrc', 'show'],
  data() {
    return {
      option: {
        strict: true,
        aspectRatio: 1,
        dragMode: 'none',
        checkCrossOrigin: false,
        autoCropArea: 1,
      },
    };
  },
  methods: {
    crop() {
      this.$emit('crop-image', this.$refs.cropper.getCroppedCanvas().toDataURL());
    },
    close() {
      this.$emit('close-cropper');
    },
  },
};
</script>
