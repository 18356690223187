export const faqAccordionItems = [
  {
    title: '<b>1. How do I make a payment?</b>',
    value: `Simply log in to your Ideal Payment account to pay by credit card or ACH using this link
    <a
      class="text-ia-300 hover:underline"
      href="https://idealagent.ingageipay.com/Account/SignIn.aspx"
      target="_blank"
    >
      https://idealagent.ingageipay.com/Account/SignIn.aspx
    </a>.
    <br/>
    <br/><strong>Wire Transfers/ACH:</strong>
    <br/>Bank: JP Morgan Chase Bank Beverly Hills, CA
    <br/>Beneficiary: IDEAL AGENT
    <br/>Routing Number: 267084131
    <br/>Account Number: 570138076
    <br/>Memo: Invoice number & Property Address
    <br/>
    <br/><strong>Checks may be mailed to:</strong>
    <br/>IDEAL AGENT
    <br/>P.O. Box 10761
    <br/>Tampa, FL, 33679-0761
    <br/>
    <br/><strong>FedEx or UPS Only To:</strong>
    <br/>IDEAL AGENT
    <br/>3505 E. Frontage Rd, Suite 100
    <br/>Tampa, FL, 33607`,
  },
  {
    title: '<b>2. Did you receive my payment?</b>',
    value: 'If you submitted a payment through the Ideal Payment system, you can verify your payment by logging in. If not, please provide us with your payment method, date, and amount.',
  },
  {
    title: '<b>3. I should have received my invoice, but I can\'t locate it.</b>',
    value: 'Verify that your email address is up to date with us and check your spam folder.',
  },
  {
    title: '<b>4. How do I contact the Accounting Department?</b>',
    value: `Send an email to
      <a
        class="text-ia-300 hover:underline"
        href="mailto:accounting@idealagent.com"
      >
       accounting@idealagent.com
      </a> including your name and the client name or property you are contacting us about.
    `,
  },
];
