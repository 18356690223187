<template>
  <div class="py-6 border-b">
    <h2 class="text-xl font-medium text-blue-gray-900">
      {{ label }}
    </h2>
    <div class="flex items-center py-3">
      <div>
        <img
          v-if="imageUrl"
          :src="imageUrl"
          :alt="alt"
          class="h-24 w-24 rounded-full"
          @click="showModal = true"
        >
        <img
          v-else
          src="@/assets/agent-portal-nav-icon.png"
          :alt="'Default ' + alt"
          class="h-24 w-24 rounded-full"
        >
      </div>
      <div class="ml-4 space-x-2">
        <button
          :class="imageUrl ? 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' : 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'"
          @click="openFileInput(imageType)"
        >
          {{ imageUrl ? 'Update' : 'Add' }}
        </button>
        <button
          v-if="imageUrl"
          class="text-blue-500 font-bold py-2 px-4 rounded"
          @click="removeImage(imageType)"
        >
          Remove
        </button>
      </div>
    </div>

    <div
      v-if="showModal"
      class="fixed z-10 inset-0 overflow-y-auto"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <img
              :src="imageUrl"
              :alt="alt"
              class="w-full"
            >
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="showModal = false"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'imageUrl', 'alt', 'imageType'],
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openFileInput() {
      this.$emit('open-file-input', this.imageType);
    },
    removeImage() {
      this.$emit('remove-image', this.imageType);
    },
  },
};
</script>
