<template>
  <div>
    <t-alert
      v-if="isSubmitted && !hideAlert"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <form @submit.prevent="$emit('submit')">
        <slot>No Form Provided</slot>
      </form>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isSubmitted: Boolean,
    hideAlert: Boolean,
  },
};
</script>
