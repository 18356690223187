<template>
  <!-- eslint-disable max-len -->
  <form-input :error-message="errorMessage">
    <div class="flex items-center">
      <div class="w-10 bg-gray-500 border-2 border-gray-500 shadow-sm flex items-center justify-center self-stretch rounded-l">
        <calendar-icon
          class="w-5 h-5"
          color="gray-100"
        />
      </div>
      <v-date-picker
        :value="formattedDate"
        :min-date="minDate"
        :max-date="maxDate"
        :mode="mode"
        :timezone="timezone"
        class="flex-1"
        @input="handleInput"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <input
            v-bind="$attrs"
            :value="inputValue"
            placeholder="No date selected"
            :class="errorClass"
            readonly
            class="w-full px-3 py-2 border border-l-0 rounded-r input-focusable"
            @focus="togglePopover"
          >
        </template>
      </v-date-picker>
    </div>
  </form-input>
</template>

<script>
import { CalendarIcon } from '@/components/icons';
import { getIsoDate, getLocalDateTime, convertLocalDateToUTC } from '@/utils';
import FormInput from './FormInput';

export default {
  name: 'CDatePicker',
  components: {
    FormInput,
    CalendarIcon,
  },
  props: {
    mode: {
      type: String,
      default: 'date',
    },
    value: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
  },
  computed: {
    isInvalid: vm => Boolean(vm.errorMessage),
    errorClass: vm => ({ 'border-red-500': vm.isInvalid }),
    timezone: vm => (vm.mode === 'dateTime' ? undefined : 'UTC'),
    formattedDate: vm => {
      return vm.mode === 'dateTime' ? getLocalDateTime(vm.value) : vm.value;
    },
  },
  methods: {
    handleInput(date) {
      const isDateTime = this.mode === 'dateTime';
      const isoDate = getIsoDate(date);
      const formattedDate = convertLocalDateToUTC(isoDate, isDateTime);
      this.$emit('input', formattedDate);
    },
  },
};
</script>
