<template>
  <!-- eslint-disable max-len -->
  <div>
    <t-alert
      v-if="spfSubmitted && !needsUpdate"
      :dismissible="false"
      variant="success"
      show
    >
      {{ fileReceivedText }}
    </t-alert>

    <t-alert
      v-else-if="spfSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <spinner v-if="isFetchingFields" />
      <template v-else>
        <div v-if="!pcfSubmitted">
          <div class="text-base font-bold pb-4">
            Provide the information below after the closing
          </div>
          <!-- Closing date dropdown -->
          <div class="pb-5">
            <p>Did the closing occur on the scheduled date?</p>
            <c-dropdown
              v-model="closingOutcome"
              class="mt-3 border-gray-300"
              variant="demo"
              :options="closingDateOptions"
            />
          </div>
        </div>
        <form
          v-if="showFileUpload"
          @submit.prevent="handleSubmitSpf"
        >
          <!-- ALTA upload -->
          <div class="pb-5">
            <p>Upload ALTA:</p>
            <div class="mt-1 flex items-center">
              <input
                id="alta"
                type="file"
                name="alta"
                @change="setFile"
              >
            </div>
          </div>
          <!-- List appointment datepicker -->
          <div class="pb-5">
            <p>Final sold date:</p>
            <c-date-picker
              v-model="$v.finalSoldDate.$model"
              close-on-select
              date-format="Y-m-d"
              placeholder="Final sold date:"
              class="mt-3"
              :max-date="new Date()"
              :error-message="getDirtyErrorMessage('finalSoldDate')"
            />
          </div>
          <div class="mb-5 flex">
            <c-button
              :is-loading="isSubmitting"
              type="submit"
              class="mr-3"
            >
              Save
            </c-button>
            <contract-fell-through
              v-if="shouldDisplayCFT"
              :address="client.address"
              profile-type="property"
              disabled
              @confirm="handleCFT"
            />
          </div>
        </form>
        <pcf-form
          v-if="showPcfForm"
          :client="client"
          hide-title
          @submit="handleSubmitPcf"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { CDropdown, CDatePicker } from '@/components/inputs';
import { CButton } from '@/components/controls';
import Spinner from '@/components/Spinner.vue';
import ContractFellThrough from '@/components/ContractFellThrough.vue';
import { formMixin } from '@/mixins';
import PcfForm from './PcfForm.vue';
import { closingDateOptions } from './constants';
import { fileReceivedMessage } from '../constants';

export default {
  name: 'SpfForm',
  components: {
    CDatePicker,
    CDropdown,
    PcfForm,
    ContractFellThrough,
    CButton,
    Spinner,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    altaFile: null,
    finalSoldDate: '',
    closingOutcome: null,
    spfSubmitted: false,
    pcfSubmitted: false,
    showContractFellThrough: false,
    isSubmitting: false,
    isFetchingFields: false,
    fileReceivedText: fileReceivedMessage,
  }),
  validations() {
    return {
      finalSoldDate: {
        required,
      },
      altaFile: {
        required,
      },
    };
  },
  computed: {
    showFileUpload: vm => vm.closingOutcome === 'Yes',
    showPcfForm: vm => vm.closingOutcome === 'No',
    shouldDisplayCFT: vm => vm.client.propertyStatus === 'PENDING',
    closingDateOptions: () => closingDateOptions,
    needsUpdate: vm => vm.client.needsUpdate,
  },
  mounted() {
    this.fetchFields();
  },
  methods: {
    ...mapActions('client', ['saveSpf', 'fetchSpf', 'getClient']),
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmitSpf() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;
      try {
        this.isSubmitting = true;
        const formData = new FormData();
        formData.append('file', this.altaFile, this.altaFile.name);
        const payload = {
          agentId: +this.client.assignedAgent,
          clientId: this.client.propertyReferralId,
          file: formData,
          finalSoldDate: this.finalSoldDate,
        };
        await this.saveSpf(payload);
        this.$emit('submit');
        this.spfSubmitted = true;
        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchFields() {
      try {
        this.isFetchingFields = true;
        const response = await this.fetchSpf(this.client.id);
        this.finalSoldDate = response.finalSoldDate;
        this.spfSubmitted = response.spfSubmitted;
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isFetchingFields = false;
      }
    },
    handleSubmitPcf() {
      this.pcfSubmitted = true;
    },
    handleCFT() {
      this.$emit('confirm-cft', 'SPF');
    },
    setFile(event) {
      const file = event.target.files[0];
      this.altaFile = file;
    },
  },
};
</script>
