<template>
  <form class="space-y-8 divide-y divide-blue-gray-300">
    <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
      <div class="sm:col-span-6">
        <h2 class="text-xl font-medium text-blue-gray-900">
          Address Information
        </h2>
        <p class="mt-1 text-sm text-blue-gray-500">
          This information will be displayed publicly so be careful what you share.
        </p>
      </div>
      <div class="sm:col-span-4">
        <p>Address</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-if="!loading.details"
              v-model="$v.mailingStreet.$model"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Enter your address"
              :error-message="getDirtyErrorMessage('mailingStreet')"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
      <div class="sm:col-span-2">
        <p>Unit/Suite Number</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-if="!loading.details"
              v-model="$v.mailingStreet2.$model"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Enter Unit/Suite Number"
              :error-message="getDirtyErrorMessage('mailingStreet2')"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
      <div class="sm:col-span-2">
        <p>City</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-model="mailingCity"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Enter a city"
              :error-message="getDirtyErrorMessage('mailingCity')"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
      <div class="sm:col-span-2">
        <p>State</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-model="mailingState"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Enter a state"
              :error-message="getDirtyErrorMessage('mailingState')"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
      <div class="sm:col-span-2">
        <p>Zip</p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <c-text-input
              v-if="!loading.details"
              v-model="$v.mailingZip.$model"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Enter a zip number"
              :error-message="getDirtyErrorMessage('mailingZip')"
              disabled
            />
          </puskeleton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import csc from 'country-state-city';
import { required } from 'vuelidate/lib/validators';
import {
  CTextInput,
} from '@/components/inputs';
import { formMixin } from '@/mixins';
import { addressInfo } from './constants';

const countries = [
  {
    id: 231,
    text: 'United States',
  },
  {
    id: 177,
    text: 'Puerto Rico',
  },
  {
    id: 240,
    text: 'Virgin Islands (US)',
  },
  {
    id: 40,
    text: 'Cayman Islands',
  },
];

export default {
  name: 'AddressInformation',
  components: {
    CTextInput,
  },
  mixins: [formMixin],
  data: () => ({
    allCountries: [],
    allStates: [],
    allCities: [],
    mailingCountry: null,
    mailingState: null,
    mailingCity: null,
    mailingStreet: '',
    mailingZip: '',
    mailingStreet2: '',
  }),
  computed: {
    ...mapState('agent', ['agent', 'loading']),
    countries: () => countries,
    fields: () => addressInfo,
    matchedMailingCountry() {
      if (!this.mailingCountry) {
        return null;
      }
      return this.allCountries.find((country) => country.id === this.mailingCountry);
    },
    matchedMailingState() {
      if (!this.mailingState) {
        return null;
      }
      return this.allStates.find((state) => state.id === this.mailingState);
    },
    matchedMailingCity() {
      if (!this.mailingCity) {
        return null;
      }
      return this.allCities.find((city) => city.id === this.mailingCity);
    },
    isCountrySelected: vm => !!vm.mailingCountry,
    isStateSelected: vm => !!vm.mailingState,
  },
  watch: {
    agent() {
      this.prepareData();
    },
  },
  created() {
    this.prepareData();
  },
  validations() {
    return {
      mailingStreet: {
        required,
      },
      mailingStreet2: {},
      mailingZip: {
        required,
      },
      mailingState: {},
      mailingCity: {},
    };
  },
  methods: {
    async prepareData() {
      try {
        await this.prefillForm();
        this.allCountries = csc.getAllCountries();
      } catch ({ message }) {
        this.$toast.error(message);
      }
    },
    setStatesForCountry() {
      if (this.mailingCountry !== null) {
        this.allStates = csc.getStatesOfCountry(this.mailingCountry?.toString());
        this.allCities = [];
      }
    },
    setCitiesForState() {
      if (this.mailingState !== null) {
        this.allCities = csc.getCitiesOfState(this.mailingState?.toString());
      }
    },
    prefillForm() {
      this.fields.forEach(field => {
        const value = this.agent[field];
        if (!value) return;
        this[field] = value;
      });
    },
  },
};
</script>
