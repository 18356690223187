<template>
  <div>
    <modal>
      <h1 class="pb-4 text-gray-800 text-xl">
        {{ tour.name }}
      </h1>
      <hr
        class="border-t border-gray-300 mb-5"
        aria-hidden="true"
      >
      <swiper
        class="swiper"
        :options="swiperOption"
      >
        <div
          v-for="(screenshot, i) in screenshots"
          :key="i"
          class="swiper-slide"
        >
          <img
            class="hidden md:block photo mx-auto"
            :src="screenshot.desktopUrl"
          >
          <img
            class="md:hidden photo mx-auto"
            :src="screenshot.mobileUrl"
          >
        </div>

        <div
          slot="button-prev"
          class="swiper-button-prev -ml-3"
        />
        <div
          slot="button-next"
          class="swiper-button-next -mr-3"
        />
      </swiper>
      <template #footer>
        <c-button
          :is-loading="isDeactivatingTutorial"
          @click="handleClose"
        >
          Done
        </c-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CButton } from '@/components/controls';
import Modal from '@/components/Modal.vue';

export default {
  name: 'Tutorial',

  components: {
    Modal,
    CButton,
  },
  props: {
    tour: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      isDeactivatingTutorial: false,
    };
  },
  computed: {
    ...mapState('agent', ['user']),

    screenshots: vm => vm.tour.siteTourScreenshots,
    isSeen: vm => Boolean(vm.tour.dtView),
  },
  methods: {
    ...mapActions('agent', ['deactivateTour']),
    async handleClose() {
      if (!this.isSeen) await this.deactivateTutorial();
      this.$emit('done');
    },
    async deactivateTutorial() {
      try {
        this.isDeactivatingTutorial = true;
        const params = {
          userId: this.user.id,
          tourId: this.tour.id,
        };
        await this.deactivateTour(params);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isDeactivatingTutorial = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
    .photo {
      max-height: calc(100vh - 280px);
    }
</style>
