export const modalText = 'Selecting “No Further Follow Up Required” removes this referral from your pipeline entirely. If you intend to follow up at a later date, uncheck the “No Further Follow Up Required” box and set a date in the future to follow up. You can also use the Assist Button if you need our assistance with this referral.';

export const CFTOptionsByClientType = {
  SELLER: {
    pathName: 'LISTING_FELL_THROUGH',
    idName: 'listingId',
    clientIdName: 'propertyReferralId',
  },
  BUYER: {
    pathName: 'SEARCH_FELL_THROUGH',
    idName: 'searchId',
    clientIdName: 'buyerProfileReferralId',
  },
};
