<template>
  <form-input :error-message="errorMessage">
    <t-select
      v-bind="$attrs"
      :value="value"
      :placeholder="placeholder"
      :class="errorClass"
      :options="options"
      class="w-full border-gray-500 rounded"
      @input="handleInput"
    />
  </form-input>
</template>

<script>
import { getBooleanFromString } from '@/utils';
import FormInput from './FormInput.vue';

export default {
  name: 'CDropdown',
  components: {
    FormInput,
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isInvalid: vm => Boolean(vm.errorMessage),
    errorClass: vm => ({ 'border-red-500': vm.isInvalid }),
    isBoolean: vm => typeof vm.options[0]?.value === 'boolean',
  },
  methods: {
    handleInput(value) {
      const selection = this.isBoolean ? getBooleanFromString(value) : value;
      this.$emit('input', selection);
    },
  },
};
</script>
