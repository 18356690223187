/* eslint-disable camelcase */
import { getGeocoderAPI, api } from '@/api';

export async function getLocationResults(searchInputText, options) {
  const url = getGeocoderAPI(searchInputText, options);
  const { data } = await api.get(url);
  return data.features;
}

export const getAddressFields = address => {
  const streetNumber = address.address;
  const street = streetNumber ? `${streetNumber} ${address.text}` : address.text;

  const addressParts = address.context.reduce((acc, addressProp) => {
    const { id, short_code, text } = addressProp;
    const key = id.split('.')[0];
    const shouldExtractRegion = Boolean(key === 'region' && short_code);
    const value = shouldExtractRegion ? short_code.split('-')[1] : text;

    acc[key] = value;
    return acc;
  }, {});

  const [longitude, latitude] = address.center;

  return {
    placeName: address.place_name,
    state: addressParts.region,
    city: addressParts.place,
    zip: addressParts.postcode,
    address: street,
    longitude,
    latitude,
  };
};
