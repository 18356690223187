<template>
  <div>
    <t-alert
      v-if="isSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>
    <template v-else>
      <form @submit.prevent="handleSubmit">
        <div class="pb-5">
          <p>Did you make contact with {{ fullName }} ?</p>
          <c-dropdown
            v-model="$v.contactMade.$model"
            class="mt-3 border-gray-300"
            variant="demo"
            :options="madeContactOptions"
            @input="clearFields"
          />
        </div>
        <div v-show="contactMade">
          <div class="pb-5">
            <p>Next Follow Up Date:</p>
            <div class="flex items-center mt-3">
              <c-date-picker
                v-model="$v.nextFollowUpDate.$model"
                class="flex-auto"
                close-on-select
                :disabled="noFurtherFollowUpRequired"
                date-format="Y-m-d"
                :min-date="new Date()"
                :error-message="getDirtyErrorMessage('nextFollowUpDate')"
              />
            </div>
          </div>
          <no-further-followup
            v-model="noFurtherFollowUpRequired"
            label="Mark as Dead"
          />
          <div class="pb-5 mt-5">
            <p>Notes:</p>
            <c-textarea-input
              v-model="$v.notes.$model"
              :error-message="getDirtyErrorMessage('notes')"
            />
          </div>
          <div class="mb-5">
            <c-button
              :is-loading="isSubmitting"
              type="submit"
            >
              Save
            </c-button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  CDatePicker,
  CDropdown,
  CTextareaInput,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import NoFurtherFollowup from '@/components/NoFurtherFollowup.vue';
import { formMixin } from '@/mixins';
import {
  madeContactOptions,
  ibcfFields,
  contactMapping,
} from './constants';

export default {
  name: 'IBCFForm',
  components: {
    CDatePicker,
    CDropdown,
    CTextareaInput,
    CButton,
    NoFurtherFollowup,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    contactMade: null,
    nextFollowUpDate: null,
    noFurtherFollowUpRequired: false,
    notes: '',
    isSubmitted: false,
    isSubmitting: false,
    fields: ibcfFields,
  }),
  validations() {
    return {
      contactMade: {
        required,
      },
      notes: {
        required,
      },
      nextFollowUpDate: {
        required: requiredIf(() => !this.noFurtherFollowUpRequired),
      },
    };
  },
  computed: {
    madeContactOptions: () => madeContactOptions,
    fullName: vm => `${vm.client.firstName} ${vm.client.lastName}`,
    contacted: vm => contactMapping[vm.contactMade],
  },
  methods: {
    ...mapActions('client', ['saveIbcf']),
    ...mapActions('agent', ['getAgentDetails']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;
      const payload = {
        agentId: +this.client.assignedAgent,
        searchId: this.client.buyerProfileReferralId,
        ...this.generatedPayload,
      };
      try {
        this.isSubmitting = true;
        await this.saveIbcf(payload);
        this.$emit('submit');
        this.isSubmitted = true;
        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    clearFields() {
      this.nextFollowUpDate = null;
      this.noFurtherFollowUpRequired = false;
      this.notes = '';
      this.$nextTick(() => this.$v.$reset());
    },
  },
};
</script>
