<template>
  <submittable-form
    hide-alert
    class="pb-20"
    @submit="handleSubmit"
  >
    <div class="mt-5 space-y-3">
      <p>Buyer Vendor:</p>
      <c-text-input
        v-model="vendorName"
        readonly
      />
    </div>

    <div class="mt-5 space-y-3">
      <p>Vendor Offering:</p>
      <c-dropdown
        v-model="vendorOffering"
        :options="booleanOptions"
        placeholder="No Option Selected"
        class="pointer-events-none"
      />
    </div>

    <div class="mt-5 space-y-3">
      <p>Vendor Not Offered Reason:</p>
      <c-dropdown
        v-model="vendorNotOfferedReason"
        :options="vendorNotOfferedReasonOptions"
        placeholder="No Option Selected"
        class="pointer-events-none"
      />
    </div>

    <div class="mt-5 space-y-3">
      <p>Offer Amount:</p>
      <c-text-input
        v-model="offerAmount"
        type="currency"
        class="pointer-events-none"
      />
    </div>

    <div class="mt-5 space-y-3">
      <p>Offer Presented to the Client:</p>
      <c-dropdown
        v-model="$v.offerPresentedToClient.$model"
        :options="booleanOptions"
        :error-message="getDirtyErrorMessage('offerPresentedToClient')"
        placeholder="No Option Selected"
      />
    </div>

    <div class="mt-5 space-y-3">
      <p>Contract/Letter of Intent (attachments):</p>
      <c-file-input
        :file="offer.attachments"
        multiple
        readonly
        downloadable
      />
    </div>

    <div class="mt-5 space-y-3">
      <p>Accept/Reject?</p>
      <c-dropdown
        v-model="$v.acceptedRejected.$model"
        :options="acceptedRejectedOptions"
        :error-message="getDirtyErrorMessage('acceptedRejected')"
        placeholder="No Option Selected"
      />
    </div>

    <div
      v-if="isOfferRejected"
      class="mt-5 space-y-3"
    >
      <p>Offer Rejection Reason</p>
      <c-dropdown
        v-model="$v.clientRejectedOfferReason.$model"
        :options="clientRejectedOfferReasonOptions"
        :error-message="getDirtyErrorMessage('clientRejectedOfferReason')"
        placeholder="Enter Offer Rejection Reason"
      />
    </div>

    <div class="mt-5">
      <c-button
        :is-loading="isSubmitting"
        type="submit"
      >
        Save
      </c-button>
    </div>
  </submittable-form>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  CTextInput,
  CDropdown,
  CFileInput,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import SubmittableForm from '@/components/forms/SubmittableForm.vue';
import { submitIof } from '@/api/actions';
import { formMixin } from '@/mixins';
import { booleanOptions } from '@/constants/dropdown';
import {
  iofFields,
  vendorNotOfferedReasonOptions,
  acceptedRejectedOptions,
  clientRejectedOfferReasonOptions,
} from './constants';

export default {
  name: 'IofFormCard',
  components: {
    SubmittableForm,
    CTextInput,
    CDropdown,
    CFileInput,
    CButton,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    isAccepted: Boolean,
  },
  data() {
    return {
      vendorName: this.offer.vendorName,
      vendorOffering: this.offer.vendorOffering,
      vendorNotOfferedReason: this.offer.vendorNotOfferedReason,
      offerAmount: this.offer.offerAmount,
      offerPresentedToClient: this.offer.offerPresentedToClient,
      acceptedRejected: this.offer.acceptedRejected,
      attachments: this.offer.attachments,
      clientRejectedOfferReason: '',
      isSubmitting: false,
      booleanOptions,
      vendorNotOfferedReasonOptions,
      acceptedRejectedOptions,
      clientRejectedOfferReasonOptions,
      fields: iofFields,
    };
  },
  validations() {
    return {
      offerPresentedToClient: { required },
      acceptedRejected: {
        required,
        canBeAccepted: value => (this.isAccepted ? !value : true),
      },
      clientRejectedOfferReason: {
        required: requiredIf(() => this.isOfferRejected),
      },
    };
  },
  computed: {
    isOfferRejected: vm => vm.acceptedRejected === false,
    fieldConditions() {
      return {
        clientRejectedOfferReason: this.isOfferRejected,
      };
    },
  },
  methods: {
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        ...this.generatedPayload,
        agentId: +this.client.assignedAgent,
        listingId: this.client.propertyReferralId,
        offerId: this.offer.id,
      };

      try {
        this.isSubmitting = true;
        await submitIof(payload);
        this.$emit('submit');
        this.$toast.success('Form successfully updated!');

        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
