<template>
  <div>
    <t-alert
      v-if="error"
      show
      variant="danger"
      :dismissible="false"
      class="text-xs"
    >
      {{ errorMessage }}
    </t-alert>
    <div class="text-gray-600 text-xs">
      Click the “Request a Reset Link” to be sent a link to reset your password to your email.
    </div>
    <c-button
      class="w-full mt-5"
      :is-loading="isRequestingResetLink"
      @click="handleResetPassword"
    >
      Request a Reset Link
    </c-button>
    <a
      href="#"
      class="text-ia-300 mt-5 text-xs font-bold hover:underline text-center block"
      @click="$emit('back-to-login')"
    >Back to login</a>
  </div>
</template>

<script>
import { CButton } from '@/components/controls';
import { mapActions } from 'vuex';

export default {
  name: 'PasswordReset',
  components: {
    CButton,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isRequestingResetLink: false,
      error: false,
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions('auth', ['requestResetPassword']),

    async handleResetPassword() {
      this.error = false;
      try {
        this.isRequestingResetLink = true;
        const params = {
          email: this.email,
        };
        await this.requestResetPassword(params);
        this.$toast.success('The link has been sent! Check your e-mail.');
      } catch ({ message }) {
        this.error = true;
        this.errorMessage = message;
      } finally {
        this.isRequestingResetLink = false;
      }
    },
  },
};
</script>
