<template>
  <ul class="flex items-center flex-horizontal gap-4 mr-4">
    <li
      v-for="control in hideControlsMapping"
      :key="control.property"
      class="flex items-center border-1 border-gray-300 p-4"
    >
      <input
        :id="control.property"
        type="checkbox"
        class="w-4 h-4 text-ia-600 border-gray-500 cursor-pointer"
        :checked="hideFlags[control.property]"
        @change="setHideFlag({ flag: control.property, value: $event.target.checked })"
      >
      <label
        :for="control.property"
        class="flex flex-col text-sm font-medium ml-3 cursor-pointer"
        v-text="control.label"
      />
    </li>
  </ul>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { hideControlsMapping } from './constants';

export default {
  name: 'SearchHideControls',
  components: {},
  data: () => ({
    hideControlsMapping,
  }),
  computed: {
    ...mapState('filters', ['hideFlags']),
  },
  methods: {
    ...mapActions('filters', ['setHideFlag']),
  },
};
</script>
