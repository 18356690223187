<template>
  <div class="flex justify-center items-center h-screen">
    <spinner
      v-if="isCheckingResetToken"
      fullscreen
    />
    <div
      v-else
      class="max-w-md sm:max-w-xs px-5 -mt-20"
    >
      <img
        src="@/assets/ia-logo.png"
      >

      <form
        class="mt-5"
        @submit.prevent="handleResetPassword"
      >
        <t-alert
          v-if="error"
          show
          variant="danger"
          :dismissible="false"
          class="text-xs"
        >
          {{ errorMessage }}
        </t-alert>
        <t-alert
          show
          :dismissible="false"
          class="text-xs mt-2"
        >
          Please enter a new password
        </t-alert>
        <c-text-input
          v-model="$v.password.$model"
          placeholder="Password"
          type="password"
          :error-message="getDirtyErrorMessage('password')"
        />
        <c-text-input
          v-model="$v.confirmedPassword.$model"
          placeholder="Confirm new password"
          type="password"
          :error-message="getDirtyErrorMessage('confirmedPassword')"
        />
        <c-button
          class="w-full mt-5"
          type="submit"
          :is-loading="isResettingPassword"
        >
          Save Password
        </c-button>
      </form>
    </div>
  </div>
</template>

<script>
import { getUserToken, removeUserToken } from '@/utils';
import { CButton } from '@/components/controls';
import { CTextInput } from '@/components/inputs';
import { Spinner } from '@/components';

import { mapActions } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';
import { formMixin } from '@/mixins';

export default {
  name: 'ResetPassword',
  components: {
    CButton,
    CTextInput,
    Spinner,
  },
  mixins: [formMixin],
  data() {
    return {
      isCheckingResetToken: false,
      isResettingPassword: false,
      error: false,
      errorMessage: '',
      password: '',
      confirmedPassword: '',
    };
  },
  validations() {
    return {
      password: {
        required,
      },
      confirmedPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    };
  },
  async created() {
    const { code } = this.$route.query || {};
    if (!code) {
      this.$toast.error('No token found');
      this.navigateAway();
      return;
    }
    try {
      this.isCheckingResetToken = true;
      await this.checkResetToken(code);
    } catch ({ message }) {
      this.$toast.error(message);
      this.navigateAway();
    } finally {
      this.isCheckingResetToken = false;
    }
  },
  methods: {
    ...mapActions('auth', ['checkResetToken', 'resetPassword']),
    async handleResetPassword() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;
      this.error = false;
      try {
        this.isResettingPassword = true;
        const token = this.$route.query.code;
        const payload = {
          password: this.password,
          token,
        };
        await this.resetPassword(payload);
        this.$toast.success('Password succesfully changed!');
        if (getUserToken()) {
          removeUserToken();
        }
        this.navigateAway();
      } catch ({ message }) {
        this.error = true;
        this.errorMessage = message;
      } finally {
        this.isResettingPassword = false;
      }
    },
    navigateAway() {
      const routeToGo = getUserToken() ? '/' : '/login';
      this.$router.push(routeToGo);
    },
  },
};
</script>
