<template>
  <div class="flex flex-col h-screen">
    <SiteAlert
      v-if="isPausedBanner"
      :alert="pausedAlert"
      is-bold
      show-icon
      is-centered
    />
    <div class="flex-1 flex flex-col lg:flex-row overflow-hidden bg-white">
      <tutorial
        v-if="tutorialShown"
        :tour="currentTour"
        @done="hideTutorial"
      />
      <side-navigation
        :sidebar-shown="sidebarShown"
        @hide-sidebar="hideSidebar"
      />
      <mobile-header
        @show-sidebar="showSidebar"
      />
      <spinner
        v-if="loading.global"
        fullscreen
      />
      <router-view
        v-else
        name="default"
        @show-tutorial="showTutorial"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getUserToken, getAdminToken } from '@/utils';
import Tutorial from '@/components/Tutorial.vue';
import Spinner from '@/components/Spinner.vue';
import SiteAlert from '@/components/notifications/SiteAlert.vue';
import { alerts } from '@/constants';

import {
  MobileHeader,
  SideNavigation,
} from '@/components';

export default {
  name: 'PrimaryLayout',
  components: {
    SideNavigation,
    MobileHeader,
    Tutorial,
    Spinner,
    SiteAlert,
  },
  data: () => ({
    sidebarShown: false,
    tutorialShown: false,
    currentTour: {},
    pausedAlert: alerts.find(x => x.name === 'AGENT_PAUSED'),
  }),
  computed: {
    ...mapGetters('agent', ['unseenTours', 'isPausedBanner']),
    ...mapGetters('auth', ['noActiveUser']),
    ...mapState('agent', ['user', 'loading']),
    ...mapState('auth', ['isAdmin']),
  },
  created() {
    this.handleAdminFlags();
    this.fetchUserData();
  },
  methods: {
    ...mapActions('agent', [
      'getUserDetails',
      'getAgentDetails',
      'getAgentAsa',
      'getAgentSummary',
      'getSecondaryAgents',
      'getUserTours',
      'setLoading',
    ]),
    ...mapActions('auth', ['setAdminFlags']),
    showSidebar() {
      this.sidebarShown = true;
    },
    hideSidebar() {
      this.sidebarShown = false;
    },
    async fetchUserData() {
      if (this.noActiveUser) return;
      try {
        this.setLoading({ type: 'global', status: true });
        await this.getUserDetails();
        await this.getAgentDetails();
        await Promise.all([
          this.getAgentAsa(),
          this.getAgentSummary(),
          this.getSecondaryAgents(),
          this.getUserTours(this.user.id),
        ]);
        if (!this.isAdmin && this.unseenTours.length) {
          this.currentTour = this.unseenTours[0];
          this.tutorialShown = true;
        }
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.setLoading({ type: 'global', status: false });
      }
    },
    handleAdminFlags() {
      const adminData = {
        setAdmin: false,
        setIsUserSelected: false,
      };
      if (getAdminToken()) {
        adminData.setAdmin = true;
      }
      if (getUserToken()) {
        adminData.setIsUserSelected = true;
      }
      this.setAdminFlags(adminData);
    },
    showTutorial(tour) {
      this.currentTour = tour;
      this.tutorialShown = true;
    },
    hideTutorial() {
      this.tutorialShown = false;
    },
  },
};
</script>
