<template>
  <submittable-form
    ref="videoAppointmentCompleted"
    :is-submitted="isSubmitted"
    class="pb-20"
    @submit="handleSubmit"
  >
    <div class="font-bold pb-4">
      Is the Video Appointment completed?
    </div>

    <c-dropdown
      v-model="videoAppointmentCompleted"
      :options="videoAppointmentCompletedOptions"
      class="border-gray-300 mb-5"
      variant="demo"
      @input="clearFields"
    />

    <template v-if="isVideoAppointmentCompleted">
      <div class="mt-8 space-y-3">
        <p>Did you leave the marketing piece with the client?</p>
        <c-dropdown
          v-model="$v.leftMarketingPiece.$model"
          :options="booleanOptions"
          :error-message="getDirtyErrorMessage('leftMarketingPiece')"
          class="border-gray-300 mb-5"
          variant="demo"
          @input="clearMarketingPieceComments"
        />
      </div>
      <div
        v-if="leftMarketingPiece === false"
        class="mt-5 space-y-3"
      >
        <p>Comments:</p>
        <c-textarea-input
          v-model="$v.leftMarketingPieceComments.$model"
          :error-message="getDirtyErrorMessage('leftMarketingPieceComments')"
          placeholder="Enter comments"
        />
      </div>

      <div class="mt-5 space-y-3">
        <p>Did you also set up a listing appointment during this time?</p>
        <c-dropdown
          v-model="$v.setListingAppointment.$model"
          :options="booleanOptions"
          :error-message="getDirtyErrorMessage('setListingAppointment')"
          class="border-gray-300 mb-5"
          variant="demo"
          @input="clearListingAppointmentComments"
        />
      </div>
      <div
        v-if="setListingAppointment === false"
        class="mt-5 space-y-3"
      >
        <p>Comments:</p>
        <c-textarea-input
          v-model="setListingAppointmentComments"
          placeholder="Enter comments"
        />
      </div>

      <div class="mt-5 space-y-3">
        <p>Was a listing agreement signed during this appointment?</p>
        <c-dropdown
          v-model="$v.listingAgreementSigned.$model"
          :options="booleanOptions"
          :error-message="getDirtyErrorMessage('listingAgreementSigned')"
          class="border-gray-300 mb-5"
          variant="demo"
          @input="clearListingAgreementSignedFields"
        />
      </div>
      <template v-if="listingAgreementSigned">
        <div class="mt-5 space-y-3">
          <p>Listing Agreement Signed Date:</p>
          <c-date-picker
            v-model="$v.listingAgreementSignedDate.$model"
            :error-message="getDirtyErrorMessage('listingAgreementSignedDate')"
            date-format="Y-m-d"
            close-on-select
          />
        </div>

        <div class="mt-5 space-y-3">
          <p>Comments:</p>
          <c-textarea-input
            v-model="$v.listingAgreementSignedComments.$model"
            :error-message="getDirtyErrorMessage('listingAgreementSignedComments')"
            placeholder="Enter comments"
          />
        </div>
      </template>

      <template v-if="listingAgreementSigned === false">
        <div class="mt-5 space-y-3">
          <p>Reason for No</p>
          <c-dropdown
            v-model="$v.didNotSignReason.$model"
            :options="didNotSignReasonOptions"
            :error-message="getDirtyErrorMessage('didNotSignReason')"
            class="border-gray-300 mb-5"
            variant="demo"
          />
        </div>

        <div class="mt-5 space-y-3">
          <p>Comments:</p>
          <c-textarea-input
            v-model="$v.listingAgreementSignedComments.$model"
            :error-message="getDirtyErrorMessage('listingAgreementSignedComments')"
            placeholder="Enter comments"
          />
        </div>

        <div class="mt-5 space-y-3">
          <p>Next Follow Up Date:</p>
          <c-date-picker
            v-model="$v.nextFollowUp.$model"
            :min-date="new Date()"
            :error-message="getDirtyErrorMessage('nextFollowUp')"
            date-format="Y-m-d"
            close-on-select
          />
        </div>
      </template>

      <questionnaire-form
        v-model="questionnaireFields"
        :is-loading="isFetchingQuestionnaireFields"
        class="mt-5"
      />
    </template>

    <template v-if="isLostOpportunity">
      <div class="mt-5 space-y-3">
        <p>Reason for No - Lost opportunity</p>
        <c-dropdown
          v-model="$v.lostOfferOpportunityReason.$model"
          :options="lostOfferOpportunityReasonOptions"
          :error-message="getDirtyErrorMessage('lostOfferOpportunityReason')"
          class="border-gray-300 mb-5"
          variant="demo"
        />
      </div>

      <div class="mt-5 space-y-3">
        <p>Comments:</p>
        <c-textarea-input
          v-model="$v.agentMarkedAsDeadRequestComments.$model"
          :error-message="getDirtyErrorMessage('agentMarkedAsDeadRequestComments')"
          placeholder="Enter comments"
        />
      </div>
    </template>

    <template v-if="isVideoAppointmentCancelled">
      <div class="mt-5 space-y-3">
        <p>Reason for Video Appointment Cancelled</p>
        <c-dropdown
          v-model="$v.videoAppointmentCancelledReason.$model"
          :options="reasonsForListingAppointmentCancelled"
          :error-message="getDirtyErrorMessage('videoAppointmentCancelledReason')"
          class="border-gray-300 mb-5"
          variant="demo"
          @input="clearCancelledFields"
        />
      </div>

      <template v-if="isAppointmentRescheduled">
        <div class="mt-5 space-y-3">
          <p>New Video Appointment Date:</p>
          <c-date-picker
            v-model="$v.offerVideoApptDateTime.$model"
            :error-message="getDirtyErrorMessage('offerVideoApptDateTime')"
            date-format="Y-m-d"
            mode="dateTime"
            close-on-select
          />
        </div>

        <div class="mt-5 space-y-3">
          <p>Comments:</p>
          <c-textarea-input
            v-model="$v.videoAppointmentCancelledReasonComments.$model"
            :error-message="getDirtyErrorMessage('videoAppointmentCancelledReasonComments')"
            placeholder="Enter comments"
          />
        </div>
      </template>

      <template v-if="isAppointmentNotRescheduled">
        <div class="mt-5 space-y-3">
          <p>Comments:</p>
          <c-textarea-input
            v-model="$v.videoAppointmentCancelledReasonComments.$model"
            :error-message="getDirtyErrorMessage('videoAppointmentCancelledReasonComments')"
            placeholder="Enter comments"
          />
        </div>

        <div class="mt-5 space-y-3">
          <p>Next Follow Up Date:</p>
          <c-date-picker
            v-model="$v.nextFollowUp.$model"
            :min-date="new Date()"
            :error-message="getDirtyErrorMessage('nextFollowUp')"
            date-format="Y-m-d"
            close-on-select
          />
        </div>
      </template>
    </template>

    <div
      v-if="videoAppointmentCompleted"
      class="pt-5"
    >
      <attachment-upload
        v-if="isVideoAppointmentCompleted"
        :attachment-type="attachmentType"
        :error-messages="attachmentErrors"
        class="mb-5"
        @changeFile="setFile($event, 'attachments')"
        @changeAttachmentType="attachmentType = $event"
      />

      <c-button
        :is-loading="isSubmitting"
        type="submit"
      >
        Save
      </c-button>
    </div>
  </submittable-form>
</template>

<script>
import { mapActions } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import {
  CDropdown,
  CDatePicker,
  CTextareaInput,
  AttachmentUpload,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import SubmittableForm from '@/components/forms/SubmittableForm.vue';
import QuestionnaireForm from '@/components/forms/QuestionnaireForm.vue';
import {
  booleanOptions,
  reasonsForListingAppointmentCancelled,
} from '@/constants';
import { submitIovaf } from '@/api/actions';
import { getFileFromObject } from '@/utils';
import { formMixin } from '@/mixins';
import {
  iovafFields,
  didNotSignReasonOptions,
  videoAppointmentCompletedOptions,
  lostOfferOpportunityReasonOptions,
  attachmentTypeOptions,
} from './constants';

export default {
  name: 'IovafForm',
  components: {
    SubmittableForm,
    QuestionnaireForm,
    CDropdown,
    CDatePicker,
    CTextareaInput,
    AttachmentUpload,
    CButton,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    videoAppointmentCompleted: null,
    leftMarketingPiece: null,
    leftMarketingPieceComments: '',
    setListingAppointment: null,
    setListingAppointmentComments: '',
    listingAgreementSigned: null,
    listingAgreementSignedDate: null,
    listingAgreementSignedComments: '',
    didNotSignReason: null,
    nextFollowUp: null,
    lostOfferOpportunityReason: null,
    agentMarkedAsDeadRequestComments: '',
    videoAppointmentCancelledReason: null,
    videoAppointmentCancelledReasonComments: '',
    offerVideoApptDateTime: null,
    attachmentType: null,
    attachments: null,
    isSubmitting: false,
    isSubmitted: false,
    questionnaireFields: {},
    isFetchingQuestionnaireFields: false,
    booleanOptions,
    reasonsForListingAppointmentCancelled,
    videoAppointmentCompletedOptions,
    lostOfferOpportunityReasonOptions,
    didNotSignReasonOptions,
    attachmentTypeOptions,
    fields: iovafFields,
  }),
  validations() {
    return {
      leftMarketingPiece: {
        required: requiredIf(() => this.isVideoAppointmentCompleted),
      },
      leftMarketingPieceComments: {
        required: requiredIf(() => this.leftMarketingPiece === false),
      },
      setListingAppointment: {
        required: requiredIf(() => this.isVideoAppointmentCompleted),
      },
      listingAgreementSigned: {
        required: requiredIf(() => this.isVideoAppointmentCompleted),
      },
      listingAgreementSignedDate: {
        required: requiredIf(() => this.listingAgreementSigned),
      },
      listingAgreementSignedComments: {
        required: requiredIf(() => this.listingAgreementSigned !== null),
      },
      didNotSignReason: {
        required: requiredIf(() => this.isListingAgreementSigned),
      },
      lostOfferOpportunityReason: {
        required: requiredIf(() => this.isLostOpportunity),
      },
      agentMarkedAsDeadRequestComments: {
        required: requiredIf(() => this.isLostOpportunity),
      },
      videoAppointmentCancelledReason: {
        required: requiredIf(() => this.isVideoAppointmentCancelled),
      },
      offerVideoApptDateTime: {
        required: requiredIf(() => this.isAppointmentRescheduled),
      },
      videoAppointmentCancelledReasonComments: {
        required: requiredIf(() => this.videoAppointmentCancelledReason),
      },
      nextFollowUp: {
        required: requiredIf(() => this.isListingAgreementSigned || this.isAppointmentNotRescheduled),
      },
      attachmentType: {
        required: requiredIf(() => this.attachments),
      },
      attachments: {
        required: requiredIf(() => this.isVideoAppointmentCompleted),
      },
    };
  },
  computed: {
    isVideoAppointmentCompleted: vm => vm.videoAppointmentCompleted === 'Yes',
    isLostOpportunity: vm => vm.videoAppointmentCompleted === 'No - Lost Opportunity',
    isVideoAppointmentCancelled: vm => vm.videoAppointmentCompleted === 'Video Appointment Cancelled',
    isAppointmentRescheduled: vm => vm.videoAppointmentCancelledReason === 'Rescheduled',
    isAppointmentNotRescheduled: vm => vm.videoAppointmentCancelledReason === 'Not Rescheduled',
    isListingAgreementSigned: vm => vm.listingAgreementSigned === false,
    attachmentErrors: vm => ({
      attachments: vm.getDirtyErrorMessage('attachments'),
      attachmentType: vm.getDirtyErrorMessage('attachmentType'),
    }),
    fieldConditions: vm => ({
      leftMarketingPiece: vm.isVideoAppointmentCompleted,
      leftMarketingPieceComments: vm.leftMarketingPiece === false,
      setListingAppointment: vm.isVideoAppointmentCompleted,
      setListingAppointmentComments: vm.setListingAppointment === false,
      listingAgreementSigned: vm.isVideoAppointmentCompleted,
      listingAgreementSignedDate: vm.listingAgreementSigned,
      didNotSignReason: vm.listingAgreementSigned === false,
      listingAgreementSignedComments: vm.listingAgreementSigned !== null,
      lostOfferOpportunityReason: vm.isLostOpportunity,
      agentMarkedAsDeadRequestComments: vm.isLostOpportunity,
      videoAppointmentCancelledReason: vm.isVideoAppointmentCancelled,
      offerVideoApptDateTime: vm.isAppointmentRescheduled,
      videoAppointmentCancelledReasonComments: vm.videoAppointmentCancelledReason,
      nextFollowUp: vm.listingAgreementSigned === false || vm.isAppointmentNotRescheduled,
      attachmentType: vm.attachments,
    }),
  },
  mounted() {
    this.fetchFields();
  },
  methods: {
    ...mapActions('agent', ['getAgentDetails']),
    ...mapActions('client', ['fetchPropertyQuestionnaireFields', 'updatePropertyQuestionnaireFields']),
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) {
        const attachmentFields = ['attachments', 'attachmentType'];
        const isAttachmentValid = attachmentFields.every(f => !this.getDirtyErrorMessage(f));
        const isScrollingTop = this.isVideoAppointmentCompleted && isAttachmentValid;

        if (isScrollingTop) this.scrollToTop();
        return;
      }

      try {
        this.isSubmitting = true;

        const payload = {
          ...this.generatedPayload,
          agentId: +this.client.assignedAgent,
          listingId: this.client.propertyReferralId,
        };

        const formData = new FormData();
        const binaryPayload = getFileFromObject(payload);
        formData.append('videoAppointmentRequest', binaryPayload);

        if (this.attachments) {
          [...this.attachments].forEach(file => formData.append('attachments', file, file.name));
        }

        const questionnairePayload = this.getQuestionnairePayload();
        await this.updatePropertyQuestionnaireFields(questionnairePayload);
        await submitIovaf(formData, this.attachmentType);
        this.$emit('submit');
        this.isSubmitted = true;

        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchFields() {
      try {
        this.isFetchingQuestionnaireFields = true;
        this.questionnaireFields = await this.fetchPropertyQuestionnaireFields(this.client.id);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isFetchingQuestionnaireFields = false;
      }
    },
    getQuestionnairePayload() {
      const questionnaireEntries = Object.entries(this.questionnaireFields);
      const questionnairePatchFields = questionnaireEntries.map(([name, value]) => {
        return { path: `/${name}`, value };
      });
      return {
        propertyId: this.client.propertyReferralId,
        fieldsToUpdate: questionnairePatchFields,
      };
    },
    setFile(files, fieldName) {
      this[fieldName] = files;
    },
    resetValidation() {
      this.$nextTick(() => this.$v.$reset());
    },
    clearFields() {
      this.leftMarketingPiece = null;
      this.leftMarketingPieceComments = '';
      this.setListingAppointment = null;
      this.setListingAppointmentComments = '';
      this.listingAgreementSigned = null;
      this.listingAgreementSignedDate = null;
      this.listingAgreementSignedComments = '';
      this.didNotSignReason = null;
      this.nextFollowUp = null;
      this.lostOfferOpportunityReason = null;
      this.agentMarkedAsDeadRequestComments = '';
      this.videoAppointmentCancelledReason = null;
      this.videoAppointmentCancelledReasonComments = '';
      this.offerVideoApptDateTime = null;
      this.attachments = null;
      this.attachmentType = null;
      this.resetValidation();
    },
    clearMarketingPieceComments() {
      this.leftMarketingPieceComments = '';
      this.resetValidation();
    },
    clearListingAppointmentComments() {
      this.setListingAppointmentComments = '';
      this.resetValidation();
    },
    clearListingAgreementSignedFields() {
      this.listingAgreementSignedDate = null;
      this.listingAgreementSignedComments = '';
      this.didNotSignReason = null;
      this.resetValidation();
    },
    clearCancelledFields() {
      this.offerVideoApptDateTime = null;
      this.videoAppointmentCancelledReasonComments = '';
      this.nextFollowUp = null;
      this.resetValidation();
    },
    scrollToTop() {
      this.$refs.videoAppointmentCompleted.$el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
