export const alertThemes = {
  success: {
    color: 'green',
    icon: 'CheckCircleIcon',
  },
  info: {
    color: 'light-blue',
    icon: 'InformationCircleIcon',
  },
  caution: {
    color: 'orange',
    icon: 'ExclamationTriangleIcon',
  },
  warning: {
    color: 'amber',
    icon: 'ExclamationTriangleIcon',
  },
  danger: {
    color: 'rose',
    icon: 'XCircleIcon',
  },
};

export const alerts = [
  {
    name: 'AGENT_PAUSED',
    text: 'New Referrals have been temporarily paused to give you an opportunity to bring your past-due communications up to date. Please contact your Account Executive for assistance. Thank you.',
    ...alertThemes.warning,
  },
];

export const collectionStatusAlertTypes = [
  {
    name: 'COLLECTION',
    label: 'Collections',
    className: 'text-white bg-red-600',
    bannerClass: 'from-red-600 to-red-500',
  },
  {
    name: 'PRE_COLLECTION',
    label: 'Pre-Collections',
    className: 'text-white bg-amber-500',
    bannerClass: 'from-amber-600 to-amber-500',
  },
];
