<template>
  <form-input :error-message="errorMessage">
    <div class="flex items-center space-x-2">
      <label
        v-if="!readonly"
        :class="inputClasses"
      >
        <input
          v-bind="$attrs"
          :multiple="multiple"
          type="file"
          class="hidden"
          @change="changeFile"
        >
        <div class="max-w-max bg-blue-500 hover:bg-blue-600 text-white text-sm px-2 py-1 cursor-pointer">
          Choose File
        </div>
      </label>
      <div
        v-if="fileArray.length"
        class="text-gray-700"
      >
        <span
          v-for="(fileInfo, fileIndex) in fileArray"
          :key="fileInfo.name"
        >
          <span
            :class="downloadable && 'hover:text-blue-700 cursor-pointer'"
            @click="downloadable && downloadFile(fileInfo)"
            v-text="fileInfo.name"
          />
          <span
            v-if="fileIndex !== fileArray.length - 1"
            v-text="', '"
          />
        </span>
      </div>
      <p v-else>
        No Files Chosen
      </p>
    </div>
  </form-input>
</template>

<script>
import { getFile } from '@/api/actions';
import { downloadFileByBlob } from '@/utils';
import FormInput from './FormInput.vue';

export default {
  components: {
    FormInput,
  },
  props: {
    file: {
      type: [Object, Array],
      default: null,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    readonly: Boolean,
    multiple: Boolean,
    downloadable: Boolean,
  },
  data: () => ({
    fileArray: [],
  }),
  computed: {
    inputClasses: vm => (vm.errorMessage ? 'border-red-500' : ''),
  },
  mounted() {
    if (!this.file) return;
    const files = this.multiple ? this.file : [this.file];
    this.fileArray = files;
  },
  methods: {
    changeFile(event) {
      this.fileArray = event.target.files;
      const payload = this.multiple ? this.fileArray : this.fileArray[0];
      this.$emit('change', payload);
    },
    async downloadFile(file) {
      const { id, storageName, name } = file;
      if (!id || !storageName) return;

      const fileBlob = await getFile(id, storageName);
      downloadFileByBlob(fileBlob, name);
    },
  },
};
</script>
