<template>
  <div
    v-if="orders"
  >
    <h2 class="pb-3 text-2xs sm:text-xl text-blue-900">
      {{ ordersTitle }}
    </h2>
    <div
      class="pb-1 xs:text-xs md:text-sm"
      v-html="formattedOrders"
    />
  </div>
</template>

<script>
export default {
  props: ['orders', 'ordersTitle'],
  computed: {
    formattedOrders() {
      return this.orders.replace(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
        '<a href="mailto:$1" class="text-blue-600 underline">$1</a>',
      );
    },
  },
};
</script>
