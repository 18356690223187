<template>
  <div>
    <t-alert
      :dismissible="false"
      variant="warning"
      show
      class="mb-8"
    >
      Please complete the property related questionnaire below if the client
      has not done previously. This is needed to receive offers from the vendors.
    </t-alert>

    <spinner v-if="isLoading" />
    <div
      v-else
      class="flex flex-col sm:flex-row gap-2"
    >
      <div
        v-for="(fieldSlice, index) in fieldSlices"
        :key="index"
        class="sm:w-1/2"
      >
        <div
          v-for="field in fieldSlice"
          :key="field.name"
          class="mb-5 space-y-1"
        >
          <p>{{ field.label }}</p>
          <component
            :is="field.component"
            v-bind="field.props"
            :value="value[field.name]"
            @input="handleInput(field, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CDropdown, CTextInput, CDatePicker } from '@/components/inputs';
import { questionnaireFormFields } from './constants';

export default {
  components: {
    CDropdown,
    CTextInput,
    CDatePicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: Boolean,
  },
  data: () => ({
    sliceIndex: 17,
    questionnaireFormFields,
  }),
  computed: {
    fieldSlices: vm => [
      questionnaireFormFields.slice(0, vm.sliceIndex),
      questionnaireFormFields.slice(vm.sliceIndex),
    ],
  },
  methods: {
    handleInput(field, value) {
      const isNumber = field.props?.type === 'number';
      const formattedValue = isNumber ? +value : value;
      const updatedFields = { ...this.value, [field.name]: formattedValue };
      this.$emit('input', updatedFields);
    },
  },
};
</script>
