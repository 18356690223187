import {
  Update,
} from '@/views';

export const isolatedLayoutChildren = [
  {
    path: '/update/:code/:name/:id',
    name: 'Update',
    components: {
      default: Update,
    },
  },
];
