<template>
  <span
    v-if="collectionInstruction?.name"
    class="text-center inline px-3 py-1 Montserrat tracking-wider"
    :class="collectionInstruction.className"
  >
    <span class="transform rotate-90 leading-none">{{ collectionInstruction?.label }}</span>
  </span>
</template>

<script>
import { collectionStatusAlertTypes } from '@/constants';

export default {
  name: 'CollectionStatusPill',
  components: {
  },
  props: {
    statusName: {
      type: String,
      default: null,
    },
  },
  computed: {
    collectionInstruction() {
      return collectionStatusAlertTypes.find(x => x.name === this.statusName);
    },
  },
};
</script>
