<template>
  <spinner v-if="isFetchingOffers" />
  <div
    v-else-if="offers.length"
    :class="gridClasses"
    class="pb-20"
  >
    <iof-form-card
      v-for="offer in offers"
      :key="offer.id"
      :client="client"
      :offer="offer"
      :is-accepted="isAccepted"
      class="bg-blue-100 border shadow-md rounded-md p-5"
    />
  </div>
  <t-alert
    v-else
    :dismissible="false"
    variant="success"
    show
  >
    No Offers Available
  </t-alert>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import IofFormCard from './IofFormCard.vue';

export default {
  name: 'IofFormGrid',
  components: {
    IofFormCard,
    Spinner,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    offers: [],
    isFetchingOffers: false,
  }),
  computed: {
    gridClasses: vm => vm.offers.length > 1 && 'grid md:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2 gap-10',
    isAccepted: vm => vm.offers.some(offer => offer.acceptedRejected),
  },
  created() {
    this.fetchOffers();
  },
  methods: {
    ...mapActions('client', ['fetchPropertyOfferFields']),
    async fetchOffers() {
      try {
        this.isFetchingOffers = true;
        const offerFields = await this.fetchPropertyOfferFields(this.client.id);
        if (offerFields) this.offers = offerFields.offers;
      } catch (e) {
        this.$toast.error(e);
      } finally {
        this.isFetchingOffers = false;
      }
    },
  },
};
</script>
