<template>
  <spinner
    fullscreen
  />
</template>

<script>
import { mapActions } from 'vuex';
import { getUserToken } from '@/utils';
import { Spinner } from '@/components';

export default {
  name: 'EmailVerification',
  components: {
    Spinner,
  },
  async created() {
    const { code } = this.$route.query || {};
    if (!code) {
      this.$toast.error('No token found');
      this.navigateAway();
      return;
    }
    try {
      await this.checkEmailToken(code);
      this.$toast.success('Email address successfully updated.');
    } catch ({ message }) {
      this.$toast.error(message);
      this.navigateAway();
    }
  },
  methods: {
    ...mapActions('agent', ['checkEmailToken']),
    navigateAway() {
      const routeToGo = getUserToken() ? '/' : '/login';
      this.$router.push(routeToGo);
    },
  },
};
</script>
