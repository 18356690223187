<template>
  <div class="mb-5 md:mb-10">
    <pie-chart
      :series="series"
      :labels="labels"
      :colors="colors"
      :settings="chartSettings"
    />
    <p class="text-gray-500 text-center font-bold text-2xl md:mt-5">
      Total {{ column.title }}: {{ column.total }}
    </p>
  </div>
</template>

<script>
import { PieChart } from '@/components/charts';
import { getHexByColorName } from '@/utils';
import { dashboardChartIgnoredFields } from './constants';

export default {
  name: 'DashboardDetailsChart',
  components: {
    PieChart,
  },
  props: {
    column: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartSettings: {
      innerStrokeColor: 'blue-light',
      outerStrokeColor: 'blue-light',
    },
  }),
  computed: {
    chartSections() {
      return this.column.fields.filter(f => !dashboardChartIgnoredFields.includes(f.label));
    },
    labels: vm => vm.chartSections.map(s => s.label),
    series: vm => vm.chartSections.map(s => s.count),
    colors() {
      return this.chartSections.map(s => {
        const color = s.color?.split('bg-')[1];
        return getHexByColorName(color);
      });
    },
  },
};
</script>
