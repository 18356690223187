<template>
  <form
    class="space-y-8 divide-y divide-blue-gray-300"
    @submit.prevent="handleSubmit"
  >
    <div class="pt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
      <div class="sm:col-span-6">
        <p>
          Phone number
        </p>
        <div class="mt-2">
          <PuSkeleton
            width="100%"
            height="2.6rem"
          >
            <vue-tel-input
              v-if="!loading.details"
              :value="phone"
              class="block w-full sm:text-sm rounded-md mt-1 opacity-50"
              placeholder="Enter a phone number"
              default-country="US"
              disabled-fetching-country
              :preferred-countries="['US', 'PR', 'VI', 'KY']"
              disabled
            />
          </PuSkeleton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'UpdatePhone',
  components: {
    VueTelInput,
  },
  data: () => ({
    phone: '',
  }),
  computed: {
    ...mapState('agent', ['agent', 'loading']),
  },
  watch: {
    agent() {
      this.prepareData();
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      this.phone = this.agent.phones?.find(p => p.phoneType === 'PRIMARY')?.phoneNumber
        || this.agent.phones?.[0]?.phoneNumber
        || '';
    },
  },
};
</script>
