<template>
  <!-- eslint-disable max-len -->
  <div
    v-bind="$attrs"
    class="flex justify-center"
    :class="{'w-full flex-1 items-center': fullscreen}"
  >
    <div
      class="loader ease-linear rounded-full border-8 border-t-8"
      :class="`h-${size} w-${size} border-${secondaryColor}`"
      :style="{'border-top-color': getHexByColorName(primaryColor)}"
    />
  </div>
</template>

<script>
import { getHexByColorName } from '@/utils';

export default {
  name: 'Spinner',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '8',
    },
    primaryColor: {
      type: String,
      default: 'ia-400',
    },
    secondaryColor: {
      type: String,
      default: 'gray-200',
    },
  },
  methods: {
    getHexByColorName,
  },
};
</script>

<style scoped lang="scss">
.loader {
  border-width: 3px;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  }

  @-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
