<template>
  <nav
    v-bind="$attrs"
    class="w-full shadow-sm bg-gray-100 px-2 py-3 sm:px-4 lg:px-6"
    aria-label="Breadcrumb"
  >
    <button
      class="flex items-center space-x-3 text-sm font-medium text-gray-900 focus:outline-none"
      @click="$emit('click')"
    >
      <chevronleft-icon class="fill-current w-5 h-5 text-gray-400" />
      <span v-text="label" />
    </button>
  </nav>
</template>

<script>
import { ChevronleftIcon } from '@/components/icons';

export default {
  name: 'Breadcrubms',
  components: {
    ChevronleftIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
