const getPatchedField = (path, value) => ({
  op: 'replace',
  path,
  value,
});

export const getPatchPayload = fieldsToUpdate => {
  const fieldArray = Array.isArray(fieldsToUpdate) ? fieldsToUpdate : [fieldsToUpdate];
  return fieldArray.map(({ path, value }) => getPatchedField(path, value));
};

export const getFileFromObject = object => {
  const blob = new Blob([JSON.stringify(object)]);
  return new File([blob], 'file.json', { type: 'application/json' });
};
