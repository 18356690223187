<template>
  <div>
    <SiteAlert
      v-if="isPausedBanner"
      :alert="pausedAlert"
      is-bold
      show-icon
      is-centered
    />
    <div>
      <div class="flex items-center justify-center bg-gray-200 border-b border-gray-200 p-4">
        <img
          src="@/assets/ia-logo.png"
          class="h-8 w-auto"
          alt="IdealAgent Logo"
        >
      </div>
      <div class="relative">
        <spinner
          v-if="loading.global"
          class="absolute"
          fullscreen
        />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import SiteAlert from '@/components/notifications/SiteAlert.vue';
import { alerts } from '@/constants';

export default {
  name: 'IsolatedLayout',
  components: {
    Spinner,
    SiteAlert,
  },
  data: () => ({
    pausedAlert: alerts.find(x => x.name === 'AGENT_PAUSED'),
  }),
  computed: {
    ...mapGetters('agent', ['isPausedBanner']),
    ...mapState('agent', ['loading']),
  },
};
</script>
