<template>
  <div>
    <spinner v-if="isLoadingClientHistory" />
    <div v-else>
      <!--Important dates -->
      <div class="text-base font-bold pb-4">
        Important Dates
      </div>
      <div class="mb-5">
        <div v-if="!hasDates">
          No dates to display
        </div>
        <div
          v-for="(date, idx) in allDates"
          v-else
          :key="idx"
        >
          <b>{{ date.label }}</b>: {{ date.value }}
        </div>
      </div>

      <!--Client history-->
      <div class="text-base font-bold pb-4">
        Your Previous Updates
      </div>
      <update-table
        :table-data="clientHistory"
        class="mb-5"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UpdateTable from '@/components/UpdateTable.vue';
import Spinner from '@/components/Spinner.vue';
import { formatDate } from '@/utils';

import {
  dateNames,
  dateTitles,
} from '@/components/constants';

export default {
  name: 'History',
  components: {
    UpdateTable,
    Spinner,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    clientName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoadingClientHistory: false,
    clientHistory: [],
    allDates: [],
  }),
  computed: {
    ...mapState('agent', ['user']),
    hasDates: vm => vm.allDates.length,
  },
  async created() {
    this.getAllDates();
    this.getHistory();
  },
  methods: {
    ...mapActions('client', ['getClientHistory']),
    getAllDates() {
      this.allDates = dateNames.reduce((acc, name) => {
        if (!this.client[name]) return acc;
        acc.push({
          label: dateTitles[name],
          value: formatDate(this.client[name]),
        });
        return acc;
      }, []);
    },
    async getHistory() {
      const { id, clientType, assignedAgent } = this.client;
      try {
        this.isLoadingClientHistory = true;
        const payload = { profileId: id, agentId: +assignedAgent, clientType };
        this.clientHistory = await this.getClientHistory(payload);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isLoadingClientHistory = false;
      }
    },
  },
};
</script>
