<template>
  <submittable-form
    :is-submitted="isSubmitted"
    class="pb-20"
    @submit="handleSubmit"
  >
    <div class="font-bold pb-4">
      Did you set the video appointment?
    </div>

    <c-dropdown
      v-model="setVideoAppointment"
      :options="setVideoAppointmentOptions"
      class="border-gray-300 mb-5"
      variant="demo"
      @input="clearFields"
    />

    <template v-if="isVideoAppoinmentSet">
      <div class="mt-8 space-y-3">
        <p>Video Appointment Date/Time:</p>
        <c-date-picker
          v-model="$v.offerVideoApptDateTime.$model"
          :error-message="getDirtyErrorMessage('offerVideoApptDateTime')"
          mode="dateTime"
          close-on-select
        />
      </div>
    </template>

    <template v-if="isVideoAppoinmentNotSet">
      <div class="mt-5 space-y-3">
        <p>Reason for not setting:</p>
        <c-dropdown
          v-model="$v.noVideoAppointmentReason.$model"
          :options="noVideoAppointmentReasonOptions"
          :error-message="getDirtyErrorMessage('noVideoAppointmentReason')"
          class="border-gray-300 mb-5"
          variant="demo"
        />
      </div>

      <div class="mt-5 space-y-3">
        <p>Next Follow Up Date:</p>
        <c-date-picker
          v-model="$v.nextFollowUp.$model"
          :min-date="new Date()"
          :error-message="getDirtyErrorMessage('nextFollowUp')"
          date-format="Y-m-d"
          close-on-select
        />
      </div>

      <div class="mt-5">
        <c-checkbox
          v-model="agentMarkedAsDeadRequest"
          label="Mark as Dead"
        />
      </div>
    </template>

    <div
      v-show="isSaveButtonShown"
      class="mt-8"
    >
      <div class="mb-8 space-y-3">
        <p>Comments:</p>
        <c-textarea-input
          v-model="$v.comments.$model"
          :error-message="getDirtyErrorMessage('comments')"
          placeholder="Enter comments"
        />
      </div>

      <c-button
        :is-loading="isSubmitting"
        type="submit"
      >
        Save
      </c-button>
    </div>
  </submittable-form>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';
import {
  CDropdown,
  CDatePicker,
  CTextareaInput,
  CCheckbox,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import SubmittableForm from '@/components/forms/SubmittableForm.vue';
import { submitIoicf } from '@/api/actions';
import { formMixin } from '@/mixins';
import {
  ioicfFields,
  setVideoAppointmentOptions,
  noVideoAppointmentReasonOptions,
} from './constants';

export default {
  name: 'IoicfForm',
  components: {
    SubmittableForm,
    CDropdown,
    CDatePicker,
    CTextareaInput,
    CCheckbox,
    CButton,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    setVideoAppointment: null,
    offerVideoApptDateTime: null,
    comments: '',
    noVideoAppointmentReason: null,
    nextFollowUp: null,
    agentMarkedAsDeadRequest: false,
    isSubmitting: false,
    isSubmitted: false,
    setVideoAppointmentOptions,
    noVideoAppointmentReasonOptions,
    fields: ioicfFields,
  }),
  validations() {
    return {
      offerVideoApptDateTime: {
        required: requiredIf(() => this.isVideoAppoinmentSet),
      },
      comments: {
        required: requiredIf(() => this.isVideoAppoinmentNotSet),
      },
      noVideoAppointmentReason: {
        required: requiredIf(() => this.isVideoAppoinmentNotSet),
      },
      nextFollowUp: {
        required: requiredIf(() => this.isVideoAppoinmentNotSet && !this.agentMarkedAsDeadRequest),
      },
    };
  },
  computed: {
    fieldConditions() {
      return {
        offerVideoApptDateTime: this.isVideoAppoinmentSet,
        comments: this.isVideoAppoinmentNotSet,
        noVideoAppointmentReason: this.isVideoAppoinmentNotSet,
        nextFollowUp: this.isVideoAppoinmentNotSet,
        agentMarkedAsDeadRequest: this.isVideoAppoinmentNotSet,
      };
    },
    isVideoAppoinmentSet: vm => vm.setVideoAppointment === 'YES',
    isVideoAppoinmentNotSet: vm => vm.setVideoAppointment === 'NO',
    isSaveButtonShown: vm => Boolean(vm.setVideoAppointment),
  },
  methods: {
    async handleSubmit() {
      if (!this.$v.$dirty) this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        ...this.generatedPayload,
        agentId: +this.client.assignedAgent,
        listingId: this.client.propertyReferralId,
      };

      try {
        this.isSubmitting = true;
        await submitIoicf(payload);
        this.$emit('submit');
        this.isSubmitted = true;

        setTimeout(this.getAgentDetails, 3000);
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isSubmitting = false;
      }
    },
    clearFields() {
      this.offerVideoApptDateTime = null;
      this.comments = '';
      this.noVideoAppointmentReason = null;
      this.nextFollowUp = null;
      this.agentMarkedAsDeadRequest = false;
      this.$v.$reset();
    },
  },
};
</script>
