<template>
  <div>
    <ul class="list-none text-sm pb-2 text-left font-semibold">
      <li class="pl-2">
        <label class="w-36 inline-block underline -ml-1">Client Name</label>
        <span class="w-36 inline-block underline">Referral Date</span>
      </li>
    </ul>
    <ul
      v-if="tableData?.length > 0"
      class="list-decimal text-sm"
    >
      <li
        v-for="(row, i) in tableData"
        :key="`detail-row-${i}`"
        class="pl-2"
      >
        <label class="w-36 inline-block text-left">{{ `${row.firstName} ${row.lastName}` }}</label>
        <span class="w-36 inline-block text-left">{{ getFormattedDateValue(row.dtCreated, 'date') }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { getFormattedDateValue } from '@/utils';

export default {
  name: 'ListingsOnDemandDetailTable',
  components: {},
  props: {
    table: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      thClass: 'table-cell sticky top-0 z-10 border-b border-gray-300 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8',
      tdClass: 'table-cell whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
      tableData: this.table ?? [],
    };
  },
  methods: {
    getFormattedDateValue,
  },
};
</script>
